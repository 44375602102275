export type EnvConfig = {
  BFF_URI: string;
  IDP_URI: string;
};

export type InterfaceToType<T> = { [P in keyof T]: T[P] };

type AddressStateOption = {
  value: number;
  label: string;
};

export type StateType = AddressStateOption | null;

export type AscensionWindow = Window & {
  global: {
    locale: string;
  };
};

export const isAscesionWindow = (subject: Window): subject is AscensionWindow =>
  (subject as AscensionWindow).global !== undefined &&
  (subject as AscensionWindow).global.locale !== undefined;
