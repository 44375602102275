export enum SSCOptOutOptionEnum {
  SectionTitle = 'Watchlist visibility',
  OptionName = 'sscOptOutSetting',
  OptInLabel = 'Supplier accounts on EstimateOne',
  OptInValue = 'sscOptIn',
  OptOutLabel = 'No one',
  OptOutValue = 'sscOptOut',
  SaveButton = 'Save watchlist visibility setting',
  InfoCardHeading = 'How does this work?',
  InfoCardDescriptionLineOne = "Your Company Profile can be found by Suppliers on projects that have been added to your Watchlist as 'interested', 'quoting' or 'quoted'.",
  InfoCardDescriptionLineTwo = 'Your Company Profile can be found by Suppliers on projects that you have responded to the builder as quoting.',
  SuccessTitle = 'Success',
  OptInSuccessMessage = 'Your company profile can be viewed by Suppliers.',
  OptOutSuccessMessage = 'Your company profile can not be viewed by Suppliers.',
}
