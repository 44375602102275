export enum EditCompanyFieldNames {
  CompanyAboutUs = 'aboutUs',
}

export enum EditCompanyFieldLabels {
  CompanyAboutUs = 'About us',
}

export type EditCompanyFieldValues = {
  [EditCompanyFieldNames.CompanyAboutUs]: string | null;
};

export type EditAboutUsFormProps = {
  fieldName: string;
  fieldLabel: string;
  fieldValue: string | null;
  toggle: () => void;
};
