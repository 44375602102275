import React from 'react';
import { Modal, ModalSize } from '@estimateone/frontend-components';
import { getContractSizeLabel } from 'components/ProjectHistory/ContractSizeSelect';
import { ProjectHistoryForm } from 'components/ProjectHistory/ProjectHistoryForm';
import { useTranslator } from '../../../../modules/TranslationsProvider';
import { getCategoryLabel } from 'components/Categories/utils';
import { extractFieldErrors } from 'utils';
import { useEditHistoricalProject } from './hooks';
import { ProjectHistory } from 'components/ProjectHistory/ProjectHistoryContent/types';
import {
  ProjectHistoryFieldNames,
  ProjectHistoryFieldValues,
} from 'components/ProjectHistory/ProjectHistoryForm/types';
import { ProjectCategoryEnum, StateEnum } from '__generated__/globalTypes';

export type EditHistoricalProjectProps = {
  data: ProjectHistory;
  isOpen: boolean;
  onRequestClose: () => void;
};

export const EditHistoricalProject = ({
  data,
  isOpen,
  onRequestClose,
}: EditHistoricalProjectProps) => {
  const {
    submit: submitForm,
    error,
    reset,
  } = useEditHistoricalProject(data.id);

  const translate = useTranslator();

  const closeModal = () => {
    reset();
    onRequestClose();
  };

  const onSubmit = async (values: ProjectHistoryFieldValues) => {
    const { data, errors } = await submitForm(values);
    if (errors) {
      return;
    }
    if (data?.updateHistoricalProject.__typename === 'FieldErrors') {
      const errors = data.updateHistoricalProject.errors;

      const fieldErrors = extractFieldErrors(errors, [
        ProjectHistoryFieldNames.Name,
        ProjectHistoryFieldNames.BuilderName,
        ProjectHistoryFieldNames.State,
        ProjectHistoryFieldNames.Region,
        ProjectHistoryFieldNames.CompletionDate,
        ProjectHistoryFieldNames.Category,
        ProjectHistoryFieldNames.ContractSize,
      ]);

      return fieldErrors;
    } else {
      closeModal();
    }
  };

  return (
    <Modal
      rootElement="#MFE_PROFILES_ROOT"
      isOpen={isOpen}
      onRequestClose={closeModal}
      size={ModalSize.Small}
    >
      <Modal.Section>
        <ProjectHistoryForm
          onSubmit={onSubmit}
          onAbort={closeModal}
          error={error}
          initialValues={{
            ...data,
            name: data.name,
            completionDate: new Date(data.completionDate),
            state: { value: data.state, label: StateEnum[data.state] },
            region: data.region,
            category: {
              value: data.category,
              label: getCategoryLabel(ProjectCategoryEnum[data.category]),
            },
            contractSize: {
              value: data.contractSize,
              label: getContractSizeLabel(data.contractSize, translate) ?? '',
            },
            linkedProjectId: data.linkedProjectId,
          }}
        />
      </Modal.Section>
    </Modal>
  );
};
