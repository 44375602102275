import { gql } from '@apollo/client';

const CONTACTABLE_FRAGMENT = gql`
  fragment CoreContactableFields on Contactable {
    id
    phone
    email
    web
  }
`;

const PROFILE_FRAGMENT = gql`
  ${CONTACTABLE_FRAGMENT}
  fragment ProfileFields on Profile {
    id
    createdById
    updatedById
    createdAt
    updatedAt
    lastActiveAt
    memberSince
    name
    workforceSize
    projectCategories
    aboutUs
    contractSizeMin
    contractSizeMax
    completionStatus
    companyNumber
    isOptedIntoDirectory
    account {
      id
    }
    offices {
      id
      ascensionId
      name
      isDefaultOffice
      contactable {
        ...CoreContactableFields
      }
      address {
        id
        ascensionId
        address1
        address2
        address3
        fullAddress
        city
        suburb
        province
        state
        postcode
        country
      }
    }
    trades {
      id
      trade {
        id
        name
        ascensionId
      }
    }
    people {
      id
      firstName
      lastName
      fullName
      isDefaultContact
      ascensionId
      contactable {
        id
        phone
        email
        web
      }
    }
    projectHistory {
      id
      name
      builderName
      state
      region
      category
      contractSize
      completionDate
      deletedAt
      linkedProjectId
      description
    }
  }
`;

export const GET_COMPANY_PROFILE = gql`
  ${PROFILE_FRAGMENT}
  query CompanyProfile($id: ID!) {
    companyProfile: profileById(id: $id) {
      ...ProfileFields
    }
  }
`;

export const GET_COMPANY_PROFILE_BY_ACCOUNT_ID = gql`
  ${PROFILE_FRAGMENT}
  query CompanyProfileByAccountId($id: ID!) {
    companyProfile: profileByAccountId(id: $id) {
      ...ProfileFields
    }
  }
`;
