import { gql } from '@apollo/client';

export const GET_PROFILE_INSIGHTS_DATA = gql`
  query GetProfileInsightsData {
    monthlyProfileViews {
      id
      month
      year
      source
      count
    }

    dailyProfileViews {
      id
      date
      accountName
      accountId
    }

    currentAccount {
      primaryCountry {
        id
      }
    }
  }
`;
