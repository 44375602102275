import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { useApolloCache } from '../../../../hooks';
import { GET_SSC_OPT_OUT_PREFERENCE } from '../../../../modules/ViewerProvider/queries';
import { EDIT_SSC_OPT_OUT_SETTING } from './mutations';
import { SscOptOutPreference } from '../../../../modules/ViewerProvider/__generated__/SscOptOutPreference';
import {
  EditSSCOptOutSetting,
  EditSSCOptOutSettingVariables,
} from './__generated__/EditSSCOptOutSetting';

export const useSubmitSSCOptOut = () => {
  const { updateCacheForQuery } = useApolloCache();

  const [editSSCOptOut, { error }] = useMutation<
    EditSSCOptOutSetting,
    EditSSCOptOutSettingVariables
  >(EDIT_SSC_OPT_OUT_SETTING, {
    update: (cache, { data }) => {
      const { success, value } = data?.editSSCOptOutSetting ?? {};
      if (success) {
        updateCacheForQuery<SscOptOutPreference, never>(
          { query: GET_SSC_OPT_OUT_PREFERENCE },
          (cacheContents) => ({
            ...cacheContents,
            sscOptOutSetting: value,
          }),
        );
      }
    },
  });

  const submit = useCallback(
    async (isOptingOut: boolean) => {
      const { errors } = await editSSCOptOut({
        variables: {
          optOut: isOptingOut,
        },
      });

      return errors;
    },
    [editSSCOptOut],
  );

  return { submit, error: error ?? undefined };
};
