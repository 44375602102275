import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { ApiClient } from './services/ApiClient';
import { TokenCache } from './services/TokenCache';
import { AuthContext, AuthContextValueType } from '../../context/AuthContext';
import { EnvConfig } from '../../types';

type AuthProviderProps = {
  envConfig: EnvConfig;
  children: React.ReactNode;
};

export const AuthProvider = ({ children, envConfig }: AuthProviderProps) => {
  const { BFF_URI, IDP_URI } = envConfig;

  const tokenUrl = `${IDP_URI}/gateway/authorise`;
  const loginUrl = `${IDP_URI}/auth/login`;
  const logoutUrl = `${IDP_URI}/auth/logout`;

  const tokenCache = new TokenCache(tokenUrl);

  const logout = async () => {
    try {
      await window.fetch(logoutUrl, {
        credentials: 'include',
        mode: 'no-cors',
      });
    } catch {}

    tokenCache.expireToken();

    window.location.assign(
      `${loginUrl}?redirect=${document.location.protocol}//${document.location.host}/projects`,
    );
  };

  const getToken = () => tokenCache.getToken();

  const contextValue: AuthContextValueType = {
    getToken,
    logout,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      <ApolloProvider client={ApiClient(`${BFF_URI}`, loginUrl, getToken)}>
        {children}
      </ApolloProvider>
    </AuthContext.Provider>
  );
};
