import React from 'react';
import { addTooltipToChart } from './BuilderViewsTooltip/BuilderViewsTooltip';
import { SingleYAxisLineChart } from './SingleYAxisLineChart';
import { mapProfileViewsToChartData } from './SingleYAxisLineChart/utils';
import { GetProfileInsightsDataQuery } from '../queries.generated';
import {
  AddToolTipProps,
  ChartDimensions,
  SingleYAxisDataPoint,
} from './SingleYAxisLineChart/types';

type MonthlyProfileView = GetProfileInsightsDataQuery['monthlyProfileViews'][0];

type BuilderViewsChartProps = {
  monthlyProfileViews: MonthlyProfileView[] | undefined;
};

export const BuilderViewsChart = ({
  monthlyProfileViews,
}: BuilderViewsChartProps) => {
  const chartId = 'builderProfileViewsChart';
  const chartDimensions: ChartDimensions = {
    width: 840,
    height: 208,
    margin: { top: 24, right: 24, bottom: 24, left: 24 },
  };

  if (!monthlyProfileViews || !monthlyProfileViews.length) {
    return null;
  }

  const chartData: SingleYAxisDataPoint[][] = [
    mapProfileViewsToChartData(monthlyProfileViews),
  ];

  const addTooltipHandler = ({ chart }: AddToolTipProps) =>
    addTooltipToChart({
      chart,
      chartId,
    });

  return (
    <SingleYAxisLineChart
      chartId={chartId}
      dataPoints={chartData}
      dimensions={chartDimensions}
      addToolTip={addTooltipHandler}
    />
  );
};
