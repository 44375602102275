import { gql, useApolloClient, useMutation } from '@apollo/client';
import { useCompanyProfileCacheItemId } from '../../../../modules/ProfileProvider';
import { UPDATE_CATEGORIES } from './mutations';
import {
  EditCategoriesFieldName,
  EditCategoriesFieldValues,
} from './fields/types';
import { CompanyProfile_companyProfile } from '../../../../modules/ProfileProvider/__generated__/CompanyProfile';
import {
  UpdateCategories,
  UpdateCategoriesVariables,
} from './__generated__/UpdateCategories';

const transformValues = (
  values: EditCategoriesFieldValues,
): UpdateCategoriesVariables => ({
  projectCategories: values[EditCategoriesFieldName.Categories].map(
    (categoryOption) => categoryOption.value,
  ),
});

export const useSubmitForm = () => {
  const { cache } = useApolloClient();
  const id = useCompanyProfileCacheItemId();

  const [updateCategories, { error }] = useMutation<
    UpdateCategories,
    UpdateCategoriesVariables
  >(UPDATE_CATEGORIES, {
    onCompleted: (data) => {
      if (data.updateCurrentProjectCategories) {
        cache.writeFragment<Partial<CompanyProfile_companyProfile>>({
          id,
          fragment: gql`
            fragment UpdatedProjectCategories on Profile {
              projectCategories
            }
          `,
          data: {
            projectCategories: data.updateCurrentProjectCategories,
          },
        });
      }
    },
  });

  const submit = async (values: EditCategoriesFieldValues) => {
    const { errors } = await updateCategories({
      variables: transformValues(values),
    });

    return errors ? errors : null;
  };

  return { submit, error: error ?? undefined };
};
