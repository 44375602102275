import React from 'react';
import { AlertIcon, SkeletonLoader } from '@estimateone/frontend-components';
import { AboutUsContent } from './AboutUsContent/AboutUsContent';
import { EditAboutUs } from './EditAboutUs';
import { useOwnAccount } from 'hooks';
import styles from './AboutUs.module.scss';

type AboutUsSectionProps = {
  aboutUs: string | null;
};

const AboutUsSection = ({ aboutUs }: AboutUsSectionProps) => {
  const { isOwnAccount } = useOwnAccount();
  return (
    <>
      <div className={styles.aboutUsHeadingWrapper}>
        <p className={styles.aboutUsHeader}>About Us</p>
        {isOwnAccount && <EditAboutUs fieldValue={aboutUs} />}
      </div>
      <AboutUsContent content={aboutUs} />
    </>
  );
};

export const AboutUsLoading = () => (
  <div className={styles.aboutUsSkeleton}>
    <SkeletonLoader width="50%" height="40px" />
    <SkeletonLoader height="16px" count={10} />
  </div>
);

export const AboutUsError = () => (
  <div className={styles.aboutUsSection}>
    <div className={styles.aboutUsHeadingWrapper}>
      <p className={styles.aboutUsHeader}>About Us</p>
    </div>
    <AlertIcon title="about us error icon" />
  </div>
);

type AboutUsProps = {
  data: string | null;
};

export const AboutUs = ({ data }: AboutUsProps) => {
  return (
    <div className={styles.aboutUsSection}>
      <AboutUsSection aboutUs={data} />
    </div>
  );
};
