import React, { useState } from 'react';
import {
  ActionMenu,
  Button,
  ButtonVariant,
  Ellipsis,
} from '@estimateone/frontend-components';
import { DeleteHistoricalProject } from '../DeleteHistoricalProject';
import { EditHistoricalProject } from '../EditHistoricalProject';
import { ProjectHistory } from 'components/ProjectHistory/ProjectHistoryContent/types';

type HistoricalProjectActionMenuProps = {
  data: ProjectHistory;
};

export const HistoricalProjectActionMenu = ({
  data,
}: HistoricalProjectActionMenuProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  return (
    <>
      <EditHistoricalProject
        data={data}
        isOpen={isEditModalOpen}
        onRequestClose={() => setIsEditModalOpen(false)}
      />

      <DeleteHistoricalProject
        data={data}
        isOpen={isDeleteModalOpen}
        onRequestClose={() => setIsDeleteModalOpen(false)}
      />
      <ActionMenu
        visible={isMenuOpen}
        onClickOutside={() => setIsMenuOpen(false)}
        triggerElement={
          <Button
            variant={ButtonVariant.Transparent}
            aria-expanded={isMenuOpen}
            aria-label="Toggle Options Menu"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <Ellipsis />
          </Button>
        }
      >
        <ActionMenu.Item
          onClick={() => {
            setIsMenuOpen(!isMenuOpen);
            setIsEditModalOpen(true);
          }}
        >
          Edit
        </ActionMenu.Item>
        <ActionMenu.Item
          onClick={() => {
            setIsMenuOpen(!isMenuOpen);
            setIsDeleteModalOpen(true);
          }}
        >
          Delete
        </ActionMenu.Item>
      </ActionMenu>
    </>
  );
};
