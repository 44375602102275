import {
  contractSizeRangeOptions,
  ContractSizeEnum,
  ContractSizeOption,
} from './types';

export const getContractSizeOptions = (
  translate: (id: string) => string,
): ContractSizeOption[] => {
  return contractSizeRangeOptions.map((option: ContractSizeOption) => ({
    ...option,
    label: option.label.replace('$', translate('currencySymbol')),
  }));
};

export const getContractSizeLabel = (
  contractSize: number,
  translate: (id: string) => string,
): string | undefined => {
  return getContractSizeOptions(translate).find(
    ({ min, value }: ContractSizeOption) =>
      (contractSize === ContractSizeEnum.AVAILABLE_UPON_REQUEST &&
        value === ContractSizeEnum.AVAILABLE_UPON_REQUEST) ||
      (contractSize <= value && contractSize >= min),
  )?.label;
};
