import React from 'react';
import { InputFieldWrapper } from '../../../../modules/FinalFormFields/InputFieldWrapper';
import { emailValidate } from '../utils';
import {
  EditOfficeDetailsFieldNames,
  EditOfficeDetailsFieldLabels,
} from './types';

export const EmailField = () => {
  return (
    <div>
      <InputFieldWrapper<string>
        field={EditOfficeDetailsFieldNames.OfficeEmail}
        id={EditOfficeDetailsFieldNames.OfficeEmail}
        label={EditOfficeDetailsFieldLabels.OfficeEmail}
        validator={emailValidate}
      />
    </div>
  );
};
