import React from 'react';
import { E1Link, Timeline } from '@estimateone/frontend-components';

export type ProfileInsightsLogProps = {
  dailyViewers: Map<string, Set<number>>;
  viewerNames: Map<number, string>;
};

export const ProfileInsightsLog = ({
  dailyViewers,
  viewerNames,
}: ProfileInsightsLogProps) => {
  return (
    <div>
      <Timeline fullWidth={false}>
        {Array.from(dailyViewers.entries()).map(([date, viewerIds]) => {
          return (
            <TimelineLogItem
              key={date}
              date={date}
              viewerIds={viewerIds}
              viewerNames={viewerNames}
            />
          );
        })}
      </Timeline>
    </div>
  );
};

type TimelineLogItemProps = {
  date: string;
  viewerIds: Set<number>;
  viewerNames: Map<number, string>;
};

const TimelineLogItem = ({
  date,
  viewerIds,
  viewerNames,
}: TimelineLogItemProps) => {
  const getViewerAffix = (index: number): string | null => {
    const isSecondLastItem = index === viewerIds.size - 2;
    const isLastItem = index === viewerIds.size - 1;

    return isSecondLastItem ? ', and ' : isLastItem ? null : ', ';
  };

  return (
    <Timeline.Segment key={`${date}-segment`} label={date}>
      {Array.from(viewerIds).map((viewerId, index) => {
        const viewerName = viewerNames.get(viewerId);
        const affix = getViewerAffix(index);

        return (
          <React.Fragment key={`${date}-${viewerId}`}>
            <E1Link href={`/network/${viewerId}/view`} target="_blank">
              {viewerName}
            </E1Link>
            {affix}
          </React.Fragment>
        );
      })}
    </Timeline.Segment>
  );
};
