import React, { PropsWithChildren } from 'react';
import { useCompanyLogoURL } from '../../../modules/ProfileProvider';
import styles from './CompanyLogo.module.scss';

/**
 * The `children` prop acts as a fallback. If a company logo can't
 * be found then `children` will be rendered.
 */
export const CompanyLogo = ({ children }: PropsWithChildren<unknown>) => {
  const url = useCompanyLogoURL();

  return (
    <object
      className={styles.logo}
      data={url}
      type="image/png"
      data-testid="company-logo"
    >
      {children}
    </object>
  );
};
