import { gql } from '@apollo/client';

export const UPDATE_TRADES = gql`
  mutation UpdateTrades($stockTradeIds: [EntityId!]!) {
    changeStockTrades(stockTradeIds: $stockTradeIds) {
      id
      name
    }
  }
`;
