import React, { useReducer } from 'react';
import { E1Link, Modal, ModalSize } from '@estimateone/frontend-components';
import { EditCompanyNameForm } from './EditCompanyNameForm/EditCompanyNameForm';

export type EditCompanyNameProps = {
  fieldValue: string | undefined;
};

export const EditCompanyName = ({ fieldValue }: EditCompanyNameProps) => {
  const [isOpen, toggle] = useReducer((curr) => !curr, false);

  return (
    <div>
      <E1Link onClick={toggle}>Edit</E1Link>

      <Modal
        rootElement="#MFE_PROFILES_ROOT"
        isOpen={isOpen}
        onRequestClose={() => toggle()}
        size={ModalSize.Small}
      >
        <EditCompanyNameForm companyName={fieldValue} close={toggle} />
      </Modal>
    </div>
  );
};
