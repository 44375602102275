export enum EditCompanyFieldNames {
  CompanyName = 'CompanyName',
  CompanyABN = 'businessIdentifier',
}

export enum EditCompanyFieldLabels {
  CompanyName = 'Company Name',
  CompanyABN = 'ABN',
}

export type EditCompanyFieldValues = {
  [EditCompanyFieldNames.CompanyName]: string;
  [EditCompanyFieldNames.CompanyABN]: string;
};

export type EditModalProps = {
  fieldName: string;
  fieldLabel: string;
  fieldValue: string | undefined | null;
  toggle: () => void;
  error: string | undefined;
  onClick: (val: EditCompanyFieldValues) => void;
  validate?: (val?: string) => string[] | undefined;
};
