import React from 'react';
import { Modal, ModalSize } from '@estimateone/frontend-components';
import { EditOfficeDetailsForm } from '../EditOfficeDetailsForm';
import { StateType } from 'types';

type EditOfficeDetailsProps = {
  isOpen: boolean;
  toggle: React.DispatchWithoutAction;
  accountOfficeId: number;
  name: string;
  streetAddress: string | null;
  suburb: string | null;
  state: StateType;
  city: string | null;
  province: string | null;
  postcode: string | null;
  officeEmail: string | null;
  phoneNumber: string | null;
  website: string | null;
};

export const EditOfficeDetailsModal = ({
  accountOfficeId,
  streetAddress,
  suburb,
  state,
  phoneNumber,
  postcode,
  website,
  officeEmail,
  name,
  city,
  province,
  isOpen,
  toggle,
}: EditOfficeDetailsProps) => {
  return (
    <div id="EDIT-OFFICE-DETAILS-OVERRIDE">
      <Modal
        rootElement="#MFE_PROFILES_ROOT"
        isOpen={isOpen}
        onRequestClose={toggle}
        size={ModalSize.Small}
      >
        <Modal.Section>
          <EditOfficeDetailsForm
            AccountOfficeId={accountOfficeId}
            Name={name}
            StreetAddress={streetAddress}
            Suburb={suburb}
            State={state}
            City={city}
            Province={province}
            Website={website}
            PhoneNumber={phoneNumber}
            Postcode={postcode}
            OfficeEmail={officeEmail}
            close={toggle}
          />
        </Modal.Section>
      </Modal>
    </div>
  );
};
