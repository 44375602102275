import React from 'react';
import { useField, useForm } from 'react-final-form';
import { FormatOptionLabelMeta } from 'react-select/dist/declarations/src/Select';
import {
  AsyncSelect,
  Icon,
  IconName,
  Tag,
  TagVariant,
} from '@estimateone/frontend-components';
import kebabCase from 'lodash.kebabcase';
import { FieldWrapper } from 'modules/FinalFormFields/FieldWrapper';
import { getCategoryLabel } from 'components/Categories/utils';
import { useDebouncedProjectSearch } from './hooks';
import {
  LinkedProjectOption,
  ProjectHistoryFieldLabels,
  ProjectHistoryFieldNames,
} from '../../types';
import { SearchE1Projects_searchE1Projects_projects_address_state } from './__generated__/SearchE1Projects';
import styles from './SearchableProjectName.module.scss';

const Placeholder = () => (
  <div className={styles.projectNamePlaceholder}>
    <Icon name={IconName.Search} />
    <p>Search by Name, ID or Create new</p>
  </div>
);

export const CREATE_NEW_OPTION: LinkedProjectOption = {
  value: '-1',
  label: 'Create New',
  id: null,
  status: null,
  state: null,
  category: null,
};

const NO_MATCHING_PROJECTS_MESSAGE = 'No matching projects found';
export const NO_MATCHING_PROJECTS_OPTION: LinkedProjectOption = {
  id: null,
  value: '-2',
  label: NO_MATCHING_PROJECTS_MESSAGE,
  status: null,
  state: null,
  category: null,
};

const formatProjectLabel = (
  { label, value, status, state }: LinkedProjectOption,
  { context }: FormatOptionLabelMeta<LinkedProjectOption>,
) => {
  if (context === 'menu') {
    //Format label in dropdown
    if (value === CREATE_NEW_OPTION.value) {
      return (
        <div className={styles.createNewOption}>
          <div className={styles.createNewOptionIcon}>
            <Icon name={IconName.Add} />
          </div>
          {label}
        </div>
      );
    }

    return (
      <div className={styles.projectNameOption}>
        <span>{label}</span>
        {state ? <span className={styles.stateName}>{state.name}</span> : null}
        {status ? (
          <div className={styles.status} data-testid="linked-project-status">
            <Tag variant={TagVariant.Iron050} text={status} />
          </div>
        ) : null}
      </div>
    );
  }

  return label;
};

export const SearchableProjectName = () => {
  const {
    input: { value },
    meta: { error, submitError, touched },
  } = useField<LinkedProjectOption>(ProjectHistoryFieldNames.Name);

  const { change } = useForm();
  const search = useDebouncedProjectSearch();

  type SelectedOptionArgs = {
    name: string;
    id: number | null;
    state: SearchE1Projects_searchE1Projects_projects_address_state | null;
    category: string | null;
  };

  const setSelectedOption = ({
    name,
    id,
    state,
    category,
  }: SelectedOptionArgs) => {
    change(ProjectHistoryFieldNames.Name, name);
    change(ProjectHistoryFieldNames.LinkedProjectId, id);
    state &&
      change(ProjectHistoryFieldNames.State, {
        value: state.shortName,
        label: state.shortName,
      });
    category &&
      change(ProjectHistoryFieldNames.Category, {
        value: kebabCase(category).replaceAll('-', '_').toUpperCase(),
        label: getCategoryLabel(category),
      });
  };

  return (
    <div>
      <FieldWrapper
        errors={error || submitError}
        hideErrorMessages={false}
        showErrors={touched}
      >
        <AsyncSelect<LinkedProjectOption>
          isRequired
          id={ProjectHistoryFieldNames.Name}
          label={ProjectHistoryFieldLabels.Name}
          value={
            typeof value === 'object' || !value
              ? value
              : {
                  id: null,
                  state: null,
                  value,
                  label: value,
                  status: null,
                  category: null,
                } // triggers by onInputChange
          }
          hideLabel={false}
          placeholder={<Placeholder />}
          noOptionsMessage={() => NO_MATCHING_PROJECTS_MESSAGE}
          loadOptions={search}
          openMenuOnFocus={true}
          formatOptionLabel={formatProjectLabel}
          isOptionDisabled={({ value }) => {
            return value === NO_MATCHING_PROJECTS_OPTION.value;
          }}
          onChange={(selectedOption, actionMeta) => {
            if (selectedOption?.value === CREATE_NEW_OPTION.value) {
              return;
            }
            // on user selecting an option, update state
            if (
              actionMeta.action === 'select-option' &&
              selectedOption !== null
            ) {
              setSelectedOption({
                name: selectedOption.label,
                id: selectedOption.id,
                state: selectedOption.state,
                category: selectedOption.category,
              });
            }
          }}
          onInputChange={(inputValue, actionMeta) => {
            // on user typing, update state
            if (actionMeta.action === 'input-change' && inputValue !== null) {
              setSelectedOption({
                name: inputValue,
                id: null,
                state: null,
                category: null,
              });
            }
          }}
        />
      </FieldWrapper>
    </div>
  );
};
