import React from 'react';
import { AlertIcon, ContractValueIcon } from '@estimateone/frontend-components';
import { ProfileBadgeCard } from '../../ProfileBadgeCard';
import { ProfileBadgeIconWrapper } from '../../ProfileBadgeIconWrapper';
import { contractSizeLabelMap } from './utils';
import {
  ContractSizeMaxEnum,
  ContractSizeMinEnum,
} from '../../../__generated__/globalTypes';
import styles from './ContractSizeBadge.module.scss';

type ContractSizeBadgeProps = {
  contractSizeMin: ContractSizeMinEnum | null;
  contractSizeMax: ContractSizeMaxEnum | null;
};

export const ContractSizeIcon = () => (
  <ProfileBadgeIconWrapper
    status="info"
    content={
      <ContractValueIcon viewBox="0 0 20 24" className={styles.contractIcon} />
    }
  />
);

const MaxContractOption = ContractSizeMaxEnum.SIZE_10M_PLUS;

type ContractRangeInput = {
  contractSizeMin: ContractSizeMinEnum | null;
  contractSizeMax: ContractSizeMaxEnum | null;
};

export const formatContractRange = (
  input: ContractRangeInput,
): string | undefined => {
  const contractSizeMin = input.contractSizeMin
    ? contractSizeLabelMap(input.contractSizeMin)
    : null;
  const contractSizeMax = input.contractSizeMax
    ? contractSizeLabelMap(input.contractSizeMax)
    : null;

  if (contractSizeMin && contractSizeMax) {
    return `${contractSizeMin} - ${contractSizeMax}`;
  }

  if (input.contractSizeMax === MaxContractOption) {
    return `All`;
  }

  if (contractSizeMin) {
    return `Above ${contractSizeMin}`;
  }

  if (contractSizeMax) {
    return `Below ${contractSizeMax}`;
  }
};

export const formatContractSizeSubHeading = ({
  contractSizeMin,
  contractSizeMax,
}: ContractRangeInput): string => {
  if (contractSizeMax === MaxContractOption && !contractSizeMin) {
    return 'Contract sizes';
  }

  return 'Contract size';
};

export const ContractSizeBadge = ({
  contractSizeMax,
  contractSizeMin,
}: ContractSizeBadgeProps) => {
  const contractRange = formatContractRange({
    contractSizeMax,
    contractSizeMin,
  });

  const contractSizeSubHeading = formatContractSizeSubHeading({
    contractSizeMax,
    contractSizeMin,
  });

  return (
    <ProfileBadgeCard
      heading={contractRange}
      subHeading={contractSizeSubHeading}
      icon={<ContractSizeIcon />}
    />
  );
};

export const ContractSizeBadgeViewError = () => (
  <div className={styles.errorIconContainer}>
    <AlertIcon title="Contract size error icon" />
  </div>
);
