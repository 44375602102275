import React from 'react';
import { formatDistanceToNowStrict } from 'date-fns';

type LastActiveProps = {
  lastActive: string | null;
};

const getDateOrNull = (date: string | undefined | null) => {
  if (!date) {
    return null;
  }

  try {
    return formatDistanceToNowStrict(new Date(date), { addSuffix: true });
  } catch (error) {
    return null;
  }
};

export const LastActive = ({ lastActive }: LastActiveProps) => {
  const date = getDateOrNull(lastActive);
  return date ? <span> Last active: {date}</span> : <span />;
};
