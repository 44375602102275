import React, { useReducer } from 'react';
import { useQuery } from '@apollo/client';
import { E1Link, Modal, ModalSize } from '@estimateone/frontend-components';
import { EditTradesForm } from './EditTradesForm/EditTradesForm';
import { GET_ALL_STOCK_TRADES } from '../queries';
import { SubbieStockTradesQuery } from '../__generated__/SubbieStockTradesQuery';

const ALLOW_MODAL_OVERFLOW = {
  style: {
    content: {
      overflow: 'visible',
    },
  },
};

type TradesProps = {
  trades: number[] | null;
};

export const EditTrades = ({ trades }: TradesProps) => {
  const [isOpen, toggle] = useReducer((curr) => !curr, false);

  const {
    loading,
    data,
    error: queryError,
  } = useQuery<SubbieStockTradesQuery>(GET_ALL_STOCK_TRADES);

  if (loading || queryError || data === undefined) {
    return <></>;
  }

  return (
    <div id="EDIT-TRADES-OVERRIDE">
      <Modal
        rootElement="#MFE_PROFILES_ROOT"
        isOpen={isOpen}
        onRequestClose={toggle}
        size={ModalSize.Small}
        reactModalProps={ALLOW_MODAL_OVERFLOW}
      >
        <Modal.Section>
          <EditTradesForm
            trades={trades}
            tradesOptions={data.stockTrades}
            close={toggle}
          />
        </Modal.Section>
      </Modal>
      <E1Link onClick={toggle}>Edit</E1Link>
    </div>
  );
};
