/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef } from 'react';
import { joinClassNames } from '@estimateone/frontend-components';
import styles from './UnstyledButton.module.scss';

/**
 * A Button that is styled to look like nothing.
 * Useful when the inside needs to be an image or a div
 */
const UnstyledButtonBase = (
  { children, className, ...props }: JSX.IntrinsicElements['button'],
  ref: React.Ref<HTMLButtonElement>,
): JSX.Element => (
  <button
    className={joinClassNames(styles.button, className)}
    {...props}
    ref={ref}
  >
    {children}
  </button>
);

export const UnstyledButton = forwardRef(UnstyledButtonBase);
