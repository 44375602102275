import React from 'react';
import { joinClassNames } from '@estimateone/frontend-components';
import { ProfileInsightsPage } from 'components/CompanyProfile/ProfileInsights';
import {
  AboutUs,
  AboutUsError,
  AboutUsLoading,
} from '../../components/AboutUs';
import { BackButton } from '../../components/BackButton';
import { Card, CardBody } from '../../components/Card';
import {
  Categories,
  CategoriesErrorView,
  CategoriesLoader,
} from '../../components/Categories';
import {
  CompanyInfo,
  CompanyInfoError,
  CompanyInfoLoading,
} from '../../components/CompanyInfo';
import { ProfileNavigation } from '../../components/CompanyProfile/ProfileNavigation';
import { ProfileSettingsPage } from '../../components/CompanyProfile/ProfileSettings';
import {
  GlobalErrorBanner,
  useGlobalErrorBanner,
} from '../../components/GlobalErrorBanner';
import {
  OfficeContactList,
  OfficeContactListErrorView,
  OfficeContactListLoader,
} from '../../components/OfficeContactList';
import {
  PrimaryContact,
  PrimaryContactErrorView,
  PrimaryContactLoader,
} from '../../components/PrimaryContact';
import {
  ABNBadge,
  ABNBadgeError,
  ABNBadgeLoading,
} from '../../components/ProfileBadgeSection/ABNBadge';
import {
  ContractAndWorkforceSize,
  ContractAndWorkforceSizeError,
  ContractAndWorkforceSizeLoading,
} from '../../components/ProfileBadgeSection/ContractAndWorkforceSize/ContractAndWorkforceSize';
import {
  LocationBadge,
  LocationBadgeError,
  LocationBadgeLoader,
} from '../../components/ProfileBadgeSection/LocationBadge/LocationBadge';
import {
  ProjectsBadgeError,
  ProjectsBadgeWrapper,
  ProjectsBadgeWrapperLoading,
} from '../../components/ProfileBadgeSection/ProjectsBadgeWrapper/ProjectsBadgeWrapper';
import {
  ProjectHistory,
  ProjectHistoryErrorIcon,
  ProjectHistoryLoader,
} from '../../components/ProjectHistory';
import {
  Trades,
  TradesErrorView,
  TradesLoadingView,
} from '../../components/Trades';
import {
  useProfileAccountId,
  useProfileContext,
} from '../../modules/ProfileProvider';
import { ViewerProvider } from '../../modules/ViewerProvider';
import styles from './CompanyProfile.module.scss';

export const Divider = () => <div className={styles.divider} />;

export type ProfileLayout = 'page' | 'slider';
export type ProfilePageType = 'profile' | 'settings' | 'insights';

export type CompanyProfileProps = {
  layout: ProfileLayout;
  pageType?: ProfilePageType;
  isSubbieProfilesInsightsEnabled?: boolean;
};

export const CompanyProfile = ({
  layout,
  pageType = 'profile',
  isSubbieProfilesInsightsEnabled = false,
}: CompanyProfileProps) => {
  const { accountId } = useProfileAccountId();

  return (
    <ViewerProvider accountId={accountId}>
      <div id="MFE_PROFILES_ROOT" className={styles.profilePage}>
        <ProfileNavigation
          layout={layout}
          isSubbieProfilesInsightsEnabled={isSubbieProfilesInsightsEnabled}
        />
        <div className={styles.profilePanel}>
          {layout === 'page' && (
            <div className={styles.breadcrumb}>
              <BackButton />
            </div>
          )}
          {pageType === 'settings' ? (
            <section className={styles.profileSettings}>
              <ProfileSettingsPage />
            </section>
          ) : isSubbieProfilesInsightsEnabled && pageType === 'insights' ? (
            <section className={styles.profileInsights}>
              <ProfileInsightsPage />
            </section>
          ) : (
            <section
              className={joinClassNames(
                styles.companyProfile,
                layout === 'slider' && styles.sliderCompanyProfile,
              )}
            >
              <CompanyProfilePage layout={layout} />
            </section>
          )}
        </div>
      </div>
    </ViewerProvider>
  );
};

export const CompanyProfilePage = ({ layout }: CompanyProfileProps) => {
  const { openBanner } = useGlobalErrorBanner();
  const { profile, loading, error } = useProfileContext();

  if (loading) {
    return (
      <>
        {layout === 'page' && (
          <div className={styles.profileHeader}>
            <GlobalErrorBanner />
            <h1 className={styles.title}>Company Profile</h1>
            <CompanyInfoLoading />
          </div>
        )}
        <div className={styles.profileBadges}>
          <div
            className={joinClassNames(
              styles.container,
              layout === 'slider' && styles.sliderContainer,
            )}
          >
            <ABNBadgeLoading />
            <ProjectsBadgeWrapperLoading />
            <Divider />
            <ContractAndWorkforceSizeLoading />
            <Divider />
            <LocationBadgeLoader />
          </div>
        </div>
        <div className={styles.aside}>
          <Card>
            <CardBody>
              <TradesLoadingView />
              <CategoriesLoader />
            </CardBody>
            <PrimaryContactLoader />
          </Card>
        </div>
        <div
          className={joinClassNames(
            styles.profileContent,
            layout === 'slider' && styles.sliderProfileContent,
          )}
        >
          <AboutUsLoading />
          <OfficeContactListLoader />
          <ProjectHistoryLoader />
        </div>
      </>
    );
  }

  if (error || profile === null || profile === undefined) {
    openBanner();
    return (
      <>
        {layout === 'page' && (
          <div className={styles.profileHeader}>
            <GlobalErrorBanner />
            <h1 className={styles.title}>Company Profile</h1>
            <CompanyInfoError />
          </div>
        )}
        <div className={styles.profileBadges}>
          <div
            className={joinClassNames(
              styles.container,
              layout === 'slider' && styles.sliderContainer,
            )}
          >
            <ABNBadgeError />
            <Divider />
            <ProjectsBadgeError />
            <Divider />
            <ContractAndWorkforceSizeError />
            <Divider />
            <LocationBadgeError />
          </div>
        </div>
        <div className={styles.aside}>
          <Card>
            <CardBody>
              <TradesErrorView />
              <CategoriesErrorView />
            </CardBody>
            <PrimaryContactErrorView />
          </Card>
        </div>
        <div
          className={`${styles.profileContent} ${
            layout === 'slider' ? styles.sliderProfileContent : ''
          }`}
        >
          <AboutUsError />
          <OfficeContactListErrorView />
          <ProjectHistoryErrorIcon />
        </div>
      </>
    );
  }

  return (
    <>
      {layout === 'page' && (
        <div className={styles.profileHeader}>
          <GlobalErrorBanner />
          <h1 className={styles.title}>Company Profile</h1>
          <CompanyInfo
            companyName={profile.name ?? undefined}
            memberSince={profile.memberSince ?? undefined}
            lastActiveAt={profile.lastActiveAt ?? null}
          />
        </div>
      )}
      <div className={styles.profileBadges}>
        <div
          className={joinClassNames(
            styles.container,
            layout === 'slider' && styles.sliderContainer,
          )}
        >
          <ABNBadge businessIdentifier={profile.companyNumber} />
          <Divider />
          <ProjectsBadgeWrapper count={profile.projectHistory.length} />
          <Divider />
          <ContractAndWorkforceSize
            contractSizeMax={profile.contractSizeMax}
            contractSizeMin={profile.contractSizeMin}
            workforceSize={profile.workforceSize}
          />
          <Divider />
          <LocationBadge
            defaultOffice={
              profile.offices.find((office) => office.isDefaultOffice) ?? null
            }
          />
        </div>
      </div>
      <div className={styles.aside}>
        <Card>
          <CardBody>
            <Trades data={profile.trades} />
            <Categories data={profile.projectCategories} />
          </CardBody>
          <PrimaryContact
            key="primary-contact"
            primaryContact={
              profile.people.find(({ isDefaultContact }) => isDefaultContact) ??
              profile.people.at(0) ?? // if there isn't a default contact use the first in the list
              null
            }
          />
        </Card>
      </div>
      <div
        className={`${styles.profileContent} ${
          layout === 'slider' ? styles.sliderProfileContent : ''
        }`}
      >
        <AboutUs data={profile.aboutUs} />
        <ProjectHistory data={profile.projectHistory} />
        <OfficeContactList data={profile.offices} />
      </div>
    </>
  );
};

export const AddressBookSliderProfile = () => {
  const { profile, loading, error } = useProfileContext();

  if (loading || error || profile === undefined || profile === null) {
    return <p>oops</p>;
  }

  return (
    <div id="MFE_PROFILES_ROOT">
      <ViewerProvider accountId={profile.account?.id}>
        <section className={styles.builderViewProfile}>
          <div className={styles.profileBadges}>
            <div className={styles.container}>
              <ABNBadge businessIdentifier={profile.companyNumber} />
              <Divider />
              <ProjectsBadgeWrapper count={profile.projectHistory.length} />
              <Divider />
              <ContractAndWorkforceSize
                contractSizeMax={profile.contractSizeMax}
                contractSizeMin={profile.contractSizeMin}
                workforceSize={profile.workforceSize}
              />
              <Divider />
              <LocationBadge
                defaultOffice={
                  profile.offices.find((office) => office.isDefaultOffice) ??
                  (profile.offices.length ? profile.offices[0] : null)
                }
              />
            </div>
          </div>
          <div className={styles.profileContent}>
            <AboutUs data={profile.aboutUs} />
          </div>
        </section>
      </ViewerProvider>
    </div>
  );
};
