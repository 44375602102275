import React from 'react';
import {
  AlertIcon,
  Avatar,
  joinClassNames,
  SkeletonLoader,
  TextLink,
} from '@estimateone/frontend-components';
import { AvatarSize } from '@estimateone/frontend-components/dist/src/enums/Avatar';
import { TagGroup } from 'components/NewTagGroup';
import { NoContentPlaceholder } from 'components/NoContentPlaceholder';
import { CardBody } from '../Card';
import { EditPrimaryContact } from './EditPrimaryContact';
import { useAdmin, useOwnAccount } from 'hooks';
import { CompanyProfile_companyProfile_people } from '../../modules/ProfileProvider/__generated__/CompanyProfile';
import styles from './PrimaryContact.module.scss';

export const PrimaryContactViewWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => (
  <CardBody>
    <div className={styles.primaryContactWrapper}>{children}</div>
  </CardBody>
);

export const PrimaryContactErrorView = () => (
  <PrimaryContactViewWrapper>
    <TagGroup header="Contact">
      <AlertIcon title="Contact error icon" />
    </TagGroup>
  </PrimaryContactViewWrapper>
);

export const PrimaryContactLoader = () => (
  <PrimaryContactViewWrapper>
    <TagGroup header="Contact">
      <SkeletonLoader height="16px" count={2} />
    </TagGroup>
  </PrimaryContactViewWrapper>
);

const EmptyView = () => {
  const { isAdmin } = useAdmin();
  const { isOwnAccount } = useOwnAccount();

  if (!isOwnAccount) {
    return <div data-testid="no-contact-view" />;
  }

  return (
    <PrimaryContactViewWrapper>
      <div className={styles.primaryContactHeadingContainer}>
        <h4 className={styles.heading}>Contact</h4>
        {isAdmin && <EditPrimaryContact primaryContact={null} />}
      </div>
      <NoContentPlaceholder />
    </PrimaryContactViewWrapper>
  );
};

type PrimaryContactProps = {
  primaryContact: CompanyProfile_companyProfile_people | null;
};

export const PrimaryContact = ({ primaryContact }: PrimaryContactProps) => {
  const { isOwnAccount } = useOwnAccount();

  if (primaryContact === null) {
    return <EmptyView />;
  }

  const { id, firstName, lastName, contactable } = primaryContact;
  const { email, phone } = contactable ?? {};

  return (
    <PrimaryContactViewWrapper>
      <div className={styles.primaryContactHeadingContainer}>
        <h4 className={styles.heading}>Contact</h4>
        {isOwnAccount && <EditPrimaryContact primaryContact={primaryContact} />}
      </div>
      <Avatar
        firstName={firstName ?? ''}
        lastName={lastName ?? ''}
        userId={Number(id)}
        size={AvatarSize.Profile}
        className={styles.avatar}
      />
      {(firstName || lastName) && (
        <p className={styles.name}>{joinClassNames(firstName, lastName)}</p>
      )}
      {email && (
        // Wrapper to make E1Link styles work properly when email spans into multiple lines
        <div>
          <TextLink className={styles.email} href={`mailto:${email}`}>
            {email}
          </TextLink>
        </div>
      )}
      {phone && <p className={styles.phone}>{phone}</p>}
    </PrimaryContactViewWrapper>
  );
};
