/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useField } from 'react-final-form';
import { Select, SelectProps } from '@estimateone/frontend-components';
import { ValueLabelOption } from '@estimateone/frontend-components/dist/src/components/Form/Select/types';
import { FieldWrapper } from '../FieldWrapper';

export type SingleSelectDropdownFieldProps<TOption extends ValueLabelOption> =
  SelectProps<TOption, false> & {
    fieldName: string;
    hideErrors?: boolean;
    initialValue?: TOption;
    validate?: (value: TOption) => string[] | undefined;
  };

export const SingleSelectDropdownField = <TOption extends ValueLabelOption>({
  fieldName,
  hideErrors = false,
  className,
  validate,
  initialValue,
  ...props
}: SingleSelectDropdownFieldProps<TOption>): React.ReactElement => {
  const {
    input,
    meta: { error, submitError, touched },
  } = useField(fieldName, { validate, initialValue });

  return (
    <div data-name={fieldName} className={className}>
      <FieldWrapper
        errors={error || submitError}
        showErrors={!hideErrors && touched}
      >
        <Select<TOption, false> {...input} {...props} />
      </FieldWrapper>
    </div>
  );
};
