import { GetProfileInsightsDataQuery } from './queries.generated';
import { InsightsStats } from './types';
import { ProfileViewLogSource } from '../../../__generated__/globalTypes';

type MonthlyProfileView = GetProfileInsightsDataQuery['monthlyProfileViews'][0];
type DailyProfileView = GetProfileInsightsDataQuery['dailyProfileViews'][0];

export const getPastYearViewTotals = (
  monthlyProfileViews: MonthlyProfileView[] | undefined,
): InsightsStats => {
  if (!monthlyProfileViews) {
    return {
      totalViews: null,
      totalDirectoryViews: null,
      totalOtherViews: null,
      totalThisMonthViews: null,
    };
  }

  const thisMonth = new Date().getMonth() + 1;
  return monthlyProfileViews.reduce(
    (acc, currentValue) => {
      acc.totalViews = acc.totalViews + currentValue.count;

      if (currentValue.source === ProfileViewLogSource.DIRECTORY) {
        acc.totalDirectoryViews = acc.totalDirectoryViews + currentValue.count;
      } else {
        acc.totalOtherViews = acc.totalOtherViews + currentValue.count;
      }

      if (currentValue.month === thisMonth) {
        acc.totalThisMonthViews = acc.totalThisMonthViews + currentValue.count;
      }

      return acc;
    },
    {
      totalViews: 0,
      totalDirectoryViews: 0,
      totalOtherViews: 0,
      totalThisMonthViews: 0,
    },
  );
};

export const getDailyViewLog = (
  dailyProfileViews: DailyProfileView[] | undefined,
) => {
  const viewerNames = new Map<number, string>();
  const dailyViewers = new Map<string, Set<number>>();

  dailyProfileViews?.forEach((viewItem) => {
    const { accountId, accountName, date } = viewItem;
    viewerNames.set(accountId, accountName);
    const viewersForDate = dailyViewers.get(date);
    const newViewersForDate = viewersForDate
      ? viewersForDate.add(accountId)
      : new Set([accountId]);
    dailyViewers.set(date, newViewersForDate);
  });

  return {
    viewerNames,
    dailyViewers,
  };
};
