import React from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import { App, AppProps } from './App';

window.mountProfilesMFE = (containerId: string, props: AppProps) => {
  const {
    BFF_URI,
    IDP_URI,
    ACCOUNT_ID,
    layout,
    PROFILE_ID,
    isSubbieProfilesInsightsEnabled,
  } = props;
  render(
    <App
      BFF_URI={BFF_URI}
      IDP_URI={IDP_URI}
      ACCOUNT_ID={ACCOUNT_ID}
      PROFILE_ID={PROFILE_ID}
      layout={layout}
      isSubbieProfilesInsightsEnabled={isSubbieProfilesInsightsEnabled}
    />,
    document.getElementById(containerId),
  );
};

window.unmountProfilesMFE = (containerId: string) => {
  const el = document.getElementById(containerId);
  if (el) {
    unmountComponentAtNode(el);
  }
};
