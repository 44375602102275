import React from 'react';
import { DeleteConfirmation } from 'components/DeleteConfirmation';
import { useDeleteHistoricalProject } from './hooks';
import { ProjectHistory } from 'components/ProjectHistory/ProjectHistoryContent/types';
const PROJECT_HISTORY = 'Project History';

export type DeleteHistoricalProjectProps = {
  data: ProjectHistory;
  isOpen: boolean;
  onRequestClose: () => void;
};

export const DeleteHistoricalProject = ({
  data,
  isOpen,
  onRequestClose,
}: DeleteHistoricalProjectProps) => {
  const { submit: deleteProject, error } = useDeleteHistoricalProject(data.id);

  const onConfirm = async () => {
    const { errors } = await deleteProject();
    if (errors) {
      return;
    } else {
      onRequestClose();
    }
  };

  return (
    <DeleteConfirmation
      name={data.name}
      error={error}
      onAbort={onRequestClose}
      isOpen={isOpen}
      field={PROJECT_HISTORY}
      onConfirm={onConfirm}
    />
  );
};
