import React from 'react';
import { InputFieldWrapper } from '../../../../modules/FinalFormFields/InputFieldWrapper';
import {
  EditOfficeDetailsFieldLabels,
  EditOfficeDetailsFieldNames,
} from './types';

export const PostcodeField = () => {
  return (
    <InputFieldWrapper<string>
      field={EditOfficeDetailsFieldNames.Postcode}
      id={EditOfficeDetailsFieldNames.Postcode}
      label={EditOfficeDetailsFieldLabels.Postcode}
      validator={() => undefined}
    />
  );
};
