import { gql } from '@apollo/client';

export const EDIT_SSC_OPT_OUT_SETTING = gql`
  mutation EditSSCOptOutSetting($optOut: Boolean!) {
    editSSCOptOutSetting(optOut: $optOut) {
      id
      attribute
      value
      success
      errors
    }
  }
`;
