import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import {
  Button,
  ButtonSize,
  ButtonVariant,
  Card,
  CardVariant,
  Icon,
  IconName,
  LoadingSpinner,
  Tag,
  TagVariant,
} from '@estimateone/frontend-components';
import { GlobalErrorBanner } from 'components/GlobalErrorBanner';
import { useProfileContext } from '../../../modules/ProfileProvider';
import { BuilderViewsChart } from './BuilderViewsChart';
import { CompleteProfileInsightsAlert } from './CompleteProfileInsightsAlert';
import { IncompleteProfileInsightsAlert } from './IncompleteProfileInsightsAlert';
import { InsightsStatsPanel } from './InsightsStatsPanel';
import { ProfileInsightsLog } from './ProfileInsightsLog';
import { getDailyViewLog, getPastYearViewTotals } from './aggregateViewStats';
import { getProfileStatus, hasCompletedProfile } from './utils';
import { GET_PROFILE_INSIGHTS_DATA } from './queries';
import { GetProfileInsightsDataQuery } from './queries.generated';
import { ProfileRoute } from '../../../pages/enums';
import styles from './ProfileInsights.module.scss';

export const ProfileInsightsPage = () => {
  const { data, loading } = useQuery<GetProfileInsightsDataQuery>(
    GET_PROFILE_INSIGHTS_DATA,
  );

  const navigate = useNavigate();
  const handleEditProfileClick = () => {
    navigate(ProfileRoute.CompanyProfile);
  };

  const { profile } = useProfileContext();

  if (loading) {
    return <LoadingSpinner />;
  }

  const profileStatus = getProfileStatus(profile);
  const isProfileComplete = hasCompletedProfile(profileStatus);

  const stats = getPastYearViewTotals(data?.monthlyProfileViews);

  const { dailyViewers, viewerNames } = getDailyViewLog(
    data?.dailyProfileViews,
  );

  return (
    <>
      <GlobalErrorBanner />
      <div className={styles.headerActionContainer}>
        <div className={styles.titleContainer}>
          <h1 className={styles.settingsHeader}>Profile Insights</h1>
          <Tag variant={TagVariant.West200} text="BETA" />
        </div>
        <Button
          className={styles.buttonRight}
          variant={ButtonVariant.Secondary}
          onClick={handleEditProfileClick}
          size={ButtonSize.Small}
        >
          <Icon name={IconName.User} marginRight="small" size="1.2em" />
          Open/edit profile
        </Button>
      </div>
      {isProfileComplete ? (
        <CompleteProfileInsightsAlert />
      ) : (
        <IncompleteProfileInsightsAlert profileStatus={profileStatus} />
      )}
      <Card variant={CardVariant.Grey}>
        <Card.Body isWhite>
          <div className={styles.cardContent}>
            <InsightsStatsPanel
              stats={stats}
              accountCountryId={data?.currentAccount?.primaryCountry?.id}
            />
            <BuilderViewsChart
              monthlyProfileViews={data?.monthlyProfileViews}
            />
          </div>
        </Card.Body>
      </Card>
      {viewerNames.size > 0 && (
        <>
          <h4>Who's viewed your Profile?</h4>
          <ProfileInsightsLog
            dailyViewers={dailyViewers}
            viewerNames={viewerNames}
          />
        </>
      )}
    </>
  );
};
