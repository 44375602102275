import React from 'react';
import { NoContentPlaceholder } from 'components/NoContentPlaceholder';
import styles from './ProfileBadgeCard.module.scss';

export type ProfileBadgeCardProps = {
  icon: React.ReactNode;
  heading: string | null | undefined;
  subHeading?: string;
};

export const ProfileBadgeCard = ({
  icon,
  heading,
  subHeading,
}: ProfileBadgeCardProps) => {
  return (
    <div className={styles.profileBadgeCardWrapper}>
      {icon}
      <div className={styles.profileContentWrapper}>
        {heading ? (
          <div className={styles.heading}>{heading}</div>
        ) : (
          <NoContentPlaceholder />
        )}
        {subHeading && <div className={styles.subHeading}>{subHeading}</div>}
      </div>
    </div>
  );
};
