import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  ButtonSize,
  ButtonVariant,
  InfoCard,
  joinClassNames,
  RadioButton,
  RadioButtonGroup,
} from '@estimateone/frontend-components';
import { useGlobalErrorBanner } from 'components/GlobalErrorBanner';
import { useViewerContext } from '../../../../modules/ViewerProvider/ViewerProvider';
import { SSCOptOutConfirmModal } from '../SSCOptOutConfirmModal';
import { useFlashMessage } from '../../../../hooks/useFlashMessage';
import { useSubmitSSCOptOut } from './hooks';
import { SSCOptOutOptionEnum } from './enums';
import { OptionProps } from '../types';
import styles from '../ProfileSettings.module.scss';

export const SSCOptOutOption = ({ className }: OptionProps) => {
  const { loading, hasOptedOutOfSSC } = useViewerContext();
  const [isOptingOut, setIsOptingOut] = useState(hasOptedOutOfSSC);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const { submit } = useSubmitSSCOptOut();
  const { successFlash } = useFlashMessage();
  const { openBanner } = useGlobalErrorBanner();

  const onChangeSSCOptOut = useCallback((value: string) => {
    setIsOptingOut(value === SSCOptOutOptionEnum.OptOutValue);
  }, []);

  const handleError = useCallback(() => {
    // reset to current state
    setIsOptingOut(hasOptedOutOfSSC);
    openBanner();
  }, [setIsOptingOut, openBanner, hasOptedOutOfSSC]);

  const onConfirmSave = useCallback(async () => {
    setShowConfirmModal(false);
    try {
      const errors = await submit(isOptingOut);
      if (errors?.length) {
        handleError();
      } else {
        const message = isOptingOut
          ? SSCOptOutOptionEnum.OptOutSuccessMessage
          : SSCOptOutOptionEnum.OptInSuccessMessage;
        successFlash(SSCOptOutOptionEnum.SuccessTitle, message);
      }
    } catch (error: unknown) {
      handleError();
    }
  }, [submit, isOptingOut, handleError, successFlash]);

  const onCancelSave = useCallback(() => {
    setShowConfirmModal(false);
    setIsOptingOut(hasOptedOutOfSSC);
  }, [hasOptedOutOfSSC]);

  const onClickSave = useCallback(async () => {
    if (isOptingOut) {
      return setShowConfirmModal(true);
    }
    await onConfirmSave();
  }, [isOptingOut, onConfirmSave]);

  // Update the form when the initial graphql query finishes loading
  useEffect(() => {
    if (!loading) {
      setIsOptingOut(hasOptedOutOfSSC);
    }
  }, [loading, hasOptedOutOfSSC]);

  const optInOutValue = isOptingOut
    ? SSCOptOutOptionEnum.OptOutValue
    : SSCOptOutOptionEnum.OptInValue;

  const value = loading ? '' : optInOutValue;

  return (
    <section className={joinClassNames(styles.option, className)}>
      <h2>{SSCOptOutOptionEnum.SectionTitle}</h2>
      <InfoCard
        heading={SSCOptOutOptionEnum.InfoCardHeading}
        icon={<></>}
        className={styles.infoCard}
      >
        <ul>
          <li>{SSCOptOutOptionEnum.InfoCardDescriptionLineOne}</li>
          <li>{SSCOptOutOptionEnum.InfoCardDescriptionLineTwo}</li>
        </ul>
      </InfoCard>
      <RadioButtonGroup
        name={SSCOptOutOptionEnum.OptionName}
        value={value}
        onChange={onChangeSSCOptOut}
        className={styles.radioGroup}
      >
        <RadioButton
          id={SSCOptOutOptionEnum.OptInValue}
          name={SSCOptOutOptionEnum.OptionName}
          value={SSCOptOutOptionEnum.OptInValue}
          className={styles.radioButtonWrapper}
        >
          {SSCOptOutOptionEnum.OptInLabel}
        </RadioButton>
        <RadioButton
          id={SSCOptOutOptionEnum.OptOutValue}
          name={SSCOptOutOptionEnum.OptionName}
          value={SSCOptOutOptionEnum.OptOutValue}
          className={styles.radioButtonWrapper}
        >
          {SSCOptOutOptionEnum.OptOutLabel}
        </RadioButton>
      </RadioButtonGroup>
      <Button
        variant={ButtonVariant.Secondary}
        onClick={onClickSave}
        size={ButtonSize.Small}
      >
        {SSCOptOutOptionEnum.SaveButton}
      </Button>
      {!loading ? (
        <SSCOptOutConfirmModal
          isOpen={showConfirmModal}
          onClose={onCancelSave}
          onConfirm={onConfirmSave}
        />
      ) : null}
    </section>
  );
};
