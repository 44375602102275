import React from 'react';
import { AlertIcon, LocationIcon } from '@estimateone/frontend-components';
import { SkeletonLoader } from '@estimateone/frontend-components';
import { ProfileBadgeCard } from '../../ProfileBadgeCard';
import { ProfileBadgeIconWrapper } from '../../ProfileBadgeIconWrapper';
import styles from './LocationBadge.module.scss';

export const LocationBadgeError = () => (
  <div className={styles.errorIconContainer}>
    <AlertIcon title="Error retrieving location" />
  </div>
);

export const LocationBadgeLoader = () => (
  <div className={styles.container}>
    <SkeletonLoader width="100%" height="16px" />
  </div>
);

const LocationIconWrapper = () => (
  <ProfileBadgeIconWrapper status="info" content={<LocationIcon />} />
);

type LocationBadgeProps = {
  defaultOffice: {
    address: {
      city: string | null;
      state: string | null;
      province: string | null;
    };
  } | null;
};

export const LocationBadge = ({ defaultOffice }: LocationBadgeProps) => {
  if (defaultOffice === undefined) {
    return <LocationBadgeError />;
  }

  const location =
    defaultOffice?.address?.state ??
    defaultOffice?.address?.city ??
    defaultOffice?.address?.province ??
    'Empty';

  return (
    <div className={styles.container}>
      <ProfileBadgeCard
        heading={location}
        subHeading="Office location"
        icon={<LocationIconWrapper />}
      />
    </div>
  );
};
