import React from 'react';
import {
  Alert,
  AlertVariant,
  Button,
  ButtonVariant,
  Modal,
  ModalSize,
} from '@estimateone/frontend-components';
import { SSCOptOutConfirmModalEnum } from './enums';
import styles from './SSCOptOutConfirmModal.module.scss';

type SSCOptOutConfirmModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

export const SSCOptOutConfirmModal = ({
  isOpen,
  onClose,
  onConfirm,
}: SSCOptOutConfirmModalProps) => {
  return (
    <Modal
      rootElement="#MFE_PROFILES_ROOT"
      isOpen={isOpen}
      onRequestClose={onClose}
      size={ModalSize.Small}
      hideClose={true}
    >
      <Modal.Section>
        <div className={styles.modalSection}>
          <h1>{SSCOptOutConfirmModalEnum.Title}</h1>
          <div className={styles.modalBody}>
            <Alert variant={AlertVariant.Yellow}>
              {SSCOptOutConfirmModalEnum.AlertText}
            </Alert>
            <p>
              Your Company Profile can <strong>not</strong> be found by
              Suppliers on projects that have been added to your Watchlist as
              'interested', 'quoting' or 'quoted'.
            </p>
          </div>
          <div className={styles.modalButtonOptions}>
            <Button onClick={onClose} variant={ButtonVariant.Grey}>
              {SSCOptOutConfirmModalEnum.CancelButton}
            </Button>
            <Button onClick={onConfirm} variant={ButtonVariant.Primary}>
              {SSCOptOutConfirmModalEnum.ConfirmButton}
            </Button>
          </div>
        </div>
      </Modal.Section>
    </Modal>
  );
};
