import { gql } from '@apollo/client';

export const GET_ACCOUNT_FEATURES = gql`
  query AvailableFeatures($accountId: EntityId!) {
    availableFeatures(id: $accountId) {
      companyProfileAdvanced
      companyProfileBasic
    }
  }
`;
