import React from 'react';
import { AlertIcon } from '@estimateone/frontend-components';
import { ReactComponent as EmployeeIconComponent } from '../../../assets/svgs/employee.svg';
import { ProfileBadgeCard } from '../../ProfileBadgeCard';
import { ProfileBadgeIconWrapper } from '../../ProfileBadgeIconWrapper';
import { getWorkforceSizeLabel } from './utils';
import { WorkforceSizeEnum } from '../../../__generated__/globalTypes';
import styles from './EmployeeBadge.module.scss';

type WorkforceSizeProps = {
  workforceSize: WorkforceSizeEnum | null;
};

export const EmployeeIcon = () => (
  <ProfileBadgeIconWrapper status="info" content={<EmployeeIconComponent />} />
);

export const EmployeeBadge = ({ workforceSize }: WorkforceSizeProps) => {
  const workforceSizeLabel = workforceSize
    ? getWorkforceSizeLabel(workforceSize)
    : null;

  return (
    <ProfileBadgeCard
      heading={workforceSizeLabel}
      subHeading="Employees"
      icon={<EmployeeIcon />}
    />
  );
};

export const EmployeeBadgeViewError = () => (
  <div className={styles.errorIconContainer}>
    <AlertIcon title="employee size error icon" />
  </div>
);
