import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity';
import { S3Client, DeleteObjectCommand } from '@aws-sdk/client-s3';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity';
import { useGlobalErrorBanner } from 'components/GlobalErrorBanner';
import { useProfileAccountId } from '../../../modules/ProfileProvider';
import { getCompanyLogoKey } from '../utils';

const REGION = 'ap-southeast-2';

export const useLogoRemover = () => {
  const { openBanner } = useGlobalErrorBanner();
  const { accountId } = useProfileAccountId();

  const client = new S3Client({
    region: REGION,
    credentials: fromCognitoIdentityPool({
      client: new CognitoIdentityClient({ region: REGION }),
      identityPoolId: process.env.REACT_APP_COMPANY_LOGO_BUCKET_POOL ?? '',
    }),
  });

  return {
    onRemove: async () => {
      if (accountId === null) {
        openBanner();
        return Promise.resolve();
      }

      const logoKey = getCompanyLogoKey(accountId.toString());

      // TODO: users can delete each others' logos
      const command = new DeleteObjectCommand({
        Bucket: process.env.REACT_APP_COMPANY_LOGO_BUCKET,
        Key: logoKey,
      });

      try {
        await client.send(command);
        window.location.reload();
      } catch (error) {
        openBanner();
      }
    },
  };
};
