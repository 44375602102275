import { useContext } from 'react';
import { ViewerContext } from '../../modules/ViewerProvider/ViewerProvider';

type UseOwnAccountPayload = { isOwnAccount: null | boolean; loading: boolean };

export const useOwnAccount = (): UseOwnAccountPayload => {
  const { loading, context } = useContext(ViewerContext);

  const isOwnAccount = context === null ? false : context?.isOwnAccount();

  return { isOwnAccount, loading };
};
