import React from 'react';
import { SingleSelectDropdownField } from '../../../../../modules/FinalFormFields/SingleSelectDropdownField/SingleSelectDropdownField';
import { WorkforceSizeEnumMap } from '../values';
import { getWorkforceSizeLabel } from '../../utils';
import { EditWorkforceSizeFieldName } from './types';
import { WorkforceSizeEnum } from '../../../../../__generated__/globalTypes';

export type WorkforceSizeOption = {
  value: WorkforceSizeEnum | null;
  label: string;
};

export const EMPTY_WORKFORCE = {
  value: null,
  label: '-',
};

const workforceSizeOptions = Object.keys(WorkforceSizeEnum)
  .map((value: unknown) => value as keyof typeof WorkforceSizeEnum)
  .map((value: keyof typeof WorkforceSizeEnum) => WorkforceSizeEnum[value])
  .map((value: WorkforceSizeEnum) => ({
    value: value,
    label: getWorkforceSizeLabel(value),
  }))
  .sort(
    (a, b) => WorkforceSizeEnumMap[a.value] - WorkforceSizeEnumMap[b.value],
  );

export const WorkforceSize = () => (
  <SingleSelectDropdownField<WorkforceSizeOption>
    id="workforce-size"
    label="Workforce size"
    fieldName={EditWorkforceSizeFieldName.WorkforceSizeName}
    options={[EMPTY_WORKFORCE, ...workforceSizeOptions]}
  />
);
