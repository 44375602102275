import React from 'react';
import { AlertIcon, SkeletonLoader } from '@estimateone/frontend-components';
import { TagGroup as NewTagGroup } from '../NewTagGroup';
import { TagGroup } from '../NewTagGroup';
import { NoContentPlaceholder } from '../NoContentPlaceholder';
import { EditTrades } from './EditTrades';
import { useOwnAccount } from 'hooks';

import { CompanyProfile_companyProfile_trades } from '../../modules/ProfileProvider/__generated__/CompanyProfile';
import styles from './Trades.module.scss';

export const TradesErrorView = () => (
  <div className={styles.tradesContainer}>
    <div>
      <h4 className={styles.tradesHeader}>Trades</h4>
      <AlertIcon title="trades error icon" />
    </div>
  </div>
);

const extractTradesNamesAndIds = (
  acc: {
    ids: number[];
    names: string[];
  },
  trade: CompanyProfile_companyProfile_trades | null,
): {
  ids: number[];
  names: string[];
} => {
  const names = trade?.trade.name
    ? [...acc.names, trade.trade.name]
    : acc.names;
  const ids = trade?.trade.ascensionId
    ? [...acc.ids, trade.trade.ascensionId]
    : acc.ids;

  return {
    names,
    ids,
  };
};

export const TradesLoadingView = () => (
  <NewTagGroup header="Trades">
    <SkeletonLoader height="16px" count={2} />
  </NewTagGroup>
);

type TradesProps = {
  data: CompanyProfile_companyProfile_trades[];
};

export const Trades = ({ data }: TradesProps) => {
  const { isOwnAccount } = useOwnAccount();

  /**
   * Extracting trades names and ids array separately from trades array to pass them as props to child elements
   */
  const parsedTrades = data.reduce(extractTradesNamesAndIds, {
    ids: [],
    names: [],
  });

  const { names, ids: tradeIds } = parsedTrades;

  return (
    <div className={styles.tradesContainer}>
      <TagGroup header="Trades">
        {names.length > 0 ? (
          names.map((name) => (
            <TagGroup.Tag key={`trades-${name}`} text={name} />
          ))
        ) : (
          <NoContentPlaceholder />
        )}
      </TagGroup>
      {isOwnAccount && <EditTrades trades={tradeIds} />}
    </div>
  );
};
