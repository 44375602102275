import {
  MaxContractSizeOption,
  MinContractSizeOption,
} from './fields/ContractSize';

export enum EditContractSizeFieldName {
  MinContractSize = 'contractSizeMin',
  MaxContractSize = 'contractSizeMax',
  MinAndMaxContractSize = 'contractSizeMinAndMax',
}

export type EditContractSizeFieldValues = {
  [EditContractSizeFieldName.MinContractSize]: MinContractSizeOption;
  [EditContractSizeFieldName.MaxContractSize]: MaxContractSizeOption;
};
