import { gql } from '@apollo/client';

export const UPDATE_CONTRACT_AND_WORKFORCE_SIZE = gql`
  mutation UpdateContractAndWorkforceSize(
    $workforceSize: WorkforceSizeEnum
    $contractSizeMin: ProfileContractSizeMinEnum
    $contractSizeMax: ProfileContractSizeMaxEnum
  ) {
    updateCurrentWorkforceSize(workforceSize: $workforceSize)
    updateContractSize(
      contractSizeMin: $contractSizeMin
      contractSizeMax: $contractSizeMax
    ) {
      ... on Profile {
        id
        contractSizeMin
        contractSizeMax
      }
      ... on FieldErrors {
        errors {
          field
          message
        }
      }
    }
  }
`;
