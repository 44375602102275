import React from 'react';
import { Form } from 'react-final-form';
import { ModalFooter } from '../../../../modules/Modal/ModalFooter';
import { FormError } from '../../../FormError';
import { TradesMultiSelectField } from './fields/Trades';
import { useSubmitForm } from './hooks';
import { EditTradesFieldName, EditTradesFieldValues } from './types';
import { SubbieStockTradesQuery_stockTrades } from '../../__generated__/SubbieStockTradesQuery';

type EditTradesModalProps = {
  trades: number[] | null;
  tradesOptions: SubbieStockTradesQuery_stockTrades[];
  close: () => void;
};

export const EditTradesForm = ({
  trades,
  tradesOptions,
  close,
}: EditTradesModalProps) => {
  const { submit: submitForm, error } = useSubmitForm();

  const onSubmit = async (values: EditTradesFieldValues) => {
    const errors = await submitForm(values);
    if (errors === null) {
      close();
    }
  };

  return (
    <Form<EditTradesFieldValues>
      keepDirtyOnReinitialize
      onSubmit={onSubmit}
      initialValues={{
        [EditTradesFieldName.Trades]: trades
          ? tradesOptions.filter(({ value }) => trades.includes(value))
          : [],
      }}
    >
      {({ handleSubmit }) => (
        <form id="EDIT_CATEGORIES_FORM" onSubmit={handleSubmit}>
          {error && <FormError />}
          <TradesMultiSelectField tradesOptions={tradesOptions} />
          <ModalFooter close={close} />
        </form>
      )}
    </Form>
  );
};
