import React, { useReducer } from 'react';
import { E1Link, Modal, ModalSize } from '@estimateone/frontend-components';
import { EditCategoriesForm } from './EditCategoriesForm';
import { ProjectCategoryEnum } from '../../../__generated__/globalTypes';

const ALLOW_MODAL_OVERFLOW = {
  style: {
    content: {
      overflow: 'visible',
    },
  },
};

type CategoriesProps = {
  categories: ProjectCategoryEnum[];
};

export const EditCategories = ({ categories }: CategoriesProps) => {
  const [isOpen, toggle] = useReducer((curr) => !curr, false);

  return (
    <div id="EDIT-CATEGORIES-OVERRIDE">
      <Modal
        rootElement="#MFE_PROFILES_ROOT"
        isOpen={isOpen}
        onRequestClose={toggle}
        size={ModalSize.Small}
        reactModalProps={ALLOW_MODAL_OVERFLOW}
      >
        <Modal.Section>
          <EditCategoriesForm categories={categories} close={toggle} />
        </Modal.Section>
      </Modal>
      <E1Link onClick={toggle}>Edit</E1Link>
    </div>
  );
};
