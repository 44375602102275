import { useMutation } from '@apollo/client';
import { DELETE_HISTORICAL_PROJECT } from './mutations';
import {
  DeleteHistoricalProject,
  DeleteHistoricalProjectVariables,
} from './__generated__/DeleteHistoricalProject';

export const useDeleteHistoricalProject = (id: string) => {
  const [deleteHistoricalProject, { error }] = useMutation<
    DeleteHistoricalProject,
    DeleteHistoricalProjectVariables
  >(DELETE_HISTORICAL_PROJECT, {
    update: (cache) => {
      cache.evict({ id: `HistoricalProject:${id}` });
      cache.gc();
    },
  });

  const submit = () => {
    return deleteHistoricalProject({
      variables: {
        id,
      },
    });
  };

  return { submit, error };
};
