import React from 'react';
import { Button, ButtonVariant } from '@estimateone/frontend-components';
import styles from './ModalFooter.module.scss';

export const ModalFooter = ({
  close,
  hasValidationErrors,
}: {
  close: () => void;
  hasValidationErrors?: boolean;
}) => (
  <div className={styles.editModalButtonsWrapper}>
    <Button
      className={styles.cancelButton}
      variant={ButtonVariant.Grey}
      fullWidth
      onClick={close}
    >
      Cancel
    </Button>
    <Button
      variant={ButtonVariant.Primary}
      fullWidth
      type="submit"
      disabled={hasValidationErrors}
    >
      Save
    </Button>
  </div>
);
