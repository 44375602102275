import { ProjectCategoryEnum } from '../../__generated__/globalTypes';

export const getCategoryLabel = (
  category: ProjectCategoryEnum | string,
): string => {
  return category
    .replace(/_/g, ' ')
    .replace(
      /(^\w|\s\w)(\S*)/g,
      (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase(),
    );
};
