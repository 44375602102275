import { gql } from '@apollo/client';

export const CHANGE_COMPANY_ABN = gql`
  mutation ChangeBusinessIdentifier($businessIdentifier: String) {
    changeBusinessIdentifier(businessIdentifier: $businessIdentifier) {
      ... on Account {
        id
        businessIdentifier
      }
      ... on Errors {
        errors {
          message
          field
        }
      }
    }
  }
`;
