import React from 'react';
import { EditModal } from '../../../EditModal';
import { ERROR_TEXT } from '../../../FormError/constants';
import { useSubmitForm } from './hooks';
import {
  EditCompanyFieldLabels,
  EditCompanyFieldNames,
  EditCompanyFieldValues,
} from '../../../EditModal/types';

export type EditCompanyNameFormProps = {
  companyName: string | null | undefined;
  close: () => void;
};

const validate = (val?: string) => {
  return val !== undefined && val.length !== 0 && val.length < 254
    ? undefined
    : ['Invalid input'];
};

export const EditCompanyNameForm = ({
  companyName,
  close,
}: EditCompanyNameFormProps) => {
  const { submit: submitForm, error } = useSubmitForm();

  const onSubmit = async (value: EditCompanyFieldValues) => {
    const errors = await submitForm(value);

    if (errors === null) {
      close();
    }
  };

  return (
    <EditModal
      fieldName={EditCompanyFieldNames.CompanyName}
      fieldLabel={EditCompanyFieldLabels.CompanyName}
      fieldValue={companyName}
      toggle={close}
      onClick={onSubmit}
      error={error && ERROR_TEXT}
      validate={validate}
    />
  );
};
