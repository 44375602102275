import React from 'react';
import { Form } from 'react-final-form';
import { ModalFooter } from '../../../../modules/Modal/ModalFooter';
import { FormError } from '../../../FormError';
import {
  ContractSize,
  EMPTY_CONTRACT_SIZE,
} from '../../ContractSizeBadge/EditContractSize/fields/ContractSize';
import {
  ContractSizeMaxEnumMap,
  ContractSizeMinEnumMap,
} from '../../ContractSizeBadge/EditContractSize/values';
import {
  EMPTY_WORKFORCE,
  WorkforceSize,
} from '../../EmployeeBadge/EditWorkforceSize/fields/WorkforceSize';
import { extractFieldErrors } from '../../../../utils';
import { contractSizeLabelMap } from '../../ContractSizeBadge/utils';
import { getWorkforceSizeLabel } from '../../EmployeeBadge/utils';
import { useSubmitForm } from './hooks';
import {
  EditContractSizeFieldName,
  EditContractSizeFieldValues,
} from '../../ContractSizeBadge/EditContractSize/types';
import {
  EditWorkforceSizeFieldName,
  EditWorkforceSizeFieldValues,
} from '../../EmployeeBadge/EditWorkforceSize/fields/types';
import { EditContractAndWorkforceSizeFormErrors } from './types';
import {
  ContractSizeMaxEnum,
  ContractSizeMinEnum,
  WorkforceSizeEnum,
} from '../../../../__generated__/globalTypes';

export type EditContractAndWorkforceSizeFormProps = {
  workforceSize: WorkforceSizeEnum | null;
  contractSizeMin: ContractSizeMinEnum | null;
  contractSizeMax: ContractSizeMaxEnum | null;
  toggle: () => void;
};

export const EditContractAndWorkforceSizeForm = ({
  workforceSize,
  contractSizeMin,
  contractSizeMax,
  toggle,
}: EditContractAndWorkforceSizeFormProps) => {
  const { submit: submitForm, error } = useSubmitForm();

  const onSubmit = async (
    values: EditWorkforceSizeFieldValues & EditContractSizeFieldValues,
  ) => {
    const { errors, data } = await submitForm(values);

    if (errors) {
      return;
    }

    if (
      data?.updateContractSize &&
      data.updateContractSize.__typename === 'FieldErrors'
    ) {
      const errors = data.updateContractSize.errors;

      const fieldErrors = extractFieldErrors(errors, [
        EditContractSizeFieldName.MinContractSize,
        EditContractSizeFieldName.MaxContractSize,
        EditContractSizeFieldName.MinAndMaxContractSize,
      ]);

      return fieldErrors;
    } else {
      toggle();
    }
  };

  const initialValues = {
    [EditWorkforceSizeFieldName.WorkforceSizeName]: workforceSize
      ? {
          label: getWorkforceSizeLabel(workforceSize),
          value: workforceSize,
        }
      : EMPTY_WORKFORCE,
    [EditContractSizeFieldName.MinContractSize]: contractSizeMin
      ? {
          label: contractSizeLabelMap(contractSizeMin),
          value: contractSizeMin,
        }
      : EMPTY_CONTRACT_SIZE,
    [EditContractSizeFieldName.MaxContractSize]: contractSizeMax
      ? {
          label: contractSizeLabelMap(contractSizeMax),
          value: contractSizeMax,
        }
      : EMPTY_CONTRACT_SIZE,
  };

  const validate = (
    values: EditWorkforceSizeFieldValues & EditContractSizeFieldValues,
  ): EditContractAndWorkforceSizeFormErrors => {
    const errors: EditContractAndWorkforceSizeFormErrors = {};

    const contractMin = values[EditContractSizeFieldName.MinContractSize];
    const contractMax = values[EditContractSizeFieldName.MaxContractSize];

    if (
      contractMin.value &&
      contractMax.value &&
      ContractSizeMinEnumMap[contractMin.value] >=
        ContractSizeMaxEnumMap[contractMax.value]
    ) {
      errors[EditContractSizeFieldName.MinAndMaxContractSize] = [
        'Min Contract Size should be less than Max Contract Size',
      ];
    }

    return errors;
  };

  return (
    <Form<EditWorkforceSizeFieldValues & EditContractSizeFieldValues>
      keepDirtyOnReinitialize
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={(values): EditContractAndWorkforceSizeFormErrors =>
        validate(values)
      }
    >
      {({ handleSubmit, submitErrors, errors, hasValidationErrors }) => (
        <form
          id="EDIT_CONTRACT_AND_WORKFORCE_SIZE_FORM"
          onSubmit={handleSubmit}
        >
          {error && <FormError />}
          <ContractSize submitErrors={submitErrors} errors={errors} />
          <WorkforceSize />
          <ModalFooter
            close={toggle}
            hasValidationErrors={hasValidationErrors}
          />
        </form>
      )}
    </Form>
  );
};
