import { StateType } from 'types';

export enum EditOfficeDetailsFieldNames {
  AccountOfficeId = 'AccountOfficeId',
  Name = 'Name',
  StreetAddress = 'StreetAddress',
  Suburb = 'Suburb',
  State = 'State',
  Postcode = 'Postcode',
  OfficeEmail = 'OfficeEmail',
  PhoneNumber = 'PhoneNumber',
  Website = 'Website',
  City = 'City',
  Province = 'Province',
}

export enum EditOfficeDetailsFieldLabels {
  StreetAddress = 'Street address',
  Suburb = 'Suburb',
  State = 'State',
  Postcode = 'Postcode',
  OfficeEmail = 'Office email',
  PhoneNumber = 'Phone number',
  Website = 'Website',
  City = 'City',
  Province = 'Province',
}
export type AddOfficeDetailsFieldValues = {
  [EditOfficeDetailsFieldNames.StreetAddress]: string | null;
  [EditOfficeDetailsFieldNames.Suburb]: string | null;
  [EditOfficeDetailsFieldNames.City]: string | null;
  [EditOfficeDetailsFieldNames.Province]: string | null;
  [EditOfficeDetailsFieldNames.State]: StateType;
  [EditOfficeDetailsFieldNames.Postcode]: string | null;
  [EditOfficeDetailsFieldNames.OfficeEmail]: string | null;
  [EditOfficeDetailsFieldNames.PhoneNumber]: string | null;
  [EditOfficeDetailsFieldNames.Website]: string | null;
};

export type EditOfficeDetailsFieldValues = AddOfficeDetailsFieldValues & {
  [EditOfficeDetailsFieldNames.AccountOfficeId]: GraphQLEntityId;
  [EditOfficeDetailsFieldNames.Name]: string;
};

export type AddressDetailsFieldProps = {
  id: string;
  field: string;
  label: string;
  validate: () => undefined | Array<string>;
};

/**
 * These magic numbers are the state's EntityId in the database
 */
export enum State {
  STATE_ACT = 1,
  STATE_QLD = 2,
  STATE_NSW = 3,
  STATE_VIC = 4,
  STATE_TAS = 5,
  STATE_SA = 6,
  STATE_WA = 7,
  STATE_NT = 8,
  STATE_EXT_TERRITORIES = 12,
}

export enum ShortStateName {
  STATE_ACT = 'ACT',
  STATE_QLD = 'QLD',
  STATE_NSW = 'NSW',
  STATE_VIC = 'VIC',
  STATE_TAS = 'TAS',
  STATE_SA = 'SA',
  STATE_WA = 'WA',
  STATE_NT = 'NT',
  STATE_EXT_TERRITORIES = 'EXT TERRITORIES',
}

export const ShortStateNameMap = {
  [State.STATE_ACT]: ShortStateName.STATE_ACT,
  [State.STATE_QLD]: ShortStateName.STATE_QLD,
  [State.STATE_NSW]: ShortStateName.STATE_NSW,
  [State.STATE_VIC]: ShortStateName.STATE_VIC,
  [State.STATE_TAS]: ShortStateName.STATE_TAS,
  [State.STATE_SA]: ShortStateName.STATE_SA,
  [State.STATE_WA]: ShortStateName.STATE_WA,
  [State.STATE_NT]: ShortStateName.STATE_NT,
  [State.STATE_EXT_TERRITORIES]: ShortStateName.STATE_EXT_TERRITORIES,
};

export const stateOptions = Object.entries(ShortStateNameMap).map(
  ([id, label]) => ({
    value: id,
    label,
  }),
);
