export enum DirectoryOptInOptionEnum {
  SectionTitle = 'Subcontractor Directory visibility',
  OptionName = 'directoryOptInSetting',
  OptInValue = 'directoryOptIn',
  OptOutLabel = 'No one',
  OptOutValue = 'directoryOptOut',
  SaveButton = 'Save directory visibility setting',
  InfoCardHeading = 'How does this work?',
  InfoCardDescription1 = 'Your Company Profile can be found in a Subcontractor directory.',
  SuccessTitle = 'Success',
}
