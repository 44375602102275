import { useApolloClient, useMutation } from '@apollo/client';

import { useCompanyProfileCacheItemId } from '../../../../modules/ProfileProvider';
import { ADD_PROJECT_TO_HISTORY } from './mutations';
import {
  ProjectHistoryFieldNames,
  ProjectHistoryFieldValues,
} from 'components/ProjectHistory/ProjectHistoryForm/types';
import { StateEnum } from '../../../../__generated__/globalTypes';
import {
  AddProjectToHistory,
  AddProjectToHistoryVariables,
} from './__generated__/AddProjectToHistory';

/**
 * Assume that all the data is valid once it gets here
 */
const transformValues = (values: ProjectHistoryFieldValues) => {
  const state =
    values[ProjectHistoryFieldNames.State] === null
      ? StateEnum.Other
      : values[ProjectHistoryFieldNames.State].value;

  return {
    input: {
      name: values[ProjectHistoryFieldNames.Name] || '',
      builderName: values[ProjectHistoryFieldNames.BuilderName] || '',
      state,
      region: values[ProjectHistoryFieldNames.Region],
      category: values[ProjectHistoryFieldNames.Category]?.value || undefined,
      completionDate:
        values[ProjectHistoryFieldNames.CompletionDate].toISOString(),
      contractSize: values[ProjectHistoryFieldNames.ContractSize].value,
      linkedProjectId: values[ProjectHistoryFieldNames.LinkedProjectId],
      description: values[ProjectHistoryFieldNames.Description],
    },
  };
};

export const useAddProjectToProjectHistory = () => {
  const { cache } = useApolloClient();
  const id = useCompanyProfileCacheItemId();

  const [addProjectHistoryName, { error, reset }] = useMutation<
    AddProjectToHistory,
    AddProjectToHistoryVariables
  >(ADD_PROJECT_TO_HISTORY, {
    onCompleted: (data) => {
      if (data.addProjectToHistory.__typename === 'HistoricalProject') {
        cache.modify({
          id,
          fields: {
            projectHistory: (existing = []) => {
              return [...existing, data.addProjectToHistory];
            },
          },
        });
      }
    },
  });

  const submit = async (values: ProjectHistoryFieldValues) => {
    const { data, errors } = await addProjectHistoryName({
      variables: transformValues(values),
    });

    return {
      data,
      errors,
    };
  };

  return { submit, error: error ?? undefined, reset };
};
