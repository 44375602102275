import { useState } from 'react';
import { usePresignedLogoUploadUrl } from './presigned-logo-upload-url.hook';

const putObject = async (url: string, data: ArrayBuffer) => {
  try {
    const putObjectResponse = await fetch(url, {
      method: 'PUT',
      body: data,
      headers: new Headers({
        'Content-Length': new Blob([data]).size.toString(),
      }),
    });

    return putObjectResponse.status === 200
      ? { success: true }
      : { error: LogoUploadErrorType.UPLOAD_FAILED };
  } catch {
    return { error: LogoUploadErrorType.UPLOAD_FAILED };
  }
};

export enum LogoUploadStatus {
  READY,
  UPLOADING,
  COMPLETE,
  FAILED,
}

export enum LogoUploadErrorType {
  FETCH_PRESIGNED_URL_FAILED,
  UPLOAD_FAILED,
}

export const useLogoUploader = () => {
  const [uploadStatus, setUploadStatus] = useState(LogoUploadStatus.READY);
  const [uploadErrorType, setUploadErrorType] = useState<
    LogoUploadErrorType | undefined
  >();
  const getUrl = usePresignedLogoUploadUrl();

  return {
    uploadStatus,
    uploadErrorType,
    uploadLogo: async (logoData: ArrayBuffer) => {
      try {
        setUploadStatus(LogoUploadStatus.UPLOADING);

        const { success, url: presignedLogoUploadUrl } = await getUrl();

        if (!success) {
          setUploadStatus(LogoUploadStatus.FAILED);
          setUploadErrorType(LogoUploadErrorType.FETCH_PRESIGNED_URL_FAILED);
          return;
        }

        const { error: putObjectError } = await putObject(
          presignedLogoUploadUrl,
          logoData,
        );

        if (putObjectError) {
          setUploadStatus(LogoUploadStatus.FAILED);
          setUploadErrorType(putObjectError);
          return;
        }

        setUploadStatus(LogoUploadStatus.COMPLETE);
      } catch (error) {
        setUploadStatus(LogoUploadStatus.FAILED);
      }
    },
  };
};
