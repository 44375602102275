import React from 'react';
import { ChevronLeftIcon } from '@estimateone/frontend-components';
import styles from './BackButton.module.scss';

export const BackButton = () => (
  <a className={styles.alignItems} href="/find-tenders">
    <ChevronLeftIcon
      className={styles.backButton}
      viewBox="0 0 5 8"
      height="8px"
      width="8px"
    />
    <span className={styles.text}>Tenders</span>
  </a>
);
