import { gql } from '@apollo/client';

export const CHANGE_PRIMARY_CONTACT = gql`
  mutation ChangePrimaryContact($userId: EntityId!) {
    changePrimaryContact(userId: $userId) {
      id
      preferredContact {
        id
        fullName
        firstName
        lastName
        email
        phone
      }
    }
  }
`;
