import { gql } from '@apollo/client';

export const GET_PRIMARY_COUNTRY = gql`
  query GetCurrentAccountCountry {
    currentAccount {
      primaryCountry {
        id
      }
    }
  }
`;
