const en_AU = {
  language: 'en_AU',
  messages: {
    location: 'au',
    currencySymbol: '$',
    businessIdentifier: 'ABN',
    missingBusinessIdentifier: 'No ABN',
    builderLabel: 'Builder',
    businessIdentifierTooltipText:
      'Adding an ABN ensures Builders interacting with you on EstimateOne recognise you operate as a registered business. Only Builders can see this information on EstimateOne.',
    directoryOptInLabel: 'Builder accounts on EstimateOne',
    directoryVisibilityDescription:
      'Only Builder accounts on EstimateOne can see your profile.',
    directoryOptInSuccessMessage:
      'Your company profile is available for Builders to search in the Subcontractor Directory.',
    directoryOptOutSuccessMessage:
      'Your company profile is not available for Builders to search in the Subcontractor Directory.',
  },
};

const en_IE = {
  language: 'en_IE',
  messages: {
    location: 'ie',
    currencySymbol: '€',
    businessIdentifier: 'CRN',
    missingBusinessIdentifier: 'No CRN',
    builderLabel: 'Main Contractor',
    businessIdentifierTooltipText:
      'Adding a CRN ensures Main Contractors interacting with you on EstimateOne recognise you operate as a registered business. Only Main Contractors can see this information on EstimateOne.',
    directoryOptInLabel: 'Main Contractor accounts on EstimateOne',
    directoryVisibilityDescription:
      'Only Main Contractor accounts on EstimateOne can see your profile.',
    directoryOptInSuccessMessage:
      'Your company profile is available for Main Contractors to search in the Subcontractor Directory.',
    directoryOptOutSuccessMessage:
      'Your company profile is not available for Main Contractors to search in the Subcontractor Directory.',
  },
};

const en_GB = {
  language: 'en_GB',
  messages: {
    location: 'gb',
    currencySymbol: '£',
    businessIdentifier: 'CRN',
    missingBusinessIdentifier: 'No CRN',
    businessIdentifierTooltipText:
      'Adding a CRN ensures Main Contractors interacting with you on EstimateOne recognise you operate as a registered business. Only Main Contractors can see this information on EstimateOne.',
    builderLabel: 'Main Contractor',
    directoryOptInLabel: 'Main Contractor accounts on EstimateOne',
    directoryVisibilityDescription:
      'Only Main Contractor accounts on EstimateOne can see your profile.',
    directoryOptInSuccessMessage:
      'Your company profile is available for Main Contractors to search in the Subcontractor Directory.',
    directoryOptOutSuccessMessage:
      'Your company profile is not available for Main Contractors to search in the Subcontractor Directory.',
  },
};

export const localTranslations = [en_IE, en_AU, en_GB];
