import { gql } from '@apollo/client';

export const GET_PROFILE = gql`
  query SubbieProfileQuery($accountId: EntityId!) {
    subbieProfile(id: $accountId) {
      id
      defaultOffice {
        id
      }
      accountOffices {
        id
        name
        phone
        email
        website
        address {
          id
          address1
          address2
          address3
          fullAddress
          shortAddress
          suburb
          city
          province
          postcode
          state {
            id
            shortName
          }
          country {
            id
            shortName
          }
        }
      }
    }
  }
`;
