import React from 'react';
import { MultiSelectDropdownField } from '../../../../../modules/FinalFormFields/MultiSelectDropdownField/MultiSelectDropdownField';
import { EditTradesFieldName } from '../types';
import { SubbieStockTradesQuery_stockTrades } from '../../../__generated__/SubbieStockTradesQuery';

type TradesMultiSelectFieldProps = {
  tradesOptions: SubbieStockTradesQuery_stockTrades[];
};

export type TradeOption = {
  value: number;
  label: string;
};

export const TradesMultiSelectField = ({
  tradesOptions,
}: TradesMultiSelectFieldProps) => {
  return (
    <MultiSelectDropdownField
      id="trades"
      label="Trades"
      fieldName={EditTradesFieldName.Trades}
      options={tradesOptions}
    />
  );
};
