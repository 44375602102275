import React from 'react';
import { ShowMoreOrLessContent } from '../../ShowMoreOrLessContent';
import { MIN_CONTENT_SIZE } from '../constants';
import { EmptyAboutUsContent } from './EmptyAboutUsContent/EmptyAboutUsContent';

type AboutUsContentProps = {
  content: string | null;
};
export const AboutUsContent = ({ content }: AboutUsContentProps) => {
  if (!content) {
    return <EmptyAboutUsContent />;
  }
  return (
    <ShowMoreOrLessContent
      content={content}
      minContentSize={MIN_CONTENT_SIZE}
    />
  );
};
