export enum EditPrimaryContactFieldName {
  PrimaryContact = 'PrimaryContact',
}

export type EditPrimaryContactFieldValues = {
  [EditPrimaryContactFieldName.PrimaryContact]: PrimaryContactOption;
};

export type PrimaryContactOption = {
  value: number;
  label: string;
};
