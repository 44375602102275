import { useApolloClient, useMutation } from '@apollo/client';
import { useCompanyProfileCacheItemId } from '../../../../modules/ProfileProvider';
import { UPDATE_CONTRACT_AND_WORKFORCE_SIZE } from './mutations';
import { EditContractSizeFieldValues } from '../../ContractSizeBadge/EditContractSize/types';
import { EditWorkforceSizeFieldValues } from '../../EmployeeBadge/EditWorkforceSize/fields/types';
import {
  UpdateContractAndWorkforceSize,
  UpdateContractAndWorkforceSizeVariables,
} from './__generated__/UpdateContractAndWorkforceSize';

const transformValues = ({
  WorkforceSizeName,
  contractSizeMin,
  contractSizeMax,
}: EditWorkforceSizeFieldValues &
  EditContractSizeFieldValues): UpdateContractAndWorkforceSizeVariables => ({
  workforceSize: WorkforceSizeName ? WorkforceSizeName.value : null,
  contractSizeMin: contractSizeMin.value,
  contractSizeMax: contractSizeMax.value,
});

export const useSubmitForm = () => {
  const { cache } = useApolloClient();
  const id = useCompanyProfileCacheItemId();

  const [updateContractAndWorkforceSize, { error }] = useMutation<
    UpdateContractAndWorkforceSize,
    UpdateContractAndWorkforceSizeVariables
  >(UPDATE_CONTRACT_AND_WORKFORCE_SIZE, {
    onCompleted: (data) => {
      cache.modify({
        id,
        fields: {
          workforceSize: () => data.updateCurrentWorkforceSize,
          contractSizeMin: (existing) =>
            data.updateContractSize?.__typename === 'Profile'
              ? data.updateContractSize.contractSizeMin
              : existing,
          contractSizeMax: (existing) =>
            data.updateContractSize?.__typename === 'Profile'
              ? data.updateContractSize.contractSizeMax
              : existing,
        },
      });
    },
  });

  const submit = async (
    values: EditWorkforceSizeFieldValues & EditContractSizeFieldValues,
  ) => {
    const { errors, data } = await updateContractAndWorkforceSize({
      variables: transformValues(values),
    });
    return { data, errors };
  };

  return { submit, error };
};
