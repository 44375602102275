import React, { useReducer } from 'react';
import { DeleteIcon } from '@estimateone/frontend-components';
import { UnstyledButton } from 'components/UnstyledButton';
import { CompanyLogo } from '../CompanyLogo';
import { DeleteCompanyLogoConfirmation } from './DeleteCompanyLogoConfirmation';
import styles from './DeleteCompanyLogo.module.scss';

export const DeleteCompanyLogo = () => {
  const [isDeleteLogoModalOpen, toggleDeleteLogoModal] = useReducer(
    (curr: boolean) => !curr,
    false,
  );

  return (
    <>
      <UnstyledButton
        onClick={toggleDeleteLogoModal}
        className={styles.deleteLogoButton}
      >
        <CompanyLogo />
        <div className={styles.deleteCompanyLogoContainer}>
          <DeleteIcon className={styles.deleteIcon} />
          <p className={styles.text}>Remove</p>
        </div>
      </UnstyledButton>
      <DeleteCompanyLogoConfirmation
        isOpen={isDeleteLogoModalOpen}
        toggleModal={toggleDeleteLogoModal}
      />
    </>
  );
};
