import React from 'react';
import { SingleSelectDropdownField } from '../../../modules/FinalFormFields/SingleSelectDropdownField/SingleSelectDropdownField';
import { useTranslator } from '../../../modules/TranslationsProvider';
import { getContractSizeOptions } from './helpers';
import {
  ProjectHistoryFieldLabels,
  ProjectHistoryFieldNames,
} from '../ProjectHistoryForm/types';

export const ContractSizeSelect = () => {
  const translate = useTranslator();
  const contractSizeOptions = getContractSizeOptions(translate);
  return (
    <div>
      <SingleSelectDropdownField
        id={ProjectHistoryFieldNames.ContractSize}
        label={ProjectHistoryFieldLabels.ContractSize}
        placeholder={ProjectHistoryFieldLabels.ContractSize}
        isRequired={true}
        fieldName={ProjectHistoryFieldNames.ContractSize}
        options={contractSizeOptions}
      />
    </div>
  );
};
