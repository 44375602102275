import { ApolloError } from '@apollo/client';
import { ContractSizeEnum } from '../ContractSizeSelect';
import { SearchE1Projects_searchE1Projects_projects_address_state } from './FormFields/SearchableProjectName/__generated__/SearchE1Projects';
import {
  ProjectCategoryEnum,
  ProjectStatus,
  StateEnum,
} from '__generated__/globalTypes';

export type LinkedProjectOption = {
  value: string;
  label: string;
  id: number | null;
  status: ProjectStatus | null;
  state: SearchE1Projects_searchE1Projects_projects_address_state | null;
  // region: string | null;
  category: string | null;
};

export enum ProjectHistoryFieldNames {
  Name = 'name',
  BuilderName = 'builderName',
  State = 'state',
  Region = 'region',
  CompletionDate = 'completionDate',
  ContractSize = 'contractSize',
  Category = 'category',
  LinkedProjectId = 'linkedProjectId',
  Description = 'description',
}

export enum ProjectHistoryFieldLabels {
  Name = 'Project name',
  BuilderName = 'Builder/Client name',
  State = 'State',
  Region = 'region',
  CompletionDate = 'Completion Date',
  Category = 'Category',
  ContractSize = 'Contract value',
  Description = 'Description',
}

export type StateOption = {
  value: StateEnum;
  label: string;
};

export type CategoryOption = {
  value: ProjectCategoryEnum;
  label: string;
};

export type ContractSizeOption = {
  value: ContractSizeEnum;
  label: string;
};

export type ProjectHistoryFieldValues = {
  [ProjectHistoryFieldNames.Name]: string;
  [ProjectHistoryFieldNames.BuilderName]: string;
  [ProjectHistoryFieldNames.State]: StateOption;
  [ProjectHistoryFieldNames.Region]: string | null;
  [ProjectHistoryFieldNames.CompletionDate]: Date;
  [ProjectHistoryFieldNames.Category]: CategoryOption;
  [ProjectHistoryFieldNames.ContractSize]: ContractSizeOption;
  [ProjectHistoryFieldNames.LinkedProjectId]: number | null;
  [ProjectHistoryFieldNames.Description]: string | null;
};

export type ProjectHistoryFormErrors = {
  [ProjectHistoryFieldNames.Name]?: string[];
  [ProjectHistoryFieldNames.BuilderName]?: string[];
  [ProjectHistoryFieldNames.State]?: string[];
  [ProjectHistoryFieldNames.Region]?: string[];
  [ProjectHistoryFieldNames.CompletionDate]?: string[];
  [ProjectHistoryFieldNames.ContractSize]?: string[];
  [ProjectHistoryFieldNames.Category]?: string[];
  [ProjectHistoryFieldNames.Description]?: string[];
};

export type DefaultProjectHistoryFieldValues = {
  id: string;
  [ProjectHistoryFieldNames.Name]: string;
  [ProjectHistoryFieldNames.BuilderName]: string;
  [ProjectHistoryFieldNames.State]: StateOption | undefined;
  [ProjectHistoryFieldNames.Region]: string | null;
  [ProjectHistoryFieldNames.CompletionDate]: Date | undefined;
  [ProjectHistoryFieldNames.Category]: CategoryOption | undefined;
  [ProjectHistoryFieldNames.ContractSize]: ContractSizeOption | undefined;
  [ProjectHistoryFieldNames.LinkedProjectId]: number | null;
  [ProjectHistoryFieldNames.Description]: string | null;
};

export type ProjectHistoryFormProps = {
  onSubmit: (values: ProjectHistoryFieldValues) => Promise<unknown>;
  initialValues?: DefaultProjectHistoryFieldValues;
  onAbort: () => void;
  canSearchProjects?: boolean;
  error?: ApolloError;
};
