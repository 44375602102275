import React, { useReducer } from 'react';
import {
  E1Link,
  SkeletonLoader,
  Tooltip,
} from '@estimateone/frontend-components';
import { ReactComponent as ErrorIconComponent } from '../../../assets/svgs/error.svg';
import { ReactComponent as InfoIconComponent } from '../../../assets/svgs/info.svg';
import { useTranslator } from '../../../modules/TranslationsProvider';
import { ProfileBadgeCard } from '../../ProfileBadgeCard';
import { ProfileBadgeIconWrapper } from '../../ProfileBadgeIconWrapper';
import { EditABNBadge } from './EditABNBadge';
import { useOwnAccount } from 'hooks';
import styles from './ABNBadge.module.scss';

const ErrorIcon = () => (
  <ProfileBadgeIconWrapper status="error" content={<ErrorIconComponent />} />
);
const InfoIcon = () => (
  <ProfileBadgeIconWrapper status="info" content={<InfoIconComponent />} />
);

type EditABNBadgeProps = {
  icon: React.ReactNode;
  heading: string;
  subHeading?: string;
};

const EditABNBadgeView = ({ heading, subHeading, icon }: EditABNBadgeProps) => {
  const translate = useTranslator();
  const [isOpen, toggle] = useReducer((curr) => !curr, false);

  return (
    <Tooltip
      text={translate('businessIdentifierTooltipText')}
      tooltipId="abn-badge-tooltip"
      place="top"
    >
      <>
        <div
          className={`${styles.container} ${styles.hoverEffect}`}
          onClick={toggle}
        >
          <ProfileBadgeCard
            heading={heading}
            subHeading={subHeading}
            icon={icon}
          />
          <div className={styles.editLink}>
            <E1Link>Edit</E1Link>
          </div>
        </div>
        <EditABNBadge
          fieldValue={subHeading || ''}
          toggle={toggle}
          isOpen={isOpen}
        />
      </>
    </Tooltip>
  );
};

export const ABNBadgeLoading = () => (
  <div className={styles.loadingState}>
    <SkeletonLoader height="16px" />
  </div>
);

export const ABNBadgeError = () => {
  const translate = useTranslator();

  return (
    <div className={styles.container}>
      <ProfileBadgeCard
        heading={translate('missingBusinessIdentifier')}
        icon={<ErrorIcon />}
      />
    </div>
  );
};

type AbnBadgeProps = {
  businessIdentifier: string | null;
};

export const ABNBadge = ({ businessIdentifier }: AbnBadgeProps) => {
  const translate = useTranslator();
  const { isOwnAccount } = useOwnAccount();

  const { heading, subHeading, icon } = businessIdentifier
    ? {
        heading: translate('businessIdentifier'),
        subHeading: businessIdentifier,
        icon: <InfoIcon />,
      }
    : {
        heading: translate('missingBusinessIdentifier'),
        subHeading: undefined,
        icon: <ErrorIcon />,
      };

  return isOwnAccount ? (
    <EditABNBadgeView heading={heading} subHeading={subHeading} icon={icon} />
  ) : (
    <div className={styles.container}>
      <ProfileBadgeCard heading={heading} subHeading={subHeading} icon={icon} />
    </div>
  );
};
