import { useContext } from 'react';
import { ViewerContext } from '../../modules/ViewerProvider/ViewerProvider';

type UseAdminPayload = { isAdmin: null | boolean; loading: boolean };

export const useAdmin = (): UseAdminPayload => {
  const { loading, context } = useContext(ViewerContext);

  const isAdmin = context === null ? false : context?.isAdmin();

  return { isAdmin, loading };
};
