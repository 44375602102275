import React from 'react';
import { Modal, ModalSize } from '@estimateone/frontend-components';
import { AddOfficeDetailsForm } from '../AddOfficeDetailsForm';

type AddOfficeDetailsProps = {
  isOpen: boolean;
  toggle: () => void;
};

export const AddOfficeDetailsModal = ({
  isOpen,
  toggle,
}: AddOfficeDetailsProps) => {
  return (
    <div id="ADD-OFFICE-DETAILS-OVERRIDE">
      <Modal
        rootElement="#MFE_PROFILES_ROOT"
        isOpen={isOpen}
        onRequestClose={toggle}
        size={ModalSize.Small}
      >
        <Modal.Section>
          <AddOfficeDetailsForm close={toggle} />
        </Modal.Section>
      </Modal>
    </div>
  );
};
