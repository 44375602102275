type FieldError = {
  field: string;
  message: string;
};

export type FormFieldErrors = { [key: string]: string[] };

export const extractFieldErrors = (
  fieldErrors: FieldError[],
  fieldNames: string[],
) => {
  return fieldErrors.reduce((errors: FormFieldErrors, fieldError) => {
    if (!fieldNames.includes(fieldError.field)) {
      return errors;
    }

    const keys = Object.keys(errors);
    if (keys.includes(fieldError.field)) {
      errors[fieldError.field].push(fieldError.message);
    } else {
      errors[fieldError.field] = [fieldError.message];
    }
    return errors;
  }, {});
};
