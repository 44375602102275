import { useMutation, useApolloClient, gql } from '@apollo/client';
import { useCompanyProfileCacheItemId } from '../../../../../modules/ProfileProvider';
import { CHANGE_COMPANY_ABN } from './mutations';
import { EditCompanyFieldValues } from '../../../../EditModal/types';
import { CompanyProfile_companyProfile } from '../../../../../modules/ProfileProvider/__generated__/CompanyProfile';
import {
  ChangeBusinessIdentifier,
  ChangeBusinessIdentifierVariables,
} from './__generated__/ChangeBusinessIdentifier';

export const useSubmitForm = () => {
  const { cache } = useApolloClient();
  const id = useCompanyProfileCacheItemId();

  const [handleChangeCompanyABN, { error }] = useMutation<
    ChangeBusinessIdentifier,
    ChangeBusinessIdentifierVariables
  >(CHANGE_COMPANY_ABN, {
    onCompleted: (data) => {
      if (data.changeBusinessIdentifier.__typename === 'Account') {
        cache.writeFragment<Partial<CompanyProfile_companyProfile>>({
          id,
          fragment: gql`
            fragment UpdatedCompanyName on Profile {
              companyNumber
            }
          `,
          data: {
            companyNumber: data.changeBusinessIdentifier.businessIdentifier,
          },
        });
      }
    },
  });

  const submit = async ({ businessIdentifier }: EditCompanyFieldValues) => {
    const { data, errors } = await handleChangeCompanyABN({
      variables: {
        businessIdentifier: businessIdentifier
          ? businessIdentifier.replaceAll(' ', '')
          : null,
      },
    });

    return {
      data: data,
      errors: errors,
    };
  };

  return { submit, error: error ?? undefined };
};
