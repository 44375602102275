import React from 'react';
import { useField } from 'react-final-form';
import { FieldWrapper, TextArea } from '@estimateone/frontend-components';

type TextAreaWrapperProps<T extends string> = {
  field: string;
  id: string;
  label: string;
  placeholder?: string;
  initialValue?: T;
  validator?: (value: T) => string[] | undefined;
};

export function TextAreaWrapper<T extends string>({
  field,
  id,
  label,
  placeholder,
  initialValue,
  validator,
}: TextAreaWrapperProps<T>) {
  const {
    input,
    meta: { error, submitError },
  } = useField(field, {
    initialValue,
    validate: validator ?? undefined,
  });

  /* eslint-disable */
  return (
    <FieldWrapper errors={error || submitError} hideErrorMessages={false}>
      <TextArea id={id} label={label} placeholder={placeholder} {...input} />
    </FieldWrapper>
  );
  /* eslint-enable */
}
