import React from 'react';
import {
  Button,
  ButtonSize,
  ButtonVariant,
  Card,
  CardVariant,
} from '@estimateone/frontend-components';
import { EditAboutUs } from 'components/AboutUs/EditAboutUs';
import { useOwnAccount } from 'hooks';
import styles from './EmptyAboutUsContent.module.scss';

export const EMPTY_ABOUTUS_CONTENT_FOR_OTHER_ACCOUNT = `This Subcontractor hasn’t added an About Us`;

const EmptyAboutUsContentForOwnAccount = () => {
  return (
    <div className={styles.aboutUsEmptyContentForOwnAccount}>
      <Card variant={CardVariant.Red}>
        <Card.Body>
          <h3 className={styles.heading}>
            Introduce your business to builders
          </h3>
          <p className={styles.content}>
            Add a brief description about your business and which services you
            provide so that builders looking at your Company Profile will know
            what you are capable of.
          </p>
          <p className={styles.subHeading}>
            <strong>It is recommended that you include</strong>
          </p>
          <ul className={styles.lists}>
            <li>How long your business has been operating</li>
            <li>Which services you offer</li>
            <li>Which regions you cover</li>
            <li>
              Anything else that will help you stand out from your competition
            </li>
          </ul>
          <EditAboutUs
            triggerElement={
              <Button variant={ButtonVariant.Secondary} size={ButtonSize.Small}>
                Add about us
              </Button>
            }
            fieldValue=""
          />
        </Card.Body>
      </Card>
    </div>
  );
};

const EmptyAboutUsContentForOtherAccount = () => {
  return (
    <p className={styles.aboutUsEmptyContent}>
      {EMPTY_ABOUTUS_CONTENT_FOR_OTHER_ACCOUNT}
    </p>
  );
};

export const EmptyAboutUsContent = () => {
  const { isOwnAccount } = useOwnAccount();

  return isOwnAccount ? (
    <EmptyAboutUsContentForOwnAccount />
  ) : (
    <EmptyAboutUsContentForOtherAccount />
  );
};
