import React from 'react';
import { Form } from 'react-final-form';
import { FormError } from 'components/FormError';
import { SingleSelectDropdownField } from '../../../../modules/FinalFormFields/SingleSelectDropdownField/SingleSelectDropdownField';
import { ModalFooter } from '../../../../modules/Modal/ModalFooter';
import { useSubmitForm } from './hooks';
import {
  EditPrimaryContactFieldName,
  EditPrimaryContactFieldValues,
  PrimaryContactOption,
} from './types';
import { CompanyProfile_companyProfile_people } from '../../../../modules/ProfileProvider/__generated__/CompanyProfile';

type EditPrimaryContactModalProps = {
  primaryContact: CompanyProfile_companyProfile_people | null;
  contacts: CompanyProfile_companyProfile_people[];
  close: () => void;
};

export const EditPrimaryContactForm = ({
  primaryContact,
  contacts,
  close,
}: EditPrimaryContactModalProps) => {
  const { submit: submitForm, error } = useSubmitForm();

  const onSubmit = async (value: EditPrimaryContactFieldValues) => {
    const errors = await submitForm(value);

    if (errors === null) {
      close();
    }
  };

  const options = contacts.map(({ id, ascensionId, fullName }) => ({
    value: ascensionId ?? +id,
    label: fullName ?? '',
  }));

  return (
    <Form<EditPrimaryContactFieldValues>
      onSubmit={onSubmit}
      initialValues={{
        [EditPrimaryContactFieldName.PrimaryContact]: primaryContact
          ? {
              value: primaryContact.ascensionId ?? +primaryContact.id, // an edit action needs an Account, and therefore an Ascension ID
              label: primaryContact.fullName ?? '',
            }
          : undefined,
      }}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          {error && <FormError />}
          <SingleSelectDropdownField<PrimaryContactOption>
            id="primary-contact"
            label="Primary Contact"
            fieldName={EditPrimaryContactFieldName.PrimaryContact}
            options={options}
          />
          <ModalFooter close={close} />
        </form>
      )}
    </Form>
  );
};
