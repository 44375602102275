import React from 'react';
import {
  Button,
  ButtonSize,
  Card,
  CardVariant,
} from '@estimateone/frontend-components';
import { AddHistoricalProject } from 'components/ProjectHistory/ProjectHistoryActions';
import { useOwnAccount } from 'hooks';
import styles from './EmptyProjectHistoryContent.module.scss';

export const EMPTY_PROJECT_HISTORY_CONTENT_FOR_OTHER_ACCOUNT = `This subcontractor has no projects to view.`;

const EmptyProjectContentForOwnAccount = () => {
  return (
    <div className={styles.projectHistoryEmptyContentForOwnAccount}>
      <Card variant={CardVariant.Green}>
        <Card.Body>
          <h3 className={styles.heading}>
            Showcase your best work and win more jobs
          </h3>
          <p className={styles.content}>Adding a project is quick and easy.</p>
          <AddHistoricalProject
            triggerElement={
              <Button size={ButtonSize.Small}>Add Project</Button>
            }
          />
        </Card.Body>
      </Card>
    </div>
  );
};

const EmptyProjectContentForOtherAccount = () => {
  return (
    <p className={styles.projectHistoryEmptyContent}>
      {EMPTY_PROJECT_HISTORY_CONTENT_FOR_OTHER_ACCOUNT}
    </p>
  );
};

export const EmptyProjectHistoryContent = () => {
  const { isOwnAccount } = useOwnAccount();

  return isOwnAccount ? (
    <EmptyProjectContentForOwnAccount />
  ) : (
    <EmptyProjectContentForOtherAccount />
  );
};
