import React from 'react';
import { Form } from 'react-final-form';
import { DatePickerFormat } from '@estimateone/frontend-components';
import { categoriesOptions } from 'components/Categories/EditCategories/EditCategoriesForm/fields/Categories';
import { FormError } from 'components/FormError';
import { DatePickerField } from 'modules/FinalFormFields/DatePickerField';
import { InputFieldWrapper } from 'modules/FinalFormFields/InputFieldWrapper';
import { SingleSelectDropdownField } from 'modules/FinalFormFields/SingleSelectDropdownField/SingleSelectDropdownField';
// import { TextAreaWrapper } from 'modules/FinalFormFields/TextAreaWrapper';
import { ModalFooter } from 'modules/Modal/ModalFooter';
import { useTranslator } from 'modules/TranslationsProvider';
import { ContractSizeSelect } from '../ContractSizeSelect';
import { ProjectName, SearchableProjectName } from './FormFields';
import {
  ProjectHistoryFieldValues,
  ProjectHistoryFormErrors,
  ProjectHistoryFieldNames,
  ProjectHistoryFieldLabels,
  DefaultProjectHistoryFieldValues,
  ProjectHistoryFormProps,
} from './types';
import { StateEnum } from '__generated__/globalTypes';
import styles from './ProjectHistoryForm.module.scss';

export const PROJECT_DESCRIPTION_CONTENT_LIMIT = 2000;

export const validate = (
  values: ProjectHistoryFieldValues,
): ProjectHistoryFormErrors => {
  const errors: ProjectHistoryFormErrors = {};
  const name = values[ProjectHistoryFieldNames.Name]?.trim();

  const builderNameInput = values[ProjectHistoryFieldNames.BuilderName];
  const builderName = builderNameInput?.trim();

  const state = values[ProjectHistoryFieldNames.State];
  const region = values[ProjectHistoryFieldNames.Region];

  const category = values[ProjectHistoryFieldNames.Category];

  const completionDate = values[ProjectHistoryFieldNames.CompletionDate];

  const contractSize = values[ProjectHistoryFieldNames.ContractSize];

  const description = values[ProjectHistoryFieldNames.Description]?.trim();

  if (!name) {
    errors[ProjectHistoryFieldNames.Name] = ["Shouldn't be empty"];
  }

  if (!builderName) {
    errors[ProjectHistoryFieldNames.BuilderName] = ["Shouldn't be empty"];
  }

  if (!state && !region) {
    errors[ProjectHistoryFieldNames.State] = ["Shouldn't be empty"];
    errors[ProjectHistoryFieldNames.Region] = ["Shouldn't be empty"];
  }

  if (!category) {
    errors[ProjectHistoryFieldNames.Category] = ["Shouldn't be empty"];
  }

  if (!completionDate) {
    errors[ProjectHistoryFieldNames.CompletionDate] = ["Shouldn't be empty"];
  }

  if (!contractSize) {
    errors[ProjectHistoryFieldNames.ContractSize] = ["Shouldn't be empty"];
  }

  if (description && description.length > PROJECT_DESCRIPTION_CONTENT_LIMIT) {
    errors[ProjectHistoryFieldNames.Description] = [
      `The length of description content should not exceed ${PROJECT_DESCRIPTION_CONTENT_LIMIT} characters`,
    ];
  }

  return errors;
};

const States = {
  ACT: 'ACT',
  NSW: 'NSW',
  NT: 'NT',
  QLD: 'QLD',
  SA: 'SA',
  TAS: 'TAS',
  VIC: 'VIC',
  WA: 'WA',
};

const stateOptions = Object.entries(States).map(([value, label]) => ({
  value,
  label,
}));

const State = () => (
  // Need this extra div wrapper to align field errors properly
  <div>
    <SingleSelectDropdownField
      id={ProjectHistoryFieldNames.State}
      label={ProjectHistoryFieldLabels.State}
      placeholder={ProjectHistoryFieldLabels.State}
      isRequired={true}
      fieldName={ProjectHistoryFieldNames.State}
      options={stateOptions}
    />
  </div>
);

const Category = () => (
  <SingleSelectDropdownField
    id={ProjectHistoryFieldNames.Category}
    label="Category"
    placeholder="Category"
    isRequired={true}
    fieldName={ProjectHistoryFieldNames.Category}
    options={categoriesOptions}
  />
);

const BuilderNameUkIe = () => (
  <div>
    <InputFieldWrapper<string>
      field="builderName"
      id="builderName"
      label="Main contractor/Client name"
      placeholder="Main contractor/Client name"
      isRequired={true}
    />
  </div>
);

const BuilderName = () => (
  <div>
    <InputFieldWrapper<string>
      field="builderName"
      id="builderName"
      label="Builder/Client name"
      placeholder="Builder/Client name"
      isRequired={true}
    />
  </div>
);

const Region = () => (
  <div>
    <InputFieldWrapper<string>
      field="region"
      id="region"
      label="Region"
      placeholder="Region / Province"
      isRequired={true}
    />
    <input
      type="hidden"
      name={ProjectHistoryFieldNames.State}
      value={StateEnum.Other}
    />
  </div>
);

export const CompletionDate = () => (
  <div>
    <DatePickerField
      id={ProjectHistoryFieldNames.CompletionDate}
      field={ProjectHistoryFieldNames.CompletionDate}
      label={ProjectHistoryFieldLabels.CompletionDate}
      dateFormat={DatePickerFormat.OmitDateShortMonth}
      placeholderText="Month and Year"
      showMonthYearPicker
      isRequired
    />
  </div>
);

// temporarily commented until displayed in subbie and builder apps.
// const Description = () => {
//   return (
//     <div className={styles.textAreaWrapper}>
//       <TextAreaWrapper<string>
//         field={ProjectHistoryFieldNames.Description}
//         id={ProjectHistoryFieldNames.Description}
//         label={ProjectHistoryFieldLabels.Description}
//         placeholder="Description (2000 characters)"
//       />
//     </div>
//   );
// };

const defaultValues: DefaultProjectHistoryFieldValues = {
  id: '',
  builderName: '',
  name: '',
  state: undefined,
  region: null,
  completionDate: undefined,
  category: undefined,
  contractSize: undefined,
  linkedProjectId: null,
  description: null,
};

export const ProjectHistoryForm = ({
  onAbort,
  initialValues = defaultValues,
  onSubmit,
  canSearchProjects = false,
  error,
}: ProjectHistoryFormProps) => {
  const translate = useTranslator();
  const isUKOrIE = ['gb', 'ie'].includes(translate('location'));

  return (
    <Form<ProjectHistoryFieldValues>
      onSubmit={onSubmit}
      validate={validate}
      initialValues={initialValues}
    >
      {({ handleSubmit }) => {
        return (
          <form id="PROJECT_HISTORY_FORM" onSubmit={handleSubmit}>
            {error && <FormError />}
            <h3 className={styles.formHeader}>Project Details</h3>
            <div className={styles.projectHistoryForm}>
              {canSearchProjects ? <SearchableProjectName /> : <ProjectName />}
              <Category />
              <div className={styles.adjacentFields}>
                {isUKOrIE ? <Region /> : <State />}
                <ContractSizeSelect />
              </div>
              {isUKOrIE ? <BuilderNameUkIe /> : <BuilderName />}
              <CompletionDate />
              {/*<Description />*/}
            </div>
            <ModalFooter close={onAbort} />
          </form>
        );
      }}
    </Form>
  );
};
