import React from 'react';
import { Form } from 'react-final-form';
import { ModalFooter } from '../../../../modules/Modal/ModalFooter';
import { FormError } from '../../../FormError';
import { CategoriesMultiSelectField } from './fields/Categories';
import { getCategoryLabel } from '../../utils';
import { useSubmitForm } from './hooks';
import {
  EditCategoriesFieldName,
  EditCategoriesFieldValues,
} from './fields/types';
import { ProjectCategoryEnum } from '../../../../__generated__/globalTypes';

export type EditCategoriesModalProps = {
  categories: ProjectCategoryEnum[];
  close: () => void;
};

export const EditCategoriesForm = ({
  categories,
  close,
}: EditCategoriesModalProps) => {
  const { submit: submitForm, error } = useSubmitForm();

  const onSubmit = async (values: EditCategoriesFieldValues) => {
    const errors = await submitForm(values);
    if (!errors) {
      close();
    }
  };

  return (
    <Form<EditCategoriesFieldValues>
      keepDirtyOnReinitialize
      onSubmit={onSubmit}
      initialValues={{
        [EditCategoriesFieldName.Categories]: categories
          ? categories.map((category) => {
              return {
                value: category,
                label: getCategoryLabel(category),
              };
            })
          : [],
      }}
    >
      {({ handleSubmit }) => (
        <form id="EDIT_CATEGORIES_FORM" onSubmit={handleSubmit}>
          {error && <FormError />}
          <CategoriesMultiSelectField />
          <ModalFooter close={close} />
        </form>
      )}
    </Form>
  );
};
