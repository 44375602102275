import React from 'react';
import { AlertIcon, SkeletonLoader } from '@estimateone/frontend-components';
import { ProjectsBadge } from '../ProjectsBadge';
import { useOwnAccount, usePaidView } from 'hooks';
import styles from './ProjectsBadgeWrapper.module.scss';

export const ProjectsBadgeWrapperLoading = () => (
  <div className={styles.loadingState}>
    <SkeletonLoader height="16px" />
  </div>
);

export const ProjectsBadgeError = () => (
  <div className={styles.errorIconContainer}>
    <AlertIcon title="Projects icon error icon" />
  </div>
);

export const ProjectsBadgeWrapper = ({ count }: { count: number }) => {
  const { loading, error, isPaidAccount } = usePaidView();
  const { isOwnAccount } = useOwnAccount();

  if (loading) {
    return <ProjectsBadgeWrapperLoading />;
  }

  if (error) {
    return <ProjectsBadgeError />;
  }

  return <ProjectsBadge projectsCount={count} />;
};
