import React, { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useProfileAccountId } from '../ProfileProvider';
import { FeatureContext, FeatureContextProps } from 'context/FeatureContext';
import { GET_ACCOUNT_FEATURES } from './queries';
import { AvailableFeatures } from './__generated__/AvailableFeatures';

const { Provider } = FeatureContext;

type FeatureProviderProps = {
  children: React.ReactNode;
};

export const FeatureProvider = ({ children }: FeatureProviderProps) => {
  const { accountId } = useProfileAccountId();
  const [makeQuery, { loading, data, error }] = useLazyQuery<AvailableFeatures>(
    GET_ACCOUNT_FEATURES,
    {
      variables: { accountId },
    },
  );

  useEffect(() => {
    if (accountId !== null) {
      makeQuery();
    }
  }, [accountId, makeQuery]);

  if (loading || data === undefined) {
    return (
      <Provider value={{ loading, context: null, error }}>{children}</Provider>
    );
  }

  const { availableFeatures } = data;
  const { companyProfileBasic, companyProfileAdvanced } = availableFeatures;

  const value: FeatureContextProps = {
    loading,
    error,
    context: {
      companyProfileBasic,
      companyProfileAdvanced,
    },
  };

  return <Provider value={value}>{children}</Provider>;
};
