import React from 'react';

export type AuthContextValueType = {
  getToken: () => Promise<string>;
  logout: () => Promise<void>;
};

export const AuthContext = React.createContext<AuthContextValueType | null>(
  null,
);
