import { useCallback } from 'react';

export const useFlashMessage = () => {
  const successFlash = useCallback((title: string, message: string) => {
    document.dispatchEvent(
      new CustomEvent('flashMessage', {
        detail: {
          type: 'success',
          title,
          message,
        },
      }),
    );
  }, []);

  const warningFlash = useCallback((title: string, message: string) => {
    document.dispatchEvent(
      new CustomEvent('flashMessage', {
        detail: {
          type: 'warning',
          title,
          message,
        },
      }),
    );
  }, []);

  return { successFlash, warningFlash };
};
