import { gql } from '@apollo/client';

export const ADD_OFFICE_DETAILS = gql`
  mutation AddOfficeDetails($officeDetails: AddOfficeDetailsInput!) {
    addOfficeDetails(input: $officeDetails) {
      ... on AccountOffice {
        id
        name
        phone
        email
        website
        address {
          id
          address1
          address2
          address3
          fullAddress
          shortAddress
          suburb
          postcode
          city
          province
          state {
            id
            shortName
          }
          country {
            id
            shortName
          }
        }
      }
      ... on Errors {
        errors {
          message
        }
      }
    }
  }
`;
