import React from 'react';
import { Form } from 'react-final-form';
import { TranslationProvider } from 'modules/TranslationsProvider';
import { ModalFooter } from '../../../modules/Modal/ModalFooter';
import { FormError } from '../../FormError';
import { Fields } from './fields/Fields';
import { useSubmitForm } from './hooks';
import {
  EditOfficeDetailsFieldNames,
  EditOfficeDetailsFieldValues,
} from './fields/types';
import { StateType } from 'types';
import styles from './EditOfficeDetailsForm.module.scss';

type EditOfficeDetailsFormProps = {
  AccountOfficeId: number;
  Name: string;
  StreetAddress: string | null;
  Suburb: string | null;
  State: StateType;
  City: string | null;
  Province: string | null;
  Postcode?: string | null;
  OfficeEmail?: string | null;
  PhoneNumber?: string | null;
  Website: string | null;
  close: () => void;
};

export const EditOfficeDetailsForm = ({
  AccountOfficeId,
  Name,
  StreetAddress,
  Suburb,
  City,
  Province,
  Website,
  State,
  Postcode,
  PhoneNumber,
  OfficeEmail,
  close,
}: EditOfficeDetailsFormProps) => {
  const { submit: submitForm, error } = useSubmitForm();

  const onSubmit = async (values: EditOfficeDetailsFieldValues) => {
    const errorsOrNull = await submitForm(values);
    if (errorsOrNull === null) {
      close();
    }
  };

  return (
    <Form<EditOfficeDetailsFieldValues>
      onSubmit={onSubmit}
      initialValues={{
        [EditOfficeDetailsFieldNames.StreetAddress]: StreetAddress,
        [EditOfficeDetailsFieldNames.AccountOfficeId]: AccountOfficeId,
        [EditOfficeDetailsFieldNames.Name]: Name,
        [EditOfficeDetailsFieldNames.OfficeEmail]: OfficeEmail,
        [EditOfficeDetailsFieldNames.Website]: Website,
        [EditOfficeDetailsFieldNames.Suburb]: Suburb,
        [EditOfficeDetailsFieldNames.City]: City,
        [EditOfficeDetailsFieldNames.Province]: Province,
        [EditOfficeDetailsFieldNames.State]: State,
        [EditOfficeDetailsFieldNames.Postcode]: Postcode,
        [EditOfficeDetailsFieldNames.PhoneNumber]: PhoneNumber,
      }}
    >
      {({ handleSubmit }) => {
        return (
          <TranslationProvider>
            <form
              id="EDIT_OFFICE_DETAILS_FORM"
              onSubmit={handleSubmit}
              className={styles.form}
            >
              {error && <FormError />}
              <Fields />
              <ModalFooter close={close} />
            </form>
          </TranslationProvider>
        );
      }}
    </Form>
  );
};
