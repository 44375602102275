import React from 'react';
import { MultiSelectDropdownField } from '../../../../../modules/FinalFormFields/MultiSelectDropdownField/MultiSelectDropdownField';
import { getCategoryLabel } from '../../../utils';
import { EditCategoriesFieldName } from './types';
import { ProjectCategoryEnum } from '../../../../../__generated__/globalTypes';

export type CategoryOption = {
  value: ProjectCategoryEnum;
  label: string;
};

export const categoriesOptions = Object.keys(ProjectCategoryEnum)
  .map((value: unknown) => value as keyof typeof ProjectCategoryEnum)
  .map((value: keyof typeof ProjectCategoryEnum) => ProjectCategoryEnum[value])
  .map((value: ProjectCategoryEnum) => ({
    value: value,
    label: getCategoryLabel(value),
  }));

export const CategoriesMultiSelectField = () => {
  return (
    <MultiSelectDropdownField
      id="categories"
      label="Categories"
      fieldName={EditCategoriesFieldName.Categories}
      options={categoriesOptions}
    />
  );
};
