import { createContext } from 'react';
import { ApolloError } from '@apollo/client';
import { AvailableFeatures_availableFeatures } from 'modules/FeatureProvider/__generated__/AvailableFeatures';

type EnabledFeatures = {
  [K in keyof AvailableFeatures_availableFeatures]: AvailableFeatures_availableFeatures[K];
};

type Features = Omit<EnabledFeatures, '__typename'>;

type FeatureContextLoading = {
  loading: boolean;
  context: null;
  error?: ApolloError;
};

type FeatureContextLoaded = {
  loading: boolean;
  context: Features;
  error?: ApolloError;
};

export type FeatureContextProps = FeatureContextLoading | FeatureContextLoaded;

export const FeatureContext = createContext<FeatureContextProps>({
  loading: true,
  context: null,
  error: undefined,
});
