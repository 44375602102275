import { gql, useApolloClient, useMutation } from '@apollo/client';
import { useCompanyProfileCacheItemId } from '../../../modules/ProfileProvider';
import { DELETE_ACCOUNT_OFFICE } from './mutations';
import { CompanyProfile_companyProfile } from '../../../modules/ProfileProvider/__generated__/CompanyProfile';

import {
  DeleteAccountOffice,
  DeleteAccountOfficeVariables,
} from './__generated__/DeleteAccountOffice';

export const useSubmitForm = () => {
  const { cache } = useApolloClient();
  const id = useCompanyProfileCacheItemId();

  const [deleteAccountOffice, { error }] = useMutation<
    DeleteAccountOffice,
    DeleteAccountOfficeVariables
  >(DELETE_ACCOUNT_OFFICE, {
    onCompleted: (data) => {
      const profile = cache.readFragment<
        Pick<CompanyProfile_companyProfile, 'offices'>
      >({
        id,
        fragment: gql`
          fragment OfficesForDelete on Profile {
            id
            offices {
              id
              ascensionId
            }
          }
        `,
      });

      const itemToEvect = profile?.offices.find(
        (office) => office.ascensionId === data.deleteAccountOffice.id,
      );

      if (itemToEvect) {
        cache.evict({
          id: `ProfileOffice:${itemToEvect.id}`,
        });
      }
    },
  });

  const submit = async (id: GraphQLEntityId) => {
    const { errors } = await deleteAccountOffice({
      variables: {
        accountOfficeId: id,
      },
    });

    return errors ? errors : null;
  };

  return { submit, error: error ?? undefined };
};
