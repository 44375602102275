import React from 'react';
import {
  Modal,
  ModalSize,
  Button,
  ButtonVariant,
} from '@estimateone/frontend-components';
import { useLogoRemover } from '../hook';
import styles from './DeleteCompanyLogoConfirmation.module.scss';

type DeleteCompanyLogoConfirmationProps = {
  isOpen: boolean;
  toggleModal: () => void;
};

export const DeleteCompanyLogoConfirmation = ({
  isOpen,
  toggleModal,
}: DeleteCompanyLogoConfirmationProps) => {
  const { onRemove } = useLogoRemover();

  return (
    <Modal
      rootElement="#MFE_PROFILES_ROOT"
      isOpen={isOpen}
      onRequestClose={toggleModal}
      size={ModalSize.Small}
    >
      <Modal.Section>
        <h3 className={styles.modalHeader}>Remove Company Logo</h3>
        <p className={styles.modalContent}>
          Are you sure you want to remove <b>Company Logo</b>?
        </p>
        <Button type="button" variant={ButtonVariant.Red} onClick={onRemove}>
          Remove
        </Button>
      </Modal.Section>
    </Modal>
  );
};
