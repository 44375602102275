export const emailValidate = (email?: string) => {
  if (!email) {
    return undefined;
  }
  const emailReg = new RegExp(
    /^[a-zA-Z0-9.!#$%&\'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/,
    'i',
  );
  return emailReg.test(email) ? undefined : ['Email address is not valid'];
};
