import React from 'react';
import { getDateMonthYearStringOrNull } from 'utils';

type MemberSinceProps = {
  memberSince?: string;
};

export const MemberSince = ({ memberSince }: MemberSinceProps) => {
  const date = getDateMonthYearStringOrNull(memberSince);
  return date ? <span>Member Since: {date} </span> : <span />;
};
