import React, { createContext } from 'react';
import { ApolloError, useQuery } from '@apollo/client';
import { COMPANY_LOGO_BASE_HREF } from '../../constants';
import { getCompanyLogoKey } from '../../components/CompanyInfo/utils';
import {
  GET_COMPANY_PROFILE,
  GET_COMPANY_PROFILE_BY_ACCOUNT_ID,
} from './queries';
import {
  CompanyProfile,
  CompanyProfileVariables,
} from './__generated__/CompanyProfile';
import {
  CompanyProfileByAccountId,
  CompanyProfileByAccountIdVariables,
} from './__generated__/CompanyProfileByAccountId';

export type ProfileContextProps = {
  loading: boolean;
  profile?: CompanyProfile['companyProfile'];
  error?: ApolloError;
};

export const ProfileContext = createContext<ProfileContextProps>({
  loading: true,
});

type ProfileByAccountIdProviderProps = {
  accountId: number;
  children?: React.ReactNode;
};

const ProfileByAccountIdProvider = ({
  accountId,
  children,
}: ProfileByAccountIdProviderProps) => {
  const { loading, data, error } = useQuery<
    CompanyProfileByAccountId,
    CompanyProfileByAccountIdVariables
  >(GET_COMPANY_PROFILE_BY_ACCOUNT_ID, { variables: { id: `${accountId}` } });

  return (
    <ProfileContext.Provider
      value={{
        loading,
        error,
        profile: data?.companyProfile,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};

type ProfileByProfileIdProviderProps = {
  companyProfileId: string;
  children?: React.ReactNode;
};

const ProfileByProfileIdProvider = ({
  companyProfileId,
  children,
}: ProfileByProfileIdProviderProps) => {
  const { loading, data, error } = useQuery<
    CompanyProfile,
    CompanyProfileVariables
  >(GET_COMPANY_PROFILE, { variables: { id: companyProfileId } });

  return (
    <ProfileContext.Provider
      value={{
        loading,
        error,
        profile: data?.companyProfile,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};

type ProfileProviderProps = {
  companyProfileId?: string;
  accountId?: number;
  children?: React.ReactNode;
};

export const ProfileProvider = ({
  children,
  companyProfileId,
  accountId,
}: ProfileProviderProps) => {
  if (companyProfileId) {
    return (
      <ProfileByProfileIdProvider companyProfileId={companyProfileId}>
        {children}
      </ProfileByProfileIdProvider>
    );
  }

  if (accountId) {
    return (
      <ProfileByAccountIdProvider accountId={accountId}>
        {children}
      </ProfileByAccountIdProvider>
    );
  }

  throw new Error('nothing was provided!');
};

export const useProfileContext = () => {
  const ctx = React.useContext(ProfileContext);

  if (ctx === undefined) {
    throw new Error(
      'useProfileContext and its hooks may only be used within an ProfileProvider',
    );
  }

  return ctx;
};

export const useProfileAccountId = () => {
  const ctx = useProfileContext();

  const accountId =
    typeof ctx.profile?.account?.id === 'number'
      ? ctx.profile?.account?.id
      : typeof ctx.profile?.account?.id === 'string'
      ? Number(ctx.profile?.account?.id)
      : null;

  return {
    loading: ctx.loading,
    error: ctx.error,
    accountId,
  };
};

export const useProfileId = () => {
  const ctx = useProfileContext();

  return ctx.profile?.id;
};

export const useCompanyProfileCacheItemId = () => {
  const ctx = useProfileContext();

  return `Profile:${ctx.profile?.id}`;
};

export const useCompanyLogoURL = () => {
  const hash = React.useMemo(() => Date.now(), []);
  const ctx = useProfileContext();

  const accountId = ctx.profile?.account?.id ?? '';
  const logoKey = getCompanyLogoKey(accountId.toString());

  return `${COMPANY_LOGO_BASE_HREF}/${logoKey}?t=${hash}`;
};
