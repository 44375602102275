import React from 'react';
import {
  InsightIcon,
  HelpBanner,
  HelpBannerContent,
} from '@estimateone/frontend-components';
import { useTranslator } from '../../../modules/TranslationsProvider';
import { ProfileStatus } from './types';
import styles from './ProfileInsights.module.scss';

type IncompleteProfileInsightsAlertProps = {
  profileStatus: ProfileStatus;
};

export const IncompleteProfileInsightsAlert = ({
  profileStatus,
}: IncompleteProfileInsightsAlertProps) => {
  const translate = useTranslator();

  return (
    <HelpBanner isHidden={false} toggleHidden={() => {}}>
      <HelpBannerContent>
        <div className={styles.alertHeader}>
          <InsightIcon />
          <h3 className={styles.alertHeaderNoMargin}>
            Complete your Profile to be seen by{' '}
            {`${translate('builderLabel')}s`}
          </h3>
        </div>
        <p>
          Low or no Profile views? {`${translate('builderLabel')}s`} can only
          discover your Profile if you have the following completed:
        </p>
        <ul aria-label="profile-status" className={styles.incompleteReasons}>
          {!profileStatus.hasCompanyNumber && (
            <li>
              your <strong>{translate('businessIdentifier')}</strong>
            </li>
          )}
          {!profileStatus.hasContractSize && (
            <li>
              a <strong>Contract size</strong> minimum and maximum
            </li>
          )}
          {!profileStatus.hasContacts && (
            <li>
              at least <strong>one Contact</strong>
            </li>
          )}
          {!profileStatus.hasOptimalTradesAmount && (
            <li>
              don’t select all Trades - only the Trades that best reflect the
              type of work you complete.
            </li>
          )}
        </ul>
        <p>
          Don’t forget to showcase your <strong>Project History</strong> to give{' '}
          {`${translate('builderLabel')}s`} insight into your work history.
        </p>
      </HelpBannerContent>
    </HelpBanner>
  );
};
