import React from 'react';

import { useQuery } from '@apollo/client';
import { GlobalErrorBanner } from 'components/GlobalErrorBanner';
import { useViewerContext } from 'modules/ViewerProvider/ViewerProvider';
import { AU_COUNTRY_ID } from '../../../constants';
import { DirectoryOptInOption } from './DirectoryOptInOption';
import { SSCOptOutOption } from './SSCOptOutOption';
import { GET_PRIMARY_COUNTRY } from './queries';
import { GetCurrentAccountCountry } from './__generated__/GetCurrentAccountCountry';
import styles from './ProfileSettings.module.scss';

export const ProfileSettingsPage = () => {
  const { isAuthorisedToOptIntoDirectory } = useViewerContext();
  const { data } = useQuery<GetCurrentAccountCountry>(GET_PRIMARY_COUNTRY);
  const showSSCOptOutOption =
    AU_COUNTRY_ID === data?.currentAccount?.primaryCountry?.id;

  return (
    <>
      <GlobalErrorBanner />
      <h1 className={styles.settingsHeader}>Profile Settings</h1>
      {showSSCOptOutOption && <SSCOptOutOption />}
      {isAuthorisedToOptIntoDirectory && <DirectoryOptInOption />}
    </>
  );
};
