import { gql, useApolloClient, useMutation } from '@apollo/client';
import { useCompanyProfileCacheItemId } from '../../../../modules/ProfileProvider';
import { CHANGE_PRIMARY_CONTACT } from './mutations';
import { EditPrimaryContactFieldValues } from './types';
import { CompanyProfile_companyProfile } from '../../../../modules/ProfileProvider/__generated__/CompanyProfile';
import {
  ChangePrimaryContact,
  ChangePrimaryContactVariables,
} from './__generated__/ChangePrimaryContact';

export const useSubmitForm = () => {
  const { cache } = useApolloClient();
  const id = useCompanyProfileCacheItemId();

  const [handleChangePrimaryContact, { error }] = useMutation<
    ChangePrimaryContact,
    ChangePrimaryContactVariables
  >(CHANGE_PRIMARY_CONTACT, {
    onCompleted: (data) => {
      const ascensionIdOfPrimaryContact =
        data.changePrimaryContact.preferredContact?.id;

      const profile = cache.readFragment<
        Pick<CompanyProfile_companyProfile, 'people'>
      >({
        id,
        fragment: gql`
          fragment ProfilePeople on Profile {
            people {
              id
              ascensionId
              isDefaultContact
            }
          }
        `,
      });
      profile?.people.forEach((person) => {
        cache.modify({
          id: `${person.__typename}:${person.id}`,
          fields: {
            isDefaultContact: () =>
              person.ascensionId === ascensionIdOfPrimaryContact,
          },
        });
      });
    },
  });

  const submit = async ({ PrimaryContact }: EditPrimaryContactFieldValues) => {
    const { errors } = await handleChangePrimaryContact({
      variables: { userId: PrimaryContact.value },
    });
    return errors ? errors : null;
  };

  return { submit, error: error ?? undefined };
};
