import React, { useState } from 'react';
import { E1Link } from '@estimateone/frontend-components';
import styles from './ShowMoreOrLessContent.module.scss';

type ShowMoreOrLessContentProps = {
  content: string | null;
  minContentSize: number;
};
export const ShowMoreOrLessContent = ({
  content,
  minContentSize,
}: ShowMoreOrLessContentProps) => {
  const [showMore, setShowMore] = useState(false);
  const TRUNCATOR = '...';

  return (
    <div>
      <p className={styles.content}>
        {showMore ? content : content?.substring(0, minContentSize) + TRUNCATOR}
      </p>
      {content && content.length > minContentSize ? (
        <div className={styles.buttonContainer}>
          <E1Link onClick={() => setShowMore(!showMore)}>
            {showMore ? 'Show less' : 'Show more'}
          </E1Link>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
