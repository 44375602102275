import { gql } from '@apollo/client';

export const CHANGE_COMPANY_NAME = gql`
  mutation ChangeAccountName($name: String!) {
    changeAccountName(name: $name) {
      id
      name
    }
  }
`;
