import React from 'react';
import {
  Alert,
  AlertVariant,
  Icon,
  IconName,
} from '@estimateone/frontend-components';
import { ERROR_TEXT } from './constants';
import styles from './FormError.module.scss';

export type FormErrorProps = {
  errorMessage?: string;
};

export const FormError = ({ errorMessage = ERROR_TEXT }: FormErrorProps) => (
  <Alert className={styles.serverError} variant={AlertVariant.Red}>
    <Icon className={styles.serverErrorIcon} name={IconName.Clear} />
    <p>{errorMessage}</p>
  </Alert>
);
