/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useField } from 'react-final-form';
import { TextInput } from '@estimateone/frontend-components';
import { FieldWrapper } from '../../../../../modules/FinalFormFields/FieldWrapper';
import {
  ProjectHistoryFieldLabels,
  ProjectHistoryFieldNames,
} from '../../types';

export const ProjectName = () => {
  const {
    input,
    meta: { error, submitError, touched },
  } = useField<string, HTMLInputElement, string>(
    ProjectHistoryFieldNames.Name,
    {
      parse: (inputValue) => inputValue,
      format: (value) => value,
    },
  );

  return (
    <FieldWrapper
      errors={error || submitError}
      hideErrorMessages={false}
      showErrors={touched}
    >
      <TextInput
        id={ProjectHistoryFieldNames.Name}
        label={ProjectHistoryFieldLabels.Name}
        placeholder={ProjectHistoryFieldLabels.Name}
        isRequired={true}
        {...input}
      />
    </FieldWrapper>
  );
};
