import {
  ContractSizeMaxEnum,
  ContractSizeMinEnum,
} from '../../../../__generated__/globalTypes';

export const ContractSizeMinEnumMap = {
  [ContractSizeMinEnum.SIZE_10k]: 10000,
  [ContractSizeMinEnum.SIZE_50k]: 50000,
  [ContractSizeMinEnum.SIZE_100k]: 100000,
  [ContractSizeMinEnum.SIZE_250k]: 250000,
  [ContractSizeMinEnum.SIZE_500k]: 500000,
  [ContractSizeMinEnum.SIZE_1M]: 1000000,
  [ContractSizeMinEnum.SIZE_5M]: 5000000,
};

export const ContractSizeMaxEnumMap = {
  [ContractSizeMaxEnum.SIZE_10k]: 10000,
  [ContractSizeMaxEnum.SIZE_50k]: 50000,
  [ContractSizeMaxEnum.SIZE_100k]: 100000,
  [ContractSizeMaxEnum.SIZE_250k]: 250000,
  [ContractSizeMaxEnum.SIZE_500k]: 500000,
  [ContractSizeMaxEnum.SIZE_1M]: 1000000,
  [ContractSizeMaxEnum.SIZE_5M]: 5000000,
  [ContractSizeMaxEnum.SIZE_10M_PLUS]: 10000000,
};
