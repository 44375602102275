import React from 'react';
import {
  Button,
  ButtonVariant,
  Modal,
  ModalSize,
} from '@estimateone/frontend-components';
import { FormError } from 'components/FormError';
import { useSubmitForm } from './hooks';
import styles from './DeleteOfficeConfirmation.module.scss';

export type DeleteOfficeConfirmationProps = {
  id: number;
  name: string;
  isOpen: boolean;
  closeModal: () => void;
};

export const DeleteOfficeConfirmation = ({
  id,
  name,
  isOpen,
  closeModal,
}: DeleteOfficeConfirmationProps) => {
  const { submit: submitForm, error } = useSubmitForm();

  const handleSubmit = async () => {
    const errorsOrNull = await submitForm(id);
    if (errorsOrNull === null) {
      closeModal();
    }
  };

  return (
    <Modal
      rootElement="#MFE_PROFILES_ROOT"
      isOpen={isOpen}
      onRequestClose={closeModal}
      size={ModalSize.Small}
    >
      <Modal.Section>
        {error && <FormError />}
        <h3 className={styles.header}>Delete Office</h3>
        <p className={styles.content}>
          Are you sure you want to delete <b>{name}</b>?
        </p>
        <Button
          type="button"
          variant={ButtonVariant.Red}
          onClick={handleSubmit}
        >
          Delete
        </Button>
      </Modal.Section>
    </Modal>
  );
};
