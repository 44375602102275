import { EnvConfig } from '../../types';

const requiredValues: Array<keyof EnvConfig> = ['BFF_URI', 'IDP_URI'];

const isValid = (config: EnvConfig | Partial<EnvConfig>): config is EnvConfig =>
  !requiredValues.find((key) => config[key] === undefined);

export const parseEnvConfig = (envConfig: Partial<EnvConfig>): EnvConfig => {
  if (!isValid(envConfig)) {
    throw new Error('config is invalid');
  }

  return envConfig;
};
