import { useMutation } from '@apollo/client';
import { EDIT_HISTORICAL_PROJECT } from './mutations';
import {
  ProjectHistoryFieldValues,
  ProjectHistoryFieldNames,
} from 'components/ProjectHistory/ProjectHistoryForm/types';

import {
  UpdateHistoricalProject,
  UpdateHistoricalProjectVariables,
} from './__generated__/UpdateHistoricalProject';

const transformValues = (values: ProjectHistoryFieldValues) => {
  const contractSize = values[ProjectHistoryFieldNames.ContractSize];

  return {
    name: values[ProjectHistoryFieldNames.Name] || '',
    builderName: values[ProjectHistoryFieldNames.BuilderName] || '',
    state: values[ProjectHistoryFieldNames.State]?.value || undefined,
    region: values[ProjectHistoryFieldNames.Region] || null,
    category: values[ProjectHistoryFieldNames.Category]?.value || undefined,
    completionDate:
      values[ProjectHistoryFieldNames.CompletionDate].toISOString(),
    contractSize: contractSize.value,
    linkedProjectId: values[ProjectHistoryFieldNames.LinkedProjectId] || null,
    description: values[ProjectHistoryFieldNames.Description] || null,
  };
};

export const useEditHistoricalProject = (id: string) => {
  const [updateHistoricalProject, { error, reset }] = useMutation<
    UpdateHistoricalProject,
    UpdateHistoricalProjectVariables
  >(EDIT_HISTORICAL_PROJECT);

  const submit = (values: ProjectHistoryFieldValues) => {
    return updateHistoricalProject({
      variables: {
        input: transformValues(values),
        id,
      },
    });
  };

  return { submit, error, reset };
};
