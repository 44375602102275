import React, { useReducer } from 'react';
import { E1Link, Modal, ModalSize } from '@estimateone/frontend-components';
import { useProfileContext } from '../../../modules/ProfileProvider';
import { EditPrimaryContactForm } from './EditPrimaryContactForm';
import { CompanyProfile_companyProfile_people } from '../../../modules/ProfileProvider/__generated__/CompanyProfile';

const ALLOW_MODAL_OVERFLOW = {
  style: {
    content: {
      overflow: 'visible',
    },
  },
};

export type EditPrimaryContactProps = {
  primaryContact: CompanyProfile_companyProfile_people | null;
};

export const EditPrimaryContact = ({
  primaryContact,
}: EditPrimaryContactProps) => {
  const { loading, error, profile } = useProfileContext();
  const contacts = profile?.people;

  const [isOpen, toggle] = useReducer((curr) => !curr, false);

  if (loading || error || contacts === null) {
    return <></>;
  }

  return (
    <div>
      <Modal
        rootElement="#MFE_PROFILES_ROOT"
        isOpen={isOpen}
        onRequestClose={toggle}
        size={ModalSize.Small}
        reactModalProps={ALLOW_MODAL_OVERFLOW}
      >
        <Modal.Section>
          <EditPrimaryContactForm
            primaryContact={primaryContact}
            contacts={contacts ?? []}
            close={toggle}
          />
        </Modal.Section>
      </Modal>
      <E1Link onClick={toggle}>Edit</E1Link>
    </div>
  );
};
