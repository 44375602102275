import React from 'react';
import { Form } from 'react-final-form';
import { Modal } from '@estimateone/frontend-components';
import { InputFieldWrapper } from '../../modules/FinalFormFields/InputFieldWrapper';
import { ModalFooter } from '../../modules/Modal/ModalFooter';
import { FormError } from '../FormError';
import { EditModalProps, EditCompanyFieldValues } from './types';

export const EditModal = ({
  fieldName,
  fieldLabel,
  fieldValue,
  toggle,
  onClick,
  error,
  validate,
}: EditModalProps) => {
  return (
    <Modal.Section>
      <Form<EditCompanyFieldValues>
        keepDirtyOnReinitialize
        onSubmit={onClick}
        initialValues={{
          [fieldName]: fieldValue || '',
        }}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            {error && <FormError />}
            <InputFieldWrapper<string>
              field={fieldName}
              id={fieldName}
              label={fieldLabel}
              validator={validate}
            />
            <ModalFooter close={toggle} />
          </form>
        )}
      </Form>
    </Modal.Section>
  );
};
