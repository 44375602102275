import { OperationVariables, useApolloClient } from '@apollo/client';
import { DocumentNode } from 'graphql/language/ast';
import { GET_PROFILE } from '../../components/OfficeContactList/queries';
import {
  SubbieProfileQuery,
  SubbieProfileQueryVariables,
} from '../../components/OfficeContactList/__generated__/SubbieProfileQuery';

export const useApolloCache = () => {
  const client = useApolloClient();

  const updateCacheForQuery = <T, V extends OperationVariables>(
    queryOptions: { query: DocumentNode; variables?: V },
    mutator: (cacheContents: T) => T,
  ) => {
    const cache = client.readQuery<T>(queryOptions);

    if (cache !== null) {
      client.writeQuery<T>({
        ...queryOptions,
        data: mutator(cache),
      });
    }
  };

  const updateCacheForProfileQuery = (
    accountId: SubbieProfileQueryVariables['accountId'],
    mutator: (cacheContents: SubbieProfileQuery) => SubbieProfileQuery,
  ) =>
    updateCacheForQuery<SubbieProfileQuery, SubbieProfileQueryVariables>(
      { query: GET_PROFILE, variables: { accountId } },
      mutator,
    );

  return { updateCacheForQuery, updateCacheForProfileQuery };
};
