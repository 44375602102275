import React, { useReducer } from 'react';
import { E1Link, Modal, ModalSize } from '@estimateone/frontend-components';
import { ProjectHistoryForm } from 'components/ProjectHistory/ProjectHistoryForm';
import { extractFieldErrors } from 'utils';
import { useAddProjectToProjectHistory } from './hooks';
import {
  ProjectHistoryFieldNames,
  ProjectHistoryFieldValues,
} from 'components/ProjectHistory/ProjectHistoryForm/types';

type AddHistoricalProjectProps = {
  triggerElement?: React.ReactElement;
};

export const AddHistoricalProject = ({
  triggerElement,
}: AddHistoricalProjectProps) => {
  const [isOpen, toggle] = useReducer((curr) => !curr, false);
  const { submit: submitForm, error, reset } = useAddProjectToProjectHistory();
  const onSubmit = async (values: ProjectHistoryFieldValues) => {
    const { data, errors } = await submitForm(values);
    if (errors) {
      return;
    }

    if (data?.addProjectToHistory.__typename === 'FieldErrors') {
      const errors = data.addProjectToHistory.errors;

      const fieldErrors = extractFieldErrors(errors, [
        ProjectHistoryFieldNames.Name,
        ProjectHistoryFieldNames.BuilderName,
        ProjectHistoryFieldNames.State,
        ProjectHistoryFieldNames.Region,
        ProjectHistoryFieldNames.CompletionDate,
        ProjectHistoryFieldNames.Category,
        ProjectHistoryFieldNames.ContractSize,
      ]);

      return fieldErrors;
    } else {
      closeModal();
    }
  };

  const closeModal = () => {
    toggle();
    reset();
  };

  return (
    <div>
      {/* //consider using the same way as editPHform is rendering? */}
      <div onClick={toggle}>
        {triggerElement ? triggerElement : <E1Link>Add</E1Link>}
      </div>
      <Modal
        rootElement="#MFE_PROFILES_ROOT"
        isOpen={isOpen}
        onRequestClose={closeModal}
        size={ModalSize.Small}
      >
        <Modal.Section>
          <ProjectHistoryForm
            onSubmit={onSubmit}
            error={error}
            onAbort={closeModal}
            canSearchProjects
          />
        </Modal.Section>
      </Modal>
    </div>
  );
};
