import React from 'react';
import { useLocation } from 'react-router-dom';
import { Nav } from '@estimateone/frontend-components';
import { ProfileLayout } from '../../../pages/CompanyProfile';
import { useOwnAccount } from '../../../hooks';
import { ProfileRoute } from '../../../pages/enums';
import styles from './ProfileNavigation.module.scss';

export type ProfileNavigationProps = {
  layout: ProfileLayout;
  isSubbieProfilesInsightsEnabled: boolean;
};

const getActiveRoute = (
  pathname: string,
  isSubbieProfilesInsightsEnabled: boolean,
) => {
  switch (pathname) {
    case ProfileRoute.ProfileSettings:
      return ProfileRoute.ProfileSettings;
    case ProfileRoute.Version:
      return ProfileRoute.Version;
    case ProfileRoute.ProfileInsights:
      return isSubbieProfilesInsightsEnabled
        ? ProfileRoute.ProfileInsights
        : ProfileRoute.CompanyProfile;
    default:
      return ProfileRoute.CompanyProfile;
  }
};

export const ProfileNavigation = ({
  layout,
  isSubbieProfilesInsightsEnabled,
}: ProfileNavigationProps) => {
  const { pathname } = useLocation();
  const { isOwnAccount } = useOwnAccount();
  const shouldRender = isOwnAccount && layout === 'page';

  const activeRoute = getActiveRoute(pathname, isSubbieProfilesInsightsEnabled);

  return shouldRender ? (
    <div className={styles.leftNavPanel}>
      <Nav>
        <Nav.Item
          link={`#${ProfileRoute.CompanyProfile}`}
          isActive={activeRoute === ProfileRoute.CompanyProfile}
          label="Company Profile"
        />
        {isSubbieProfilesInsightsEnabled ? (
          <div className={styles.insightsNav}>
            <Nav.Item
              link={`#${ProfileRoute.ProfileInsights}`}
              isActive={activeRoute === ProfileRoute.ProfileInsights}
              label="Insights"
            />
          </div>
        ) : (
          <></>
        )}
        <Nav.Item
          link={`#${ProfileRoute.ProfileSettings}`}
          isActive={activeRoute === ProfileRoute.ProfileSettings}
          label="Profile Settings"
        />
      </Nav>
    </div>
  ) : null;
};
