import React from 'react';
import { ProjectHistorySectionProps } from '../ProjectHistory';
import { EmptyProjectHistoryContent } from './EmptyProjectHistoryContent';
import { ProjectHistoryCard } from './ProjectHistoryCard';
import { useOwnAccount } from 'hooks';
import styles from './ProjectHistoryContent.module.scss';

export const ProjectHistoryContent = ({ data }: ProjectHistorySectionProps) => {
  const { isOwnAccount, loading } = useOwnAccount();

  if (loading) {
    return <></>;
  }

  if (data.length === 0) {
    return <EmptyProjectHistoryContent />;
  }

  return (
    <>
      <div className={styles.historyContainer}>
        {data.slice(0, 20).map((project) => (
          <ProjectHistoryCard
            key={project.id}
            project={project}
            showActionMenu={isOwnAccount}
          />
        ))}
      </div>
    </>
  );
};
