import React from 'react';
import { Modal, ModalSize } from '@estimateone/frontend-components';
import { EditABNBadgeForm } from './EditABNBadgeForm/EditABNBadgeForm';

export type EditCompanyABNProps = {
  fieldValue: string | undefined | null;
  isOpen: boolean;
  toggle: React.DispatchWithoutAction;
};

export const EditABNBadge = ({
  fieldValue,
  isOpen,
  toggle,
}: EditCompanyABNProps) => {
  return (
    <div>
      <Modal
        rootElement="#MFE_PROFILES_ROOT"
        isOpen={isOpen}
        onRequestClose={() => toggle()}
        size={ModalSize.Small}
      >
        <EditABNBadgeForm abn={fieldValue} close={toggle} />
      </Modal>
    </div>
  );
};
