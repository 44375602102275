import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  ButtonSize,
  ButtonVariant,
  InfoCard,
  joinClassNames,
  RadioButton,
  RadioButtonGroup,
  Tag,
  TagVariant,
} from '@estimateone/frontend-components';
import { useGlobalErrorBanner } from 'components/GlobalErrorBanner';
import { useProfileContext } from '../../../../modules/ProfileProvider';
import { useTranslator } from '../../../../modules/TranslationsProvider';
import { useFlashMessage } from '../../../../hooks/useFlashMessage';
import { useSubmitDirectoryOptIn } from './hooks';
import { DirectoryOptInOptionEnum } from './enums';
import { OptionProps } from '../types';
import styles from '../ProfileSettings.module.scss';

export const DirectoryOptInOption = ({ className }: OptionProps) => {
  const { profile, loading } = useProfileContext();
  const hasOptedIntoDirectory = profile?.isOptedIntoDirectory ?? false;
  const [isOptingIn, setIsOptingIn] = useState(hasOptedIntoDirectory);
  const { submit } = useSubmitDirectoryOptIn();
  const { successFlash } = useFlashMessage();
  const { openBanner } = useGlobalErrorBanner();
  const translate = useTranslator();

  const onChangeDirectoryOptIn = useCallback((value: string) => {
    setIsOptingIn(value === DirectoryOptInOptionEnum.OptInValue);
  }, []);

  const handleError = useCallback(() => {
    // reset to current state
    setIsOptingIn(hasOptedIntoDirectory);
    openBanner();
  }, [hasOptedIntoDirectory, openBanner]);

  const onClickSaveButton = useCallback(async () => {
    try {
      const errors = await submit(isOptingIn);

      if (errors?.length) {
        handleError();
      } else {
        const message = isOptingIn
          ? translate('directoryOptInSuccessMessage')
          : translate('directoryOptOutSuccessMessage');
        successFlash(DirectoryOptInOptionEnum.SuccessTitle, message);
      }
    } catch (error: unknown) {
      handleError();
    }
  }, [isOptingIn, submit, successFlash, handleError]);

  // Update the form when the initial graphql query finishes loading
  useEffect(() => {
    if (!loading) {
      setIsOptingIn(hasOptedIntoDirectory);
    }
  }, [loading, hasOptedIntoDirectory]);

  const optInOutValue = isOptingIn
    ? DirectoryOptInOptionEnum.OptInValue
    : DirectoryOptInOptionEnum.OptOutValue;

  const value = loading ? '' : optInOutValue;

  return (
    <section className={joinClassNames(styles.option, className)}>
      <div className={styles.sectionTitleWrapper}>
        <Tag variant={TagVariant.West200} text="BETA" />
        <span className={styles.sectionTitleText}>
          {DirectoryOptInOptionEnum.SectionTitle}
        </span>
      </div>
      <InfoCard
        heading={DirectoryOptInOptionEnum.InfoCardHeading}
        icon={<></>}
        className={styles.infoCard}
      >
        <ul>
          <li>{DirectoryOptInOptionEnum.InfoCardDescription1}</li>
          <li>{translate('directoryVisibilityDescription')}</li>
        </ul>
      </InfoCard>
      <RadioButtonGroup
        name={DirectoryOptInOptionEnum.OptionName}
        value={value}
        onChange={onChangeDirectoryOptIn}
        className={styles.radioGroup}
      >
        <RadioButton
          id={DirectoryOptInOptionEnum.OptInValue}
          name={DirectoryOptInOptionEnum.OptionName}
          value={DirectoryOptInOptionEnum.OptInValue}
          className={styles.radioButtonWrapper}
        >
          {translate('directoryOptInLabel')}
        </RadioButton>
        <RadioButton
          id={DirectoryOptInOptionEnum.OptOutValue}
          name={DirectoryOptInOptionEnum.OptionName}
          value={DirectoryOptInOptionEnum.OptOutValue}
          className={styles.radioButtonWrapper}
        >
          {DirectoryOptInOptionEnum.OptOutLabel}
        </RadioButton>
      </RadioButtonGroup>
      <Button
        variant={ButtonVariant.Secondary}
        onClick={onClickSaveButton}
        size={ButtonSize.Small}
      >
        {DirectoryOptInOptionEnum.SaveButton}
      </Button>
    </section>
  );
};
