import { gql } from '@apollo/client';

export const EDIT_DIRECTORY_OPT_IN_SETTING = gql`
  mutation EditDirectoryOptInSetting($optIn: Boolean!) {
    editDirectoryOptInSetting(optIn: $optIn) {
      id
      attribute
      value
      success
      errors
    }
  }
`;
