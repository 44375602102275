import React, { useReducer, useState } from 'react';
import {
  ActionMenu,
  Button,
  ButtonVariant,
  Ellipsis,
} from '@estimateone/frontend-components';
import { DeleteOfficeConfirmation } from '../DeleteOfficeConfirmation';
import { EditOfficeDetailsModal } from '../EditOfficeDetailsModal';
import { stateOptions } from '../EditOfficeDetailsForm/fields/types';

export type ActionableOfficeListItemProps = {
  id: number;
  name: string;
  phone: string | null;
  email: string | null;
  address: {
    id: number;
    address1: string | null;
    suburb: string | null;
    city: string | null;
    postcode: string | null;
    state: string | null;
    province: string | null;
  };
  website: string | null;
  defaultOfficeId: GraphQLEntityId;
};

export const ActionableOfficeListItem = ({
  id,
  name,
  phone,
  email,
  address,
  website,
  defaultOfficeId,
}: ActionableOfficeListItemProps) => {
  const [isEditModalOpen, toggleEditModal] = useReducer(
    (curr: boolean) => !curr,
    false,
  );
  const [isDeleteModelOpen, toggleDeleteModal] = useReducer(
    (curr: boolean) => !curr,
    false,
  );

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const initialState = stateOptions.find(
    (option) => option.label === address?.state,
  )?.value;
  const stateValue = initialState ? parseInt(initialState) : 0;
  return (
    <>
      <EditOfficeDetailsModal
        accountOfficeId={id}
        isOpen={isEditModalOpen}
        toggle={toggleEditModal}
        streetAddress={address?.address1 || ''}
        website={website}
        name={name}
        phoneNumber={phone}
        suburb={address?.suburb || ''}
        state={{
          value: stateValue,
          label: address?.state || '',
        }}
        postcode={address?.postcode || ''}
        city={address?.city || ''}
        province={address?.province || ''}
        officeEmail={email}
      />
      <DeleteOfficeConfirmation
        id={id}
        name={name}
        isOpen={isDeleteModelOpen}
        closeModal={toggleDeleteModal}
      />
      <ActionMenu
        visible={isMenuOpen}
        onClickOutside={() => setIsMenuOpen(false)}
        triggerElement={
          <Button
            variant={ButtonVariant.Transparent}
            aria-expanded={isMenuOpen}
            aria-label="Toggle Office Options Menu"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <Ellipsis />
          </Button>
        }
      >
        <ActionMenu.Item
          onClick={() => {
            setIsMenuOpen(false);
            toggleEditModal();
          }}
        >
          Edit
        </ActionMenu.Item>
        {defaultOfficeId !== id ? (
          <ActionMenu.Item
            onClick={() => {
              setIsMenuOpen(false);
              toggleDeleteModal();
            }}
          >
            Delete
          </ActionMenu.Item>
        ) : (
          <></>
        )}
      </ActionMenu>
    </>
  );
};
