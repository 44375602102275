import { Mutex } from 'async-mutex';

export class TokenCache {
  private token?: string;
  private expiresAtUnixMillis = 0;
  private mutex = new Mutex();

  constructor(private tokenUri: string, private tokenLifetimeSeconds = 1800) {}

  /**
   * This function relies on the client having a PHPSESSID cookie
   */
  private async fetchToken(): Promise<string> {
    // We need to send the cookie in the headers so the IDP can look up our session
    const resp = await fetch(this.tokenUri, { credentials: 'include' });
    const { token } = await resp.json();
    return token;
  }

  public async getToken(): Promise<string> {
    if (this.token === undefined || this.hasExpired()) {
      await this.mutex.runExclusive(async () => {
        if (this.token === undefined || this.hasExpired()) {
          this.token = await this.fetchToken();
          this.expiresAtUnixMillis = this.getNewExpiry();
        }
      });
    }
    return this.token as string;
  }

  public expireToken(): void {
    this.token = undefined;
  }

  private hasExpired(): boolean {
    return Date.now() > this.expiresAtUnixMillis;
  }

  private getNewExpiry(): number {
    return Date.now() + this.tokenLifetimeSeconds * 1e3;
  }
}
