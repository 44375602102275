import React from 'react';
import { Tooltip } from '@estimateone/frontend-components';
import { IE_COUNTRY_ID, UK_COUNTRY_ID } from '../../../../constants';
import { InsightsStats } from '../types';
import styles from './styles.module.scss';

type InsightsStatsPanelProps = {
  stats: InsightsStats;
  accountCountryId: number | undefined;
};

export const InsightsStatsPanel = ({
  stats,
  accountCountryId,
}: InsightsStatsPanelProps) => {
  const useUKILanguage =
    accountCountryId &&
    [UK_COUNTRY_ID, IE_COUNTRY_ID].includes(accountCountryId);

  const networkViewsTipText = `${
    useUKILanguage ? 'Main Contractors' : 'Builders'
  } viewing your profile after searching our network`;
  const otherViewsTipText = `${
    useUKILanguage ? 'Main Contractors' : 'Builders'
  } viewing your profile when you request documents, send quotes or other activities`;

  const {
    totalDirectoryViews,
    totalOtherViews,
    totalThisMonthViews,
    totalViews,
  } = stats;
  return (
    <>
      <div className={styles.cardTitleRow}>
        <h4 className={styles.cardHeading}>
          Profile views from {useUKILanguage ? 'Main Contractors' : 'Builders'}
        </h4>
        <span className={styles.cardSubheading}>Last 12 months to date</span>
      </div>
      <div className={styles.cardStats}>
        <div aria-label="Total Views">
          <h3 className={styles.statTitle}>Total</h3>
          <h2 className={styles.statCount}>{!totalViews ? '-' : totalViews}</h2>
        </div>
        <div className={styles.verticalRule} />
        <Tooltip tooltipId="network-views-tip" text={networkViewsTipText}>
          <div aria-label="Network Views">
            <h3 className={styles.statTitle}>Network Views</h3>
            <h2 className={styles.statCount}>
              {!totalDirectoryViews ? '-' : totalDirectoryViews}
            </h2>
          </div>
        </Tooltip>
        <Tooltip tooltipId="other-views-tip" text={otherViewsTipText}>
          <div aria-label="Other Views">
            <h3 className={styles.statTitle}>Other Views</h3>
            <h2 className={styles.statCount}>
              {!totalOtherViews ? '-' : totalOtherViews}
            </h2>
          </div>
        </Tooltip>
        <div className={styles.verticalRule} />
        <div aria-label="This Month">
          <h3 className={styles.statTitle}>This Month</h3>
          <h2 className={styles.statCount}>
            {!totalThisMonthViews ? '-' : totalThisMonthViews}
          </h2>
        </div>
      </div>
    </>
  );
};
