import { useMutation } from '@apollo/client';
import {
  useProfileAccountId,
  useProfileId,
} from '../../../modules/ProfileProvider';
import {
  GET_COMPANY_PROFILE,
  GET_COMPANY_PROFILE_BY_ACCOUNT_ID,
} from 'modules/ProfileProvider/queries';
import { UPDATE_OFFICE_DETAILS } from './mutations';
import {
  EditOfficeDetailsFieldNames,
  EditOfficeDetailsFieldValues,
} from './fields/types';
import {
  ChangeOfficeDetails,
  ChangeOfficeDetailsVariables,
} from './__generated__/ChangeOfficeDetails';
import {
  CompanyProfile,
  CompanyProfile_companyProfile as ProfileByProfileId,
  CompanyProfile_companyProfile_offices as ProfileOffice,
} from 'modules/ProfileProvider/__generated__/CompanyProfile';
import {
  CompanyProfileByAccountId,
  CompanyProfileByAccountId_companyProfile as ProfileByAccountId,
} from 'modules/ProfileProvider/__generated__/CompanyProfileByAccountId';

const updateOffices = (
  data: ChangeOfficeDetails,
  profile: ProfileByProfileId | ProfileByAccountId,
) => {
  return profile?.offices.map((office: ProfileOffice) => {
    if (office.ascensionId !== data.changeOfficeDetails?.id) {
      return office;
    }
    return {
      ...office,
      name: data.changeOfficeDetails?.name,
      contactable: {
        ...office.contactable,
        email: data.changeOfficeDetails?.email,
        web: data.changeOfficeDetails?.website,
        phone: data.changeOfficeDetails?.phone,
      },
      address: {
        ...office.address,
        address1: data.changeOfficeDetails?.address?.address1,
        address2: data.changeOfficeDetails?.address?.address2,
        address3: data.changeOfficeDetails?.address?.address3,
        shortAddress: data.changeOfficeDetails?.address?.shortAddress,
        fullAddress: data.changeOfficeDetails?.address?.fullAddress,
        city: data.changeOfficeDetails?.address?.city,
        suburb: data.changeOfficeDetails?.address?.suburb,
        province: data.changeOfficeDetails?.address?.province,
        state: data.changeOfficeDetails?.address?.state?.shortName,
        postcode: data.changeOfficeDetails?.address?.postcode,
      },
    };
  });
};

const transformValues = (
  values: EditOfficeDetailsFieldValues,
): ChangeOfficeDetailsVariables => {
  const state = values[EditOfficeDetailsFieldNames.State];
  return {
    officeDetails: {
      accountOfficeId: values[EditOfficeDetailsFieldNames.AccountOfficeId],
      name: values[EditOfficeDetailsFieldNames.Name],
      phone: values[EditOfficeDetailsFieldNames.PhoneNumber],
      website: values[EditOfficeDetailsFieldNames.Website],
      email: values[EditOfficeDetailsFieldNames.OfficeEmail],
      address: {
        address1: values[EditOfficeDetailsFieldNames.StreetAddress],
        suburb: values[EditOfficeDetailsFieldNames.Suburb],
        stateId: state?.value ? parseInt(state?.value.toString(), 10) : null,
        postcode: values[EditOfficeDetailsFieldNames.Postcode],
        city: values[EditOfficeDetailsFieldNames.City],
        province: values[EditOfficeDetailsFieldNames.Province],
      },
    },
  };
};

export const useSubmitForm = () => {
  const id = useProfileId();
  const { accountId } = useProfileAccountId();
  const [updateOfficeAddress, { error }] = useMutation<
    ChangeOfficeDetails,
    ChangeOfficeDetailsVariables
  >(UPDATE_OFFICE_DETAILS, {
    update: (cache, { data }) => {
      if (data?.changeOfficeDetails?.__typename === 'AccountOffice') {
        const profileByProfileId: CompanyProfile | null = cache.readQuery({
          query: GET_COMPANY_PROFILE,
          variables: {
            id,
          },
        });

        const profileByAccountId: CompanyProfileByAccountId | null =
          cache.readQuery({
            query: GET_COMPANY_PROFILE_BY_ACCOUNT_ID,
            variables: {
              id: accountId?.toString(),
            },
          });

        if (profileByProfileId && profileByProfileId.companyProfile) {
          cache.writeQuery({
            query: GET_COMPANY_PROFILE,
            variables: {
              id,
            },
            data: {
              companyProfile: {
                ...profileByProfileId.companyProfile,
                offices: updateOffices(data, profileByProfileId.companyProfile),
              },
            },
          });
        }

        if (profileByAccountId && profileByAccountId.companyProfile) {
          cache.writeQuery({
            query: GET_COMPANY_PROFILE_BY_ACCOUNT_ID,
            variables: {
              id,
            },
            data: {
              companyProfile: {
                ...profileByAccountId.companyProfile,
                offices: updateOffices(data, profileByAccountId.companyProfile),
              },
            },
          });
        }
      }
    },
  });

  const submit = async (values: EditOfficeDetailsFieldValues) => {
    const { errors } = await updateOfficeAddress({
      variables: transformValues(values),
    });
    return errors ? errors : null;
  };
  return { submit, error: error ?? undefined };
};
