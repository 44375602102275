import React from 'react';
import { Tag } from './subcomponents';
import styles from './TagGroup.module.scss';

type TagGroupProps = {
  header: string;
  children: React.ReactNode;
};

export const TagGroup = ({ header, children }: TagGroupProps) => (
  <div>
    <h4 className={styles.tagsHeader}>{header}</h4>
    {children}
  </div>
);

TagGroup.Tag = Tag;
