import { useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useCompanyProfileCacheItemId } from '../../../../modules/ProfileProvider';
import { EDIT_DIRECTORY_OPT_IN_SETTING } from './mutations';
import {
  EditDirectoryOptInSetting,
  EditDirectoryOptInSettingVariables,
} from './__generated__/EditDirectoryOptInSetting';

export const useSubmitDirectoryOptIn = () => {
  const id = useCompanyProfileCacheItemId();

  const [editDirectoryOptIn, { error }] = useMutation<
    EditDirectoryOptInSetting,
    EditDirectoryOptInSettingVariables
  >(EDIT_DIRECTORY_OPT_IN_SETTING, {
    update: (cache, { data }) => {
      if (data?.editDirectoryOptInSetting.success) {
        cache.writeFragment({
          id,
          fragment: gql`
            fragment UpdatedDirectoryOptIn on Profile {
              isOptedIntoDirectory
            }
          `,
          data: {
            isOptedIntoDirectory: data.editDirectoryOptInSetting.value,
          },
        });
      }
    },
  });

  const submit = useCallback(
    async (isOptingIn: boolean) => {
      const { errors } = await editDirectoryOptIn({
        variables: {
          optIn: isOptingIn,
        },
      });

      return errors ?? null;
    },
    [editDirectoryOptIn],
  );

  return { submit, error: error ?? undefined };
};
