import React from 'react';
import { InputFieldWrapper } from '../../../../modules/FinalFormFields/InputFieldWrapper';
import {
  EditOfficeDetailsFieldNames,
  EditOfficeDetailsFieldLabels,
} from './types';

export const PhoneNumberField = () => {
  return (
    <div>
      <InputFieldWrapper<string>
        field={EditOfficeDetailsFieldNames.PhoneNumber}
        id={EditOfficeDetailsFieldNames.PhoneNumber}
        label={EditOfficeDetailsFieldLabels.PhoneNumber}
        validator={() => undefined}
      />
    </div>
  );
};
