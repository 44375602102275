import React, { useEffect, useState } from 'react';
import {
  AlertIcon,
  SkeletonLoader,
  Tooltip,
} from '@estimateone/frontend-components';
import DefaultCompanyProfileLogo from '../../assets/images/defaultCompanyProfileLogo.png';
import { useCompanyLogoURL } from '../../modules/ProfileProvider';
import { LastActive } from '../LastActive';
import { MemberSince } from '../MemberSince';
import { CompanyLogo } from './CompanyLogo';
import { DeleteCompanyLogo } from './DeleteCompanyLogo/DeleteCompanyLogo';
import { EditCompanyName } from './EditCompanyName';
import { UploadCompanyLogo } from './UploadCompanyLogo';
import { useOwnAccount } from '../../hooks';
import { useAdmin } from 'hooks/useAdmin';
import styles from './CompanyInfo.module.scss';

const ReadOnlyDefaultLogo = (
  props: Pick<JSX.IntrinsicElements['img'], 'alt'>,
) => (
  <img
    alt="default logo"
    className={styles.logo}
    src={DefaultCompanyProfileLogo}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  />
);

export const CompanyInfoLoading = () => (
  <SkeletonLoader width="380px" height="50px" />
);

export const CompanyInfoError = () => (
  <div className={styles.headingWrapper}>
    <img
      className={styles.logo}
      alt="error no company logo"
      src={DefaultCompanyProfileLogo}
    />
    <AlertIcon title="error no company name" />
  </div>
);

type CompanyLogoViewProps = {
  companyName: string | undefined;
};

const CompanyLogoOwnerReaderView = ({ companyName }: CompanyLogoViewProps) => (
  <Tooltip
    text="Only account admins can add a logo"
    tooltipId="company-logo-tooltip"
    place="top"
  >
    <CompanyLogo>
      <ReadOnlyDefaultLogo alt={companyName} />
    </CompanyLogo>
  </Tooltip>
);

const CompanyLogoReaderView = ({ companyName }: CompanyLogoViewProps) => (
  <CompanyLogo>
    <ReadOnlyDefaultLogo alt={companyName} />
  </CompanyLogo>
);

export const CompanyLogoView = ({ companyName }: CompanyLogoViewProps) => {
  const { isAdmin } = useAdmin();
  const { isOwnAccount, loading } = useOwnAccount();
  const url = useCompanyLogoURL();

  const [logoExists, setLogoExists] = useState(false);

  useEffect(() => {
    fetch(url, { method: 'HEAD' })
      .then(({ status }) => {
        setLogoExists(status === 200);
      })
      .catch(() => {
        setLogoExists(false);
      });
  }, [url]);

  if (loading || isAdmin === null) {
    return <></>;
  }

  if (!isOwnAccount) {
    return <CompanyLogoReaderView companyName={companyName} />;
  }

  if (!isAdmin) {
    return <CompanyLogoOwnerReaderView companyName={companyName} />;
  }

  return logoExists ? (
    <DeleteCompanyLogo />
  ) : (
    <UploadCompanyLogo onLogoUpdated={() => setLogoExists(true)} />
  );
};

export type CompanyInfoProps = {
  companyName: string | undefined;
  memberSince: string | undefined;
  lastActiveAt: string | null;
};

export const CompanyInfo = ({
  companyName,
  memberSince,
  lastActiveAt,
}: CompanyInfoProps) => {
  const { isAdmin } = useAdmin();

  if (isAdmin === null) {
    return <></>;
  }

  return (
    <div className={styles.headingWrapper}>
      <CompanyLogoView companyName={companyName} />
      <div>
        <div className={styles.companyNameContent}>
          <h2 className={styles.heading}>{companyName}</h2>
          {isAdmin && <EditCompanyName fieldValue={companyName} />}
        </div>

        <div className={styles.createdAt}>
          <MemberSince memberSince={memberSince} />
          <span className={styles.divide}> | </span>
          <LastActive lastActive={lastActiveAt} />
        </div>
      </div>
    </div>
  );
};
