import React from 'react';
import { Button } from '@estimateone/frontend-components';
import { BackButton } from 'components/BackButton';
import UnrecoverableErrorIconComponent from '../../assets/svgs/oopsWire.png';
import { ReactComponent as RefreshIconComponent } from '../../assets/svgs/refresh.svg';
import styles from './UnrecoverableError.module.scss';

const ErrorContent = () => {
  return (
    <div className={styles.errorContent}>
      <p className={styles.errorHeading}>Something went wrong</p>
      <p className={styles.errorDescription}>
        If the issue persists, contact EstimateOne with the following info:
      </p>
      <p className={styles.serviceHeading}>
        Service: Profiles & Request ID: SUBBIE_PROFILE_QUERY
      </p>
    </div>
  );
};

export const UnrecoverableError = () => {
  return (
    <>
      <div className={styles.backButton}>
        <BackButton />
      </div>
      <div className={styles.container}>
        <div className={styles.errorIcon}>
          <img
            alt="Unrecoverable Error"
            src={UnrecoverableErrorIconComponent}
          />
        </div>
        <ErrorContent />
        <Button title="refresh">
          <div className={styles.buttonContainer}>
            <RefreshIconComponent />
            <div className={styles.refreshContent}>Refresh browser</div>
          </div>
        </Button>
      </div>
    </>
  );
};
