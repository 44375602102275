import React, { useContext } from 'react';
import { AlertIcon, SkeletonLoader } from '@estimateone/frontend-components';
import { ViewerContext } from '../../modules/ViewerProvider/ViewerProvider';
import { AddHistoricalProject } from './ProjectHistoryActions';
import { ProjectHistoryContent } from './ProjectHistoryContent';
import { useOwnAccount } from 'hooks';
import { CompanyProfile_companyProfile_projectHistory } from '../../modules/ProfileProvider/__generated__/CompanyProfile';
import styles from './ProjectHistory.module.scss';

const MAXIMUM_PROJECTS = 20;

type ProjectHistoryProps = {
  data: CompanyProfile_companyProfile_projectHistory[];
};

export const ProjectHistory = ({ data }: ProjectHistoryProps) => {
  return (
    <div className={styles.projectHistorySection}>
      <ProjectHistorySection data={data} />
    </div>
  );
};

export type ProjectHistorySectionProps = {
  data: CompanyProfile_companyProfile_projectHistory[];
};

const ProjectHistorySection = ({ data }: ProjectHistorySectionProps) => {
  const filteredData = data.filter((project) => !project.deletedAt);
  const { loading } = useContext(ViewerContext);
  const { isOwnAccount } = useOwnAccount();
  const maxProjectsReached = filteredData.length >= MAXIMUM_PROJECTS;

  if (loading) {
    return <></>;
  }

  return isOwnAccount ? (
    <>
      <div className={styles.projectHistoryHeadingWrapper}>
        <p className={styles.projectHistoryHeader}>Project History</p>
        {!maxProjectsReached && <AddHistoricalProject />}
      </div>
      <p className={styles.projectHistorySubheader}>
        Showcase your capability by adding key projects (20 maximum).
      </p>
      <ProjectHistoryContent data={filteredData} />
    </>
  ) : (
    <>
      <div className={styles.projectHistoryHeadingWrapper}>
        <p className={styles.projectHistoryHeader}>Project History</p>
      </div>
      <ProjectHistoryContent data={filteredData} />
    </>
  );
};

export const ProjectHistoryErrorIcon = () => (
  <div className={styles.projectHistorySection}>
    <div className={styles.projectHistoryHeadingWrapper}>
      <p className={styles.projectHistoryHeader}>Project History</p>
    </div>
    <AlertIcon title="project history error icon" />
  </div>
);

export const ProjectHistoryLoader = () => (
  <div className={styles.projectHistorySkeleton}>
    <SkeletonLoader width="50%" height="40px" />
    <SkeletonLoader height="16px" count={4} />
  </div>
);
