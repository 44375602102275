import React from 'react';
import { Tag, TagVariant } from '@estimateone/frontend-components';
import { HistoricalProjectActionMenu } from 'components/ProjectHistory/ProjectHistoryActions';
import { useTranslator } from '../../../../modules/TranslationsProvider';
import { ShowMoreOrLessContent } from '../../../ShowMoreOrLessContent';
import { getContractSizeLabel } from '../../ContractSizeSelect';
import { getDateMonthYearStringOrNull } from '../../../../utils';
import { getCategoryLabel } from '../../../Categories/utils';
import { ProjectHistory } from '../types';
import styles from './ProjectHistoryCard.module.scss';

export type ProjectHistoryCardProps = {
  project: ProjectHistory;
  showActionMenu: boolean | null;
};

export const ProjectHistoryCard = ({
  project,
  showActionMenu = false,
}: ProjectHistoryCardProps) => {
  const translate = useTranslator();

  const completionDate = getDateMonthYearStringOrNull(project.completionDate);
  const TRUNCATE_CHARACTER_LENGTH = 225;

  return (
    <div className={styles.cardContainer}>
      <div className={styles.cardHeader}>
        <div>
          <div className={styles.builderName}>{project.builderName}</div>
          <div className={styles.projectName}>{project.name}</div>
        </div>
        {showActionMenu && (
          <div>
            <HistoricalProjectActionMenu data={project} />
          </div>
        )}
      </div>
      <div className={styles.cardTags}>
        <Tag
          text={getCategoryLabel(project.category)}
          variant={TagVariant.Practical025}
        />
        <Tag
          text={project.state}
          variant={TagVariant.Plain}
          className={styles.historyTag}
        />
        <Tag
          text={
            completionDate ? `Completion: ${completionDate}` : 'Current Project'
          }
          variant={TagVariant.Plain}
          className={styles.historyTag}
        />
        <Tag
          text={
            `Contract: ${getContractSizeLabel(
              project.contractSize,
              translate,
            )}` ?? ''
          }
          variant={TagVariant.Plain}
          className={styles.historyTag}
        />
      </div>
      {project.description && (
        <ShowMoreOrLessContent
          content={project.description}
          minContentSize={TRUNCATE_CHARACTER_LENGTH}
        />
      )}
    </div>
  );
};
