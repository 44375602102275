import React from 'react';
import { Modal, ModalSize } from '@estimateone/frontend-components';
import { EditContractAndWorkforceSizeForm } from './EditContractAndWorkforceSizeForm';
import {
  ContractSizeMaxEnum,
  ContractSizeMinEnum,
  WorkforceSizeEnum,
} from '../../../__generated__/globalTypes';

const ALLOW_MODAL_OVERFLOW = {
  style: {
    content: {
      overflow: 'visible',
    },
  },
};

export type EditContractAndWorkforceSizeProps = {
  isOpen: boolean;
  toggle: React.DispatchWithoutAction;
  contractSizeMax: ContractSizeMaxEnum | null;
  contractSizeMin: ContractSizeMinEnum | null;
  workforceSize: WorkforceSizeEnum | null;
};

export const EditContractAndWorkforceSize = ({
  isOpen,
  toggle,
  contractSizeMax,
  contractSizeMin,
  workforceSize,
}: EditContractAndWorkforceSizeProps) => {
  return (
    <div>
      <Modal
        rootElement="#MFE_PROFILES_ROOT"
        isOpen={isOpen}
        onRequestClose={toggle}
        size={ModalSize.Small}
        reactModalProps={ALLOW_MODAL_OVERFLOW}
      >
        <Modal.Section>
          <EditContractAndWorkforceSizeForm
            workforceSize={workforceSize}
            contractSizeMin={contractSizeMin}
            contractSizeMax={contractSizeMax}
            toggle={toggle}
          />
        </Modal.Section>
      </Modal>
    </div>
  );
};
