import React from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import { ErrorBoundary } from './components/ErrorBoundary';
import { AuthProvider } from './modules/AuthProvider';
import { FeatureProvider } from './modules/FeatureProvider/FeatureProvider';
import { ProfileProvider } from './modules/ProfileProvider';
import { TranslationProvider } from './modules/TranslationsProvider';
import {
  AddressBookSliderProfile,
  CompanyProfile,
  ProfileLayout,
} from './pages/CompanyProfile';
import { Version } from './pages/Version';
import { parseEnvConfig } from './services/parseEnvConfig';
import { ProfileRoute } from './pages/enums';

export type AppProps = {
  BFF_URI: string;
  IDP_URI: string;
  layout?: ProfileLayout | 'ab-slider';
  PROFILE_ID?: string;
  ACCOUNT_ID?: number;
  isSubbieProfilesInsightsEnabled?: boolean;
};

export const App = ({
  BFF_URI,
  IDP_URI,
  ACCOUNT_ID,
  PROFILE_ID,
  layout = 'page',
  isSubbieProfilesInsightsEnabled = false,
}: AppProps) => {
  const config = parseEnvConfig({ BFF_URI, IDP_URI });

  return (
    <ErrorBoundary>
      <AuthProvider envConfig={config}>
        <TranslationProvider>
          <ProfileProvider companyProfileId={PROFILE_ID} accountId={ACCOUNT_ID}>
            <FeatureProvider>
              <HashRouter>
                <Routes>
                  <Route path={ProfileRoute.Version} element={<Version />} />
                  <Route
                    path={ProfileRoute.ProfileSettings}
                    element={
                      <CompanyProfile
                        layout="page"
                        pageType="settings"
                        isSubbieProfilesInsightsEnabled={
                          isSubbieProfilesInsightsEnabled
                        }
                      />
                    }
                  />
                  {isSubbieProfilesInsightsEnabled ? (
                    <Route
                      path={ProfileRoute.ProfileInsights}
                      element={
                        <CompanyProfile
                          layout="page"
                          pageType="insights"
                          isSubbieProfilesInsightsEnabled={
                            isSubbieProfilesInsightsEnabled
                          }
                        />
                      }
                    />
                  ) : null}
                  <Route
                    path="*"
                    element={
                      layout === 'ab-slider' ? (
                        <AddressBookSliderProfile />
                      ) : (
                        <CompanyProfile
                          layout={layout}
                          isSubbieProfilesInsightsEnabled={
                            isSubbieProfilesInsightsEnabled
                          }
                        />
                      )
                    }
                  />
                </Routes>
              </HashRouter>
            </FeatureProvider>
          </ProfileProvider>
        </TranslationProvider>
      </AuthProvider>
    </ErrorBoundary>
  );
};
