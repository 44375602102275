import { gql, useApolloClient, useMutation } from '@apollo/client';
import { useCompanyProfileCacheItemId } from '../../../../modules/ProfileProvider';
import { UPDATE_ABOUT_US } from '../mutations';
import { EditCompanyFieldValues } from './types';
import {
  UpdateAboutUs,
  UpdateAboutUsVariables,
} from '../__generated__/UpdateAboutUs';

export const useSubmitForm = () => {
  const { cache } = useApolloClient();
  const id = useCompanyProfileCacheItemId();

  const [handleUpdateAboutUs, { error }] = useMutation<
    UpdateAboutUs,
    UpdateAboutUsVariables
  >(UPDATE_ABOUT_US, {
    onCompleted: (data) => {
      if (data.updateAboutUs?.__typename === 'StringData') {
        cache.writeFragment({
          id,
          fragment: gql`
            fragment UpdatedAboutUs on Profile {
              aboutUs
            }
          `,
          data: {
            aboutUs: data.updateAboutUs.data,
          },
        });
      }
    },
  });

  const submit = async ({ aboutUs }: EditCompanyFieldValues) => {
    const { data, errors } = await handleUpdateAboutUs({
      variables: { aboutUs: aboutUs ? aboutUs : null },
    });

    return {
      data: data,
      errors: errors,
    };
  };

  return { submit, error: error ?? undefined };
};
