import React from 'react';
import { InputFieldWrapper } from '../../../../modules/FinalFormFields/InputFieldWrapper';
import {
  EditOfficeDetailsFieldLabels,
  EditOfficeDetailsFieldNames,
} from './types';

export const CityField = () => {
  return (
    <div>
      <InputFieldWrapper<string>
        field={EditOfficeDetailsFieldNames.City}
        id={EditOfficeDetailsFieldNames.City}
        label={EditOfficeDetailsFieldLabels.City}
        validator={() => undefined}
      />
    </div>
  );
};
