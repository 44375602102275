import { format } from 'date-fns';
import { GetProfileInsightsDataQuery } from '../../queries.generated';
import { SingleYAxisDataPoint } from './types';

type MonthlyProfileView = GetProfileInsightsDataQuery['monthlyProfileViews'][0];

export const formatMonthYearForDisplay = (data: SingleYAxisDataPoint) => {
  return `${mapMonthNumberToText(data.month)} '${String(data.year).slice(-2)}${
    checkIfCurrentMonth(data.month, data.year) ? ` to date` : ''
  }`;
};

export const mapProfileViewsToChartData = (
  profileViews: MonthlyProfileView[],
) => {
  const monthlyCounts: Record<string, number> = profileViews.reduce(
    (acc: Record<string, number>, item) => {
      const month = item.month;
      acc[month as unknown as keyof typeof acc] =
        acc[month as unknown as keyof typeof acc] || 0;
      acc[month as unknown as keyof typeof acc] += item.count;
      return acc;
    },
    {},
  );

  // Create an entry for each month (set views as 0 for a month where there is no data)
  Array.from({ length: 12 }, (_, i) => i + 1).forEach((month) => {
    if (monthlyCounts[month]) {
      return;
    }
    monthlyCounts[month] = 0;
  });

  const data: SingleYAxisDataPoint[] = Object.keys(monthlyCounts)
    .map((key: string) => {
      const views = monthlyCounts[key];
      const month = Number(key);
      const year = profileViews.find((d) => d.month.toString() === key)?.year;
      const isCurrentMonth = checkIfCurrentMonth(month, year);
      if (year) {
        return { views, isCurrentMonth, month, year };
      } else {
        const currentMonth = new Date().getMonth() + 1;
        const currentYear = new Date().getFullYear();

        if (month > currentMonth) {
          return { views, isCurrentMonth, month, year: currentYear - 1 };
        }
        return { views, isCurrentMonth, month, year: currentYear };
      }
    })
    .sort((d1, d2) => d1.year - d2.year);
  return data;
};

export const checkIfCurrentMonth = (month: number, year?: number): boolean => {
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();
  if (currentMonth === month && currentYear && currentYear === year) {
    return true;
  }
  return false;
};

export const mapMonthNumberToText = (month: number): string => {
  return format(new Date(2000, month - 1, 1), 'LLL');
};
