import { gql } from '@apollo/client';

export const UPDATE_OFFICE_DETAILS = gql`
  mutation ChangeOfficeDetails($officeDetails: ChangeOfficeDetailsInput!) {
    changeOfficeDetails(input: $officeDetails) {
      id
      name
      phone
      email
      website
      address {
        id
        address1
        address2
        address3
        fullAddress
        shortAddress
        suburb
        postcode
        city
        province
        state {
          id
          shortName
        }
        country {
          id
          shortName
        }
      }
    }
  }
`;
