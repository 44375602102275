import { useMutation } from '@apollo/client';
import { ADD_OFFICE_DETAILS } from './mutations';
import {
  AddOfficeDetailsFieldValues,
  EditOfficeDetailsFieldNames,
} from '../EditOfficeDetailsForm/fields/types';
import {
  AddOfficeDetails,
  AddOfficeDetailsVariables,
} from './__generated__/AddOfficeDetails';

const transformValues = (
  values: AddOfficeDetailsFieldValues,
): AddOfficeDetailsVariables => {
  const state = values[EditOfficeDetailsFieldNames.State];
  return {
    officeDetails: {
      phone: values[EditOfficeDetailsFieldNames.PhoneNumber] ?? null,
      website: values[EditOfficeDetailsFieldNames.Website] ?? null,
      email: values[EditOfficeDetailsFieldNames.OfficeEmail] ?? null,
      address: {
        address1: values[EditOfficeDetailsFieldNames.StreetAddress] ?? '',
        suburb: values[EditOfficeDetailsFieldNames.Suburb] ?? '',
        stateId: state?.value ? parseInt(state?.value.toString(), 10) : 0,
        postcode: values[EditOfficeDetailsFieldNames.Postcode] ?? '',
        city: values[EditOfficeDetailsFieldNames.City] ?? '',
        province: values[EditOfficeDetailsFieldNames.Province] ?? '',
      },
    },
  };
};

export const useSubmitForm = () => {
  const [updateOfficeAddress, { data, error }] = useMutation<
    AddOfficeDetails,
    AddOfficeDetailsVariables
  >(ADD_OFFICE_DETAILS);

  const submit = async (values: AddOfficeDetailsFieldValues) => {
    const { errors, data } = await updateOfficeAddress({
      variables: transformValues(values),
    });
    if (errors) {
      return errors;
    }
    return data?.addOfficeDetails?.__typename === 'Errors'
      ? data.addOfficeDetails.errors
      : null;
  };

  return { submit, error: error ?? undefined, data: data?.addOfficeDetails };
};
