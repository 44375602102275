import { gql } from '@apollo/client';

export const EDIT_HISTORICAL_PROJECT = gql`
  mutation UpdateHistoricalProject($input: HistoricalProjectInput!, $id: ID!) {
    updateHistoricalProject(input: $input, id: $id) {
      ... on HistoricalProject {
        __typename
        id
        name
        builderName
        state
        completionDate
        contractSize
        category
        description
      }
      ... on FieldErrors {
        __typename
        errors {
          field
          message
        }
      }
    }
  }
`;
