import React from 'react';
import { Form } from 'react-final-form';
import { FormError } from 'components/FormError';
import { ModalFooter } from 'modules/Modal/ModalFooter';
import { TranslationProvider } from 'modules/TranslationsProvider';
import { Fields } from '../EditOfficeDetailsForm/fields/Fields';
import { useSubmitForm } from './hooks';
import { AddOfficeDetailsFieldValues } from '../EditOfficeDetailsForm/fields/types';
import styles from '../EditOfficeDetailsForm/EditOfficeDetailsForm.module.scss';

type AddOfficeDetailsFormProps = {
  close: () => void;
};

export const AddOfficeDetailsForm = ({ close }: AddOfficeDetailsFormProps) => {
  const { submit: submitForm, error, data } = useSubmitForm();

  const onSubmit = async (values: AddOfficeDetailsFieldValues) => {
    const errorsOrNull = await submitForm(values);
    if (errorsOrNull === null) {
      close();
    }
  };

  const errorMessage =
    data?.__typename === 'Errors' ? data?.errors[0]?.message : null;

  return (
    <Form<AddOfficeDetailsFieldValues> onSubmit={onSubmit}>
      {({ handleSubmit }) => {
        return (
          <TranslationProvider>
            <form
              id="ADD_OFFICE_DETAILS_FORM"
              onSubmit={handleSubmit}
              className={styles.form}
            >
              {error && <FormError />}
              {errorMessage ? <FormError errorMessage={errorMessage} /> : null}
              <Fields />
              <ModalFooter close={close} />
            </form>
          </TranslationProvider>
        );
      }}
    </Form>
  );
};
