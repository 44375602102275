import { format } from 'date-fns';
import numbro from 'numbro';

export { extractFieldErrors } from './api';

export const getCurrencyFormat = (value: number, symbol?: string) =>
  numbro(value).formatCurrency({
    thousandSeparated: true,
    trimMantissa: true,
    mantissa: 1,
    average: true,
    currencySymbol: symbol ?? '$',
  });

const getMonthYear = (date: string) => format(new Date(date), 'MMM yyyy');

export const getDateMonthYearStringOrNull = (date?: string) => {
  if (!date) {
    return null;
  }

  try {
    return getMonthYear(date);
  } catch (error) {
    return null;
  }
};
