import React, { useCallback, useState } from 'react';
import {
  AlertIcon,
  SkeletonLoader,
  E1Link,
  TextLink,
} from '@estimateone/frontend-components';
import { AddOfficeDetailsModal } from './AddOfficeDetailsModal';
import { ActionableOfficeListItem } from './views';
import { useOwnAccount } from 'hooks';
import { CompanyProfile_companyProfile_offices } from '../../modules/ProfileProvider/__generated__/CompanyProfile';

import styles from './OfficeContactList.module.scss';

export const OfficeContactListErrorView = () => (
  <div className={styles.officeSkeleton}>
    <p className={styles.officeListHeader}>Office</p>
    <AlertIcon title="office list error icon" />
  </div>
);

export const OfficeContactListLoader = () => (
  <div className={styles.officeSkeleton}>
    <SkeletonLoader width="50%" height="40px" />
    <SkeletonLoader height="16px" count={10} />
  </div>
);

function getAbsoluteURL(url: null): null;
function getAbsoluteURL(url: string): string;
function getAbsoluteURL(url: string | null) {
  if (!url) {
    return null;
  }
  if (url.startsWith('//') || url.startsWith('http')) {
    return url;
  }
  return '//' + url;
}

type OfficeContactListProps = {
  data: CompanyProfile_companyProfile_offices[];
};

export const OfficeContactList = ({ data }: OfficeContactListProps) => {
  const { isOwnAccount, loading } = useOwnAccount();
  const [showAddOficeModal, setShowAddOfficeModal] = useState(false);

  const toggleAddOfficeModal = useCallback(
    () => setShowAddOfficeModal((prevState) => !prevState),
    [setShowAddOfficeModal],
  );

  if (loading) {
    return <></>;
  }

  const defaultOfficeId =
    data.find((office) => office.isDefaultOffice)?.ascensionId ?? -1;

  if (!isOwnAccount) {
    return (
      <div>
        <div className={styles.officeListHeadingWrapper}>
          <p className={styles.officeListHeader}>Office</p>
        </div>
        <ul className={styles.officeLists}>
          {data.map(({ id, name, contactable, address }) => (
            <li key={id} className={styles.officeListContainer}>
              <div className={styles.container}>
                <div>
                  <p>{name}</p>
                  {address && <p>{address.fullAddress}</p>}
                  {contactable?.email && (
                    <TextLink href={`mailto:${contactable.email}`}>
                      {contactable.email}
                    </TextLink>
                  )}
                  {contactable?.phone && <p>{contactable.phone}</p>}
                  {contactable?.web && (
                    <TextLink external href={getAbsoluteURL(contactable.web)}>
                      {contactable.web}
                    </TextLink>
                  )}
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    );
  }

  return (
    <div>
      <div className={styles.officeListHeadingWrapper}>
        <p className={styles.officeListHeader}>Office</p>
        <E1Link onClick={toggleAddOfficeModal} aria-label="Add office">
          Add
        </E1Link>
      </div>
      <ul className={styles.officeLists}>
        {data.map(({ id, ascensionId, name, contactable, address }) => (
          <li key={id} className={styles.officeListContainer}>
            <div className={styles.container}>
              <div>
                <p>{name}</p>
                {address && <p>{address.fullAddress}</p>}
                {contactable?.email && (
                  <TextLink href={`mailto:${contactable.email}`}>
                    {contactable.email}
                  </TextLink>
                )}
                {contactable?.phone && <p>{contactable.phone}</p>}
                {contactable?.web && (
                  <TextLink external href={getAbsoluteURL(contactable.web)}>
                    {contactable.web}
                  </TextLink>
                )}
              </div>
              <ActionableOfficeListItem
                key={name}
                id={ascensionId ?? +id} // editing an office requires an account, meaning an ascensionId would exist
                name={name}
                phone={contactable?.phone ?? null}
                email={contactable?.email ?? null}
                address={{
                  ...address,
                  id: address.ascensionId ?? +address.id, // editing an address requires an account, meaning an ascensionId would exist
                }}
                website={contactable?.web ?? null}
                defaultOfficeId={defaultOfficeId}
              />
            </div>
          </li>
        ))}
      </ul>
      <AddOfficeDetailsModal
        isOpen={showAddOficeModal}
        toggle={toggleAddOfficeModal}
      />
    </div>
  );
};
