import React from 'react';
import { useTranslator } from '../../../../../modules/TranslationsProvider';
import { EditModal } from '../../../../EditModal';
import { ERROR_TEXT } from '../../../../FormError/constants';
import { useSubmitForm } from './hooks';
import {
  EditCompanyFieldNames,
  EditCompanyFieldValues,
} from '../../../../EditModal/types';

export type EditABNBadgeModalProps = {
  abn: string | null | undefined;
  close: () => void;
};
export const EditABNBadgeForm = ({ abn, close }: EditABNBadgeModalProps) => {
  const { submit: submitForm, error } = useSubmitForm();
  const translate = useTranslator();

  const onSubmit = async (value: EditCompanyFieldValues) => {
    const { errors, data } = await submitForm(value);

    if (data?.changeBusinessIdentifier) {
      const responseType = data.changeBusinessIdentifier.__typename;
      if (responseType === 'Errors') {
        const fieldErrors = data.changeBusinessIdentifier.errors;

        return {
          [EditCompanyFieldNames.CompanyABN]: fieldErrors
            .filter((err) => err?.field === EditCompanyFieldNames.CompanyABN)
            .map((err) => err?.message),
        };
      } else {
        close();
      }
    }

    if (errors === null) {
      close();
    }
  };

  return (
    <EditModal
      fieldName={EditCompanyFieldNames.CompanyABN}
      fieldLabel={translate('businessIdentifier')}
      fieldValue={abn}
      toggle={close}
      onClick={onSubmit}
      error={error && ERROR_TEXT}
    />
  );
};
