import React from 'react';
import { mapMonthNumberToText } from '../SingleYAxisLineChart/utils';
import { SingleYAxisDataPoint } from '../SingleYAxisLineChart/types';
import styles from './styles.module.scss';

type ToolTipContentProps = {
  data: SingleYAxisDataPoint[];
};

export const ToolTipContent = ({ data }: ToolTipContentProps) => {
  const [dataPoint] = data;
  const { isCurrentMonth, month, year, views } = dataPoint;
  return (
    <>
      <div className={styles.tooltipBody}>
        <div className={styles.viewsContainer}>
          <div className={styles.indicator} />
          <div className={styles.views}>{views} Profile views</div>
        </div>
        <div className={styles.month}>
          {`${mapMonthNumberToText(month)} ${year} ${
            isCurrentMonth ? 'to date' : ''
          }`}
        </div>
      </div>
    </>
  );
};
