import { gql } from '@apollo/client';

export const GET_ALL_STOCK_TRADES = gql`
  query SubbieStockTradesQuery {
    stockTrades {
      value: id
      label: name
    }
  }
`;
