import React from 'react';
import { InputFieldWrapper } from '../../../../modules/FinalFormFields/InputFieldWrapper';
import {
  EditOfficeDetailsFieldNames,
  EditOfficeDetailsFieldLabels,
} from './types';

export const WebsiteField = () => {
  return (
    <div>
      <InputFieldWrapper<string>
        field={EditOfficeDetailsFieldNames.Website}
        id={EditOfficeDetailsFieldNames.Website}
        label={EditOfficeDetailsFieldLabels.Website}
        validator={() => undefined}
      />
    </div>
  );
};
