import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  HttpLink,
  NormalizedCacheObject,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { APP_NAME } from '../../../constants';

type GatewayClientOptions = {
  endpoint: string;
  idpLoginUri: string;
  getToken: () => Promise<string>;
};

/**
 * See Webpack DefinePlugin config
 */
declare const __VERSION__: string;

export const localDevelopmentClient = new ApolloClient({
  uri: process.env.REACT_APP_MOCKS_URI ?? 'http://localhost:8050/graphql',
  cache: new InMemoryCache(),
});

const productionClient = ({
  endpoint,
  idpLoginUri,
  getToken,
}: GatewayClientOptions): ApolloClient<NormalizedCacheObject> => {
  // Auth with the IDP
  const authLink = setContext(async (_, { headers }) => {
    try {
      return {
        headers: {
          authorization: `Bearer ${await getToken()}`,
          ...headers,
        },
      };
    } catch (err) {
      document.location.assign(
        `${idpLoginUri}?redirect=${document.location.href}`,
      );
    }
  });

  const httpLink = new HttpLink({ uri: endpoint });

  return new ApolloClient({
    defaultOptions: {
      mutate: {
        errorPolicy: 'all', // this ensure that we don't have to catch errors explicitly
      },
    },
    link: ApolloLink.from([
      authLink,
      httpLink, // if the test is true -- debatch
    ]),
    cache: new InMemoryCache(),
    name: APP_NAME,
    version: __VERSION__,
  });
};

export const ApiClient = (
  endpoint: string,
  loginUrl: string,
  getToken: () => Promise<string>,
): ApolloClient<NormalizedCacheObject> => {
  return process.env.NODE_ENV === 'development' &&
    process.env.REACT_APP_INTEGRATED_MODE === 'false'
    ? localDevelopmentClient
    : productionClient({
        endpoint,
        idpLoginUri: loginUrl,
        getToken,
      });
};
