import React from 'react';

import { useTranslator } from 'modules/TranslationsProvider';
import { CityField } from './CityField';
import { EmailField } from './EmailField';
import { PhoneNumberField } from './PhoneNumberField';
import { PostcodeField } from './PostcodeField';
import { ProvinceField } from './ProvinceField';
import { StateField } from './StateField';
import { StreetAddressField } from './StreetAddressField';
import { SuburbField } from './SuburbField';
import { WebsiteField } from './WebsiteField';
import styles from './Fields.module.scss';

export const EMPTY_STATE = {
  value: 'Empty',
  label: 'None',
};

const ForUKIE = () => (
  <>
    <CityField />
    <ProvinceField />
  </>
);

const ForAU = () => (
  <>
    <SuburbField />
    <StateField />
  </>
);

export const Fields = () => {
  const translate = useTranslator();

  const isUKOrIE = ['gb', 'ie'].includes(translate('location'));

  return (
    <div className={styles.formWrapper}>
      <StreetAddressField />
      <div className={styles.adjacentFields}>
        {isUKOrIE ? <ForUKIE /> : <ForAU />}
      </div>
      <div className={styles.adjacentFields}>
        <PostcodeField />
        <PhoneNumberField />
      </div>
      <EmailField />
      <WebsiteField />
    </div>
  );
};
