import { useMemo } from 'react';
import { useApolloClient } from '@apollo/client';
import debounce from 'lodash.debounce';
import {
  CREATE_NEW_OPTION,
  NO_MATCHING_PROJECTS_OPTION,
} from './SearchableProjectName';
import { SEARCH_PROJECTS } from './queries';
import { LinkedProjectOption } from '../../types';
import {
  SearchE1Projects,
  SearchE1ProjectsVariables,
} from './__generated__/SearchE1Projects';

const MIN_INPUT_LENGTH = 3;

export const useDebouncedProjectSearch = () => {
  const { query } = useApolloClient();

  const executeQuery = (userQuery: string) =>
    query<SearchE1Projects, SearchE1ProjectsVariables>({
      query: SEARCH_PROJECTS,
      variables: {
        input: {
          query: userQuery,
        },
      },
    });

  const _getOptions = (
    input: string,
    callback: (options: LinkedProjectOption[]) => unknown,
  ) => {
    if (input.length < MIN_INPUT_LENGTH) {
      return;
    }

    executeQuery(input)
      .then(({ data }) => data.searchE1Projects.projects)
      .then((projects) =>
        projects.map(({ name, id, status, address, stageCategoryName }) => ({
          label: name,
          value: name,
          id,
          status,
          state: address?.state || null,
          category: stageCategoryName || null,
        })),
      )
      .then((options) =>
        callback(
          options.length
            ? [...options, CREATE_NEW_OPTION]
            : [NO_MATCHING_PROJECTS_OPTION, CREATE_NEW_OPTION],
        ),
      );
  };

  // We don't want to regenerate this every time the component is rerendered
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => debounce(_getOptions, 750), []);
};
