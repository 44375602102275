import React from 'react';
import { SingleSelectDropdownField } from '../../../../modules/FinalFormFields/SingleSelectDropdownField/SingleSelectDropdownField';
import { EMPTY_STATE } from './Fields';
import {
  EditOfficeDetailsFieldNames,
  EditOfficeDetailsFieldLabels,
  stateOptions,
} from './types';

export const StateField = () => {
  return (
    <SingleSelectDropdownField
      id={EditOfficeDetailsFieldNames.State}
      label={EditOfficeDetailsFieldLabels.State}
      fieldName={EditOfficeDetailsFieldNames.State}
      options={[EMPTY_STATE, ...stateOptions]}
    />
  );
};
