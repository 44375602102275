/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ContractSizeMaxEnum {
  SIZE_100k = "SIZE_100k",
  SIZE_10M_PLUS = "SIZE_10M_PLUS",
  SIZE_10k = "SIZE_10k",
  SIZE_1M = "SIZE_1M",
  SIZE_250k = "SIZE_250k",
  SIZE_500k = "SIZE_500k",
  SIZE_50k = "SIZE_50k",
  SIZE_5M = "SIZE_5M",
}

export enum ContractSizeMinEnum {
  SIZE_100k = "SIZE_100k",
  SIZE_10k = "SIZE_10k",
  SIZE_1M = "SIZE_1M",
  SIZE_250k = "SIZE_250k",
  SIZE_500k = "SIZE_500k",
  SIZE_50k = "SIZE_50k",
  SIZE_5M = "SIZE_5M",
}

export enum ProfileViewLogSource {
  ADDRESSBOOK = "ADDRESSBOOK",
  DIRECTORY = "DIRECTORY",
  PAGE = "PAGE",
  QUOTE = "QUOTE",
  RFQ = "RFQ",
}

export enum ProjectCategoryEnum {
  AGED_CARE = "AGED_CARE",
  CIVIL = "CIVIL",
  COMMERCIAL = "COMMERCIAL",
  CORRECTIONS = "CORRECTIONS",
  DATA_CENTRE = "DATA_CENTRE",
  DEFENCE = "DEFENCE",
  EDUCATION = "EDUCATION",
  ENTERTAINMENT = "ENTERTAINMENT",
  FIT_OUT = "FIT_OUT",
  GOVERNMENT = "GOVERNMENT",
  HEALTH = "HEALTH",
  HOSPITALITY = "HOSPITALITY",
  INDUSTRIAL_HEAVY = "INDUSTRIAL_HEAVY",
  INDUSTRIAL_LIGHT = "INDUSTRIAL_LIGHT",
  MEDICAL = "MEDICAL",
  MIXED_USE = "MIXED_USE",
  OTHER = "OTHER",
  PHARMACEUTICAL = "PHARMACEUTICAL",
  RECREATION = "RECREATION",
  REFURBISHMENT = "REFURBISHMENT",
  RESIDENTIAL = "RESIDENTIAL",
  RETAIL = "RETAIL",
  TOURISM = "TOURISM",
}

export enum ProjectStatus {
  AWARDED = "AWARDED",
  CLOSED = "CLOSED",
  OPEN = "OPEN",
}

export enum StateEnum {
  ACT = "ACT",
  NSW = "NSW",
  NT = "NT",
  Other = "Other",
  QLD = "QLD",
  SA = "SA",
  TAS = "TAS",
  VIC = "VIC",
  WA = "WA",
}

export enum WorkforceSizeEnum {
  RANGE_0_5 = "RANGE_0_5",
  RANGE_21_50 = "RANGE_21_50",
  RANGE_251_PLUS = "RANGE_251_PLUS",
  RANGE_51_250 = "RANGE_51_250",
  RANGE_6_20 = "RANGE_6_20",
}

export interface AddOfficeDetailsInput {
  phone?: string | null;
  email?: string | null;
  address: ChangeOfficeDetailsAddressInput;
  website?: string | null;
}

export interface ChangeOfficeDetailsAddressInput {
  address1?: string | null;
  postcode?: string | null;
  suburb?: string | null;
  city?: string | null;
  province?: string | null;
  stateId?: GraphQLEntityId | null;
}

export interface ChangeOfficeDetailsInput {
  accountOfficeId: GraphQLEntityId;
  name?: string | null;
  phone?: string | null;
  email?: string | null;
  address: ChangeOfficeDetailsAddressInput;
  website?: string | null;
}

export interface HistoricalProjectInput {
  name: string;
  builderName: string;
  state: StateEnum;
  region?: string | null;
  completionDate: GraphQLDateTime;
  contractSize: number;
  category: ProjectCategoryEnum;
  linkedProjectId?: number | null;
  description?: string | null;
}

export interface SearchProjectsInput {
  query: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
