import React, { createContext, useContext } from 'react';
import { useQuery } from '@apollo/client';
import {
  GET_IS_AUTHORISED_TO_OPT_INTO_DIRECTORY,
  GET_SSC_OPT_OUT_PREFERENCE,
  GET_USER_CONTEXT,
} from './queries';
import { isAuthorisedToOptIntoDirectoryQuery } from './queries.generated';
import { SscOptOutPreference } from './__generated__/SscOptOutPreference';
import { UserContextQuery } from './__generated__/UserContextQuery';
import { UserDetails } from './__generated__/UserDetails';

export type ViewerContextProps =
  | {
      loading: false;
      // lets us only do one null check
      context: {
        user: UserDetails;
        isAdmin: () => boolean;
        isOwnAccount: () => boolean;
      };
      hasOptedOutOfSSC: boolean;
      isAuthorisedToOptIntoDirectory: boolean;
    }
  | {
      loading: true;
      context: null;
      hasOptedOutOfSSC: boolean;
      isAuthorisedToOptIntoDirectory: boolean;
    };

export const ViewerContext = createContext<ViewerContextProps>({
  loading: true,
  context: null,
  hasOptedOutOfSSC: false,
  isAuthorisedToOptIntoDirectory: false,
});

type ViewerProviderProps = {
  children: React.ReactNode;
  accountId?: number | null;
};

/**
 * The viewer is the current authenticated user
 */
export const ViewerProvider = ({
  children,
  accountId,
}: ViewerProviderProps) => {
  const { loading: userLoading, data: userData } =
    useQuery<UserContextQuery>(GET_USER_CONTEXT);
  const { loading: loadingSSC, data: dataSSC } = useQuery<SscOptOutPreference>(
    GET_SSC_OPT_OUT_PREFERENCE,
  );
  const { loading: loadingDirectoryAuthorised, data: dataDirectoryAuthorised } =
    useQuery<isAuthorisedToOptIntoDirectoryQuery>(
      GET_IS_AUTHORISED_TO_OPT_INTO_DIRECTORY,
    );

  if (
    userLoading ||
    !userData ||
    loadingSSC ||
    !dataSSC ||
    loadingDirectoryAuthorised
  ) {
    return (
      <ViewerContext.Provider
        value={{
          loading: true,
          context: null,
          hasOptedOutOfSSC: false,
          isAuthorisedToOptIntoDirectory: false,
        }}
      >
        {children}
      </ViewerContext.Provider>
    );
  }

  const isOwnAccount =
    accountId !== null && userData.currentUser?.account?.id === accountId;
  const hasOptedOutOfSSC = dataSSC?.hasOptedOutOfSSC ?? false;
  const isAuthorisedToOptIntoDirectory =
    dataDirectoryAuthorised?.isAuthorisedToOptIntoDirectory ?? false;

  const value: ViewerContextProps = {
    loading: false,
    context: {
      user: userData.currentUser,
      isAdmin: () => userData.currentUser.isAdmin && isOwnAccount,
      isOwnAccount: () => isOwnAccount,
    },
    hasOptedOutOfSSC,
    isAuthorisedToOptIntoDirectory,
  };
  return (
    <ViewerContext.Provider value={value}>{children}</ViewerContext.Provider>
  );
};

export const useViewerContext = () => {
  const ctx = useContext(ViewerContext);

  if (ctx === undefined) {
    throw new Error(
      'useHasOptedOutOfSSC may only be used within a ViewerProvider',
    );
  }

  return ctx;
};
