import { gql } from '@apollo/client';

export const DELETE_HISTORICAL_PROJECT = gql`
  mutation DeleteHistoricalProject($id: ID!) {
    deleteHistoricalProject(id: $id) {
      __typename
      id
      name
      builderName
      region
      state
      completionDate
      contractSize
      category
      deletedAt
      description
    }
  }
`;
